import styled from "styled-components";
import { MOBILE } from "../../../constants/mobile";
import { MEDIUM } from "../../../constants/desktop";
import { useContext } from "react";
import { CustomBlurDivContext } from "../../../context/custom-blur-div-context";

// Styled button component with responsive styles
const CreateTeamButton = styled.button`
  font-size: 14px;
  background: #1585b533;
  padding: 0.4rem 1rem;
  border-radius: 4px;
  color: #1585b5;
  border: 1px solid #1585b5;
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-right: 20px;
  width: auto;

  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem 1.5rem;
    font-size: 12px;
    margin-top: 0.2rem;
  }

  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 16px;
    padding: 0.5rem 3rem;
  }
`;

const CreateNewTeamButton = ({
  setShowTeamSettingsPopup,
  setIsButtonCreateNewTeam,
  selectedDropdownOption,
}: any) => {
  const { setIsBackgroundBlur} = useContext(CustomBlurDivContext)

  // Button is hidden if a dropdown option is selected and it is not 'All Chatbots'
  const isHidden = selectedDropdownOption && selectedDropdownOption !== "All Chatbots";

  return (
    <CreateTeamButton
      type="button"
      hidden={isHidden}
      onClick={() => {
        setShowTeamSettingsPopup(true);
        setIsBackgroundBlur(true)
        setIsButtonCreateNewTeam(true);
      }}
    >
      Create New Team
    </CreateTeamButton>
  );
};

export default CreateNewTeamButton;
