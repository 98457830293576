import React, { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import LoaderGif from "../images/loader.gif";
import { LoaderContext } from "../context/loader-context";

export const StatusLoaderSection = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 99999;
  background: #0001;
`;

export const WaitTitle = styled.p`
  text-align: center;
  font-size: 24px;
  color: #4f83b5;
  font-weight: bold;
`;

const AppLoader = () => {
  const { showLoader } = useContext(LoaderContext);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined; //  Explicitly typed

    if (showLoader) {
      timer = setTimeout(() => setLoader(true), 1000);
    } else {
      setLoader(false);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [showLoader]);
  return (
    <>
      {loader && <StatusLoaderSection>
        <img src={LoaderGif} alt="loading..." width={60} />
        <WaitTitle>Please Wait...</WaitTitle>
      </StatusLoaderSection>}
    </>
  );
};

export default AppLoader;
