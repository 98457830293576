import { createContext, ReactElement, useState } from "react";
import { CustomBlurDivProps, CustomBlurDivValues } from "../types";

export const CustomBlurDivContext = createContext({} as CustomBlurDivValues);

const CustomBlurDivContextProvider = (
  props: CustomBlurDivProps
): ReactElement<CustomBlurDivValues> => {
  const [isBackgroundBlur, setIsBackgroundBlur] = useState<boolean>(false);

  return (
    <CustomBlurDivContext.Provider
      value={{
        isBackgroundBlur,
        setIsBackgroundBlur,
        
      }}
    >
      {props.children}
    </CustomBlurDivContext.Provider>
  );
};

export default CustomBlurDivContextProvider;
