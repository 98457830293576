
import { useContext } from "react";
import WarningIcon from "../../../images/warning-icon.svg"
import { TeamContext } from "../../../../context/team-context";
import styled from "styled-components";
import { Col, Navbar, Row } from "react-bootstrap";
import { MOBILE } from "../../../../constants/mobile";
import { TAB } from "../../../../constants/tab";
import { MEDIUM } from "../../../../constants/desktop";
import BackImage from '../../../../images/back-button-black.svg'
import { PublicContext } from "../../../../context/PublicContext";
import { DEVICE_MEDIUM, DEVICE_MOBILE, DEVICE_TAB } from "../../../../constants/breakpoints";
import { FaAlignJustify } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const NavbarMain = styled(Navbar)`
padding-left: 2% !important;
padding-right: 2% !important;
justify-content: space-between !important; 
width: 100% !important;
z-index: 8 !important;
background: #D9D3E5 !important;
@media (max-width: ${MOBILE.maxWidth}px) {
flex-direction: row !important;  
align-items: start !important;
font-size: 11px !important;
}
@media (min-width: ${TAB.minWidth}px) {
align-items: start !important;

}
@media (min-width: ${MEDIUM.minWidth}px) {
justify-content: start !important; 

}
  
`;

const Label = styled.label`
@media (min-width: ${MEDIUM.minWidth}px) {
margin-left: 5%;
}
`

const MethodLabel = styled.label`
@media (min-width: ${MEDIUM.minWidth}px) {
margin-left: 10%;
}
`



const BackButton = styled.button`
border: none;
padding: 0;
background: none;
`

const BackButtonImage = styled.img`
@media (max-width: ${MOBILE.maxWidth}px) {
width: 15px;
}

`

const NavbarForChatbotBuilder = ({ createMethod }: any) => {
    const { userRoleInTeam, selectedTeam } = useContext(TeamContext);
    const navigate = useNavigate();

    return (
        <>
            <NavbarMain>
                <div style={{ alignItems: 'center', justifyContent: 'center', width: '10%' }}>
                    <BackButton type="button" onClick={() => { navigate('/create-chatbot') }}> <BackButtonImage src={BackImage} alt="" /></BackButton>

                    {createMethod && createMethod === 'chatbot-builder' ?
                        <MethodLabel htmlFor="">Chatbot Builder</MethodLabel> :
                        <MethodLabel htmlFor="">Manual</MethodLabel>
                    }
                </div>


                <Label htmlFor="">{selectedTeam?.name}</Label>

                <Label htmlFor="">User Role:
                    {userRoleInTeam && userRoleInTeam === 'owner' && <b> Owner</b>}
                    {userRoleInTeam && userRoleInTeam === 'admin' && <b> Team Admin</b>}
                    {userRoleInTeam && userRoleInTeam === 'member' && <b> Member</b>}
                    {userRoleInTeam && userRoleInTeam === 'viewer' && <b> Viewer</b>}
                </Label>

            </NavbarMain>


        </>
    );
}

export default NavbarForChatbotBuilder