import React, { useContext, useEffect, useState } from "react";
import {
  Field,
} from "formik";
import styled from "styled-components";
import { FieldSection } from "../styled-public";
import { UserContext } from "../../../context/UserContext";
import { MEDIUM } from "../../../constants/desktop";
import { MOBILE } from "../../../constants/mobile";

const FieldLabel = styled.label`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.8px;
  // white-space: nowrap;
  // overflow: hidden;
  color: rgba(51, 48, 60, 0.8);
  text-overflow: ellipsis;
`;

const FormInput = styled(Field)`
  padding: 8px !important;
  border: 1px solid rgba(201, 199, 206, 1) !important;
  border-radius: 4px !important;
`;

const FormInputTextarea = styled.textarea`
  padding: 8px;
  border: 1px solid rgba(201, 199, 206, 1);
  border-radius: 4px;
  height: 7rem;
  overflow: hidden;
`;

const FieldSectionDiv = styled.div`
  margin-bottom: 2% !important;
  margin-top: 3% !important;
  @media (max-width: ${MOBILE.maxWidth}PX)
  {
    flex-direction: column !important;
  margin-top: 5% !important;

  }
`;

const FieldSectionName = styled(FieldSection)`
  width: 48.5%  !important;
  @media (max-width: ${MOBILE.maxWidth}PX)
  {
  width: 100%  !important;

  }
`;

const FieldSectionEmail = styled(FieldSection)`
  width: 48.5%  !important;
  margin-left: 3% !important;
  @media (max-width: ${MOBILE.maxWidth}PX)
  {
  margin-top: 5%;
  margin-left: 0% !important;
  width: 100%  !important;

  }
`;

const FieldSectionCompanyDetail = styled(FieldSection)`
margin-top: 2% !important;
@media (max-width: ${MOBILE.maxWidth}px){
margin-top: 5% !important;
}
`

const HearderPara = styled.label`
display: block;
font-size: 20px;
@media (max-width: ${MOBILE.maxWidth}px){
font-size: 16px;
}
`

const ContentPara = styled.label`
font-size: 18px;
@media (max-width: ${MOBILE.maxWidth}px){
font-size: 14px;
}
@media (min-width: ${MEDIUM.minWidth}px) 
{
font-size: 16px;
}
`

const BasicInfo = ({
  setFieldValue,
  values,
  errors,
  setIsEmailValidated,
  isEmailValidated,
}: any) => {
  const [formErrors, setFormErrors] = useState<any>(errors);
  const { user } = useContext(UserContext);

  useEffect(() => {
    setFormErrors(errors || []);
    user && setFieldValue('email', user.email)
  }, [errors]);

  const validateEmail = (email: any) => {
    if (!email) {
      setFormErrors({ ...formErrors, email: "Required email" });
      setIsEmailValidated(false);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setFormErrors({ ...formErrors, email: "Invalid email address" });
      setIsEmailValidated(false);
    } else {
      setFormErrors({ ...formErrors, email: "" });
      setIsEmailValidated(true);
    }
  };

  return (
    <>
      <HearderPara>
        <b>Simple Chatbot Knowledge Base Setup</b>
      </HearderPara>
      <ContentPara>
        Just add a few details, and our AI will create a customized knowledge
        base for your chatbot. It’s quick and straightforward.
      </ContentPara>

      <FieldSectionDiv>
        <FieldSectionName>
          <FieldLabel>Company Name</FieldLabel>
          <FormInput
            name="companyName"
            type="text"
            onChange={(e: any) => {
              setFieldValue(`companyName`, e.target.value);
            }}
          />
        </FieldSectionName>

        {!user ? (
          <FieldSectionEmail>
            <FieldLabel>Your Email*</FieldLabel>
            <FormInput
              name="email"
              type="email"
              onChange={(e: any) => {
                setFieldValue("email", e.target.value);
                validateEmail(e.target.value);
              }}
            />
            {formErrors.email || !isEmailValidated ? (
              <div style={{ color: "red" }}>{formErrors.email}</div>
            ) : (
              <></>
            )}
          </FieldSectionEmail>
        ) : (
          <FieldSectionEmail>
            <FieldLabel>Your Email*</FieldLabel>
            <FormInput
              name="email"
              type="email"
              disabled={true}
              value={user?.email}
            />
          </FieldSectionEmail>
        )}
      </FieldSectionDiv>

      <FieldSectionCompanyDetail>
        <FieldLabel>
          Please provide a brief summary of your company, product, or service.
        </FieldLabel>
        <FormInputTextarea
          defaultValue={values.commpanyDetails}
          name="commpanyDetails"
          onChange={(e: any) => {
            setFieldValue(`commpanyDetails`, e.target.value);
          }}
        />
      </FieldSectionCompanyDetail>
    </>
  );
};

export default BasicInfo;
