import { useCallback, useContext, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { BookContext } from "../context/BookContext";
import { UserContext } from "../context/UserContext";
import AdminProvider from "../context/admin-context";
import { LoaderContext } from "../context/loader-context";
import { NotesContext } from "../context/notes-context";
import socket from "../dropchat-socket"; //"../../dropchat-socket";
import {
  EventCategory,
  PlanEvents,
  SubCategory,
} from "../event-utils/dropchat-event-enums";
import {
  getDeviceInfoForTrackEvent,
  getUsersEmail,
} from "../event-utils/dropchat-event-track";
import { trackEvent } from "../event-utils/track-event";
import { magic } from "../magic";
import { Mixpanel } from "../mixpanel";
import myAxios, { setAuthHeader } from "../myaxios";
import useDebounce from "../use-debounce";
import AdminView from "./admin/admin-view";
import UserView from "./admin/user-view";
import BookChat from "./book-chat/book-chat";
import BookArea from "./book/book-area";
import ChatWithBook from "./chatWithBook";
import Notes from "./notes/notes";
import SearchBook from "./searchBook";
import Subscriptions from "./subscription/subscription";
import { PlanContext } from "../context/plan-context";
import { isTestUser } from "../utils/utils";
import { TeamContext } from "../context/team-context";
import TeamBookChat from "./publicComponent/team-book-chat";
import PdfTextExtrator from "./book/pdf-text-extrator";
import TrialChatbot from "./publicComponent/trial-chatbot/trial-chatbot";
import ChatbotBuilder from "./book/chatbot-builder/chatbot-builder";
import ChatbotBuilderChatPage from "./book/chatbot-builder/chatbot-builder-chat-page";
import CreateWithChatbotBuilder from "./book/chatbot-builder/create-with-chatbot-builder";
import CreateChatbotManually from "./book/chatbot-builder/create-chatbot-manually";
declare var _cio: any;

const BackgroundWrapper = ({ loadingState }: any) => {
  const {
    user,
    setUser,
    setUserDashboardDetails,
    setHandleRefresh,
    setUserProfileSettings,
    setIntegrationAppsList,
    setTotalBooksCount,
    updateIntegratedApps,
    updateUserSubscription,
  } = useContext(UserContext);
  const { addUserTeams, setSelectedTeam } = useContext(TeamContext);
  const { setNotesCategories } = useContext(NotesContext);
  const {
    setPlansList,
    setActivePlan,
    setDowngradeRequestData,
    setLoggedInUserPlan,
  } = useContext(PlanContext);
  const navigate = useNavigate();
  const [checkInProgress, setCheckInProgress] = useState(false);
  const { setLoaderMessage } = useContext(LoaderContext);
  const { updateBookIndex } = useContext(BookContext);
  const [planCondition, setplanCondition] = useState<boolean>(false);
  const location = useLocation();

  const navigateToLanding = useCallback(() => {
    loadingState(true);
    setTimeout(() => {
      loadingState(false);
      navigate("/landing");
    }, 2000);
  }, [navigate, loadingState]);

  useEffect(() => {
    if (location.pathname === "/subscription/failed") {
      setLoaderMessage(
        "Unfortunately, your payment failed. Please try again..."
      );
      setplanCondition(true);
    } else if (location.pathname === "/subscription/success") {
      setLoaderMessage(
        "Your payment was successful, Thank you!. Loading your subscription information. Please wait..."
      );
      setplanCondition(true);
    } else {
      setLoaderMessage("Verifying Credentials. Please Wait...");
    }
    // eslint-disable-next-line
  }, []);

  useDebounce(
    () => {
      // console.log("Here to verify crential ...");
      const getUserDataFromServer = async (didToken: string) => {
        const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: didToken,
            deviceInfo: await getDeviceInfoForTrackEvent(),
          }),
        });

        if (res.status === 200) {
          const data = await res.json();
          // Set the UserContext to the now logged in user
          await magic.user.getMetadata();

          setUser({ ...data });

          if (data.jwt) setAuthHeader(data.jwt);

          return true;
        } else {
          // navigate("/login");
          navigateToLanding();
        }

        return false;
      };
      if (!user || !user.id) {
        const localUser = localStorage.getItem("user");

        const user = localUser ? JSON.parse(localUser) : undefined;

        if (user && user.jwt) {
          setAuthHeader(user.jwt);
          setUser({ ...user });

          setTimeout(() => {
            myAxios
              .get(process.env.REACT_APP_SERVER_URL + "/user-profile")
              .then((response) => {
                if (response.data) {
                  localStorage.setItem("user", JSON.stringify(response.data));
                  setUser(response.data);
                }
              })
              .catch((error) => {
                // handle error
                setAuthHeader("");
                setUser(undefined);
                localStorage.setItem("verifyCredentialRequired", "no");
                localStorage.setItem("user", "");
                NotificationManager.error("Session expire, please login again");
                // console.log("Error ", error);
                navigate("/login");
              });
          }, 10000);
        } else if (!checkInProgress) {
          setCheckInProgress(true);
          loadingState(true);
          magic.user.isLoggedIn().then((isAuthenticated) => {
            // loadingState(false);
            //  setLocalLoading()
            if (isAuthenticated) {
              // already authenticated get meta data & login user
              // get user meta data
              magic.user.getIdToken().then((token) => {
                if (token) {
                  getUserDataFromServer(token).then((res) => {
                    if (res) {
                      localStorage.setItem("verifyCredentialRequired", "yes");
                      loadingState(false);
                      setHandleRefresh(true);
                      if (location.pathname === "/") {
                        navigate("/my-chatbots/create-pdf-chatbot");
                      } else navigate(location.pathname);
                    } else {
                      navigateToLanding();
                    }
                  });
                } else {
                  navigateToLanding();
                }
              });
            } else {
              navigateToLanding();
            }
          });
        }
      } else {
        loadingState(false);
        setHandleRefresh(true);
      }
      // eslint-disable-next-line
    },
    [user, checkInProgress, navigateToLanding],
    500
  );

  useEffect(() => {
    if (user && user.id) {
      if (
        user?.email &&
        !isTestUser(user.email) &&
        process.env.REACT_APP_ENV === "production"
      ) {
        // event only track when user is not test user
        const d = new Date();
        const seconds = Math.round(d.getTime() / 1000);
        _cio.identify({
          // Required attributes
          id: user.email, // Use either cio_id, id, or email.

          // Strongly recommended attributes
          created_at: seconds, // Timestamp in your system that represents when
          // the user first signed up. You'll want to send it
          // as seconds since the epoch.

          // Example attributes (you can name attributes what you wish)
          first_name: user.firstname,
          last_name: user.lastname,
          plan: "basic", // To use the example segments, set this to 'free' or 'premium'.
        });
        Mixpanel.identify(user.email);
        Mixpanel.people.set({
          $email: user.email,
          email: user.email,
          $first_name: user.firstname,
          $last_name: user.lastname,
        });
      }
      myAxios
        .get(process.env.REACT_APP_SERVER_URL + "/user/user-profile")
        .then((response) => {
          if (response.data && response.data.data) {
            setUserProfileSettings(response.data.data);
          }
        })
        .catch((error) => {
          // handle error
          // console.log("user settings error, ", error.response.data);
        });

      myAxios
        .get(process.env.REACT_APP_SERVER_URL + "/books/count")
        .then((response) => {
          setTotalBooksCount(response.data.data.totalBooksCount);
        })
        .catch((error) => {
          // handle error
          // console.log(error.response.data);
        });

      myAxios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/admin-settings/user/get-user-dashabord-details`
        )
        .then((response) => {
          if (response.data && response.data.data) {
            setUserDashboardDetails(response.data.data);
          }
        })
        .catch((error) => {
          // handle error
          // console.log(error.response.data);
        });

      myAxios
        .get(`${process.env.REACT_APP_SERVER_URL}/notes-categories`)
        .then((response) => {
          if (response.data && response.data.data) {
            setNotesCategories(response.data.data);
          }
        })
        .catch((error) => {
          // handle error
          // console.log(error.response.data);
        });

      myAxios
        .get(process.env.REACT_APP_SERVER_URL + "/integration-apps")
        .then((response) => {
          if (response.data.success) {
            setIntegrationAppsList(response.data.data);
          }
        });

      myAxios.get(`${process.env.REACT_APP_SERVER_URL}/plans`).then((res) => {
        if (res.data.success) {
          setPlansList(res.data.data.plans);
          setActivePlan(res.data.data.currentSubscription);
          setDowngradeRequestData(res.data.data.downgradeRequestData);
          updateUserSubscription(res.data.data.currentSubscription);
          setLoggedInUserPlan(
            res.data.data.loggedInUserPlans.currentSubscription
          );
        }
      });
      myAxios
        .get(process.env.REACT_APP_SERVER_URL + "/teams/teamsByUser")
        .then((response) => {
          // console.log("response is , ", response.data);
          if (response.data && response.data.success) {
            addUserTeams(response.data.data.teamsWithMembers);
            setSelectedTeam(response.data.data.lastSelectedTeam);
          } else {
          }
        })
        .catch((error) => {
          // handle error
          // resetForm();
        });
    }
    // eslint-disable-next-line
  }, [!user]);

  useEffect(() => {
    if (user && planCondition) {
      if (location.pathname === "/subscription/failed") {
        trackEvent({
          email: getUsersEmail(user),
          eventCategory: EventCategory.PLAN,
          event: PlanEvents.USER_PURCHASE_PLAN_FAILED,
          subCategory: SubCategory.EVENT,
          description: "Unfortunately, your last payment failed.",
        });
        setplanCondition(false);
      } else if (location.pathname === "/subscription/success") {
        trackEvent({
          email: getUsersEmail(user),
          eventCategory: EventCategory.PLAN,
          event: PlanEvents.USER_PURCHASE_PLAN_SUCCEED,
          subCategory: SubCategory.EVENT,
          description: "Your payment was successful, Thank you!.",
        });
        setplanCondition(false);
      }
    }
    // eslint-disable-next-line
  }, [user, planCondition]);

  useEffect(() => {
    if (user && user.email) {
      // console.log("Here to setup socket book event");
      // console.log("here to setup socket");
      socket.emit("join", {
        email: user.email,
      });
      socket.off("book-event");
      socket.on("book-event", (data) => {
        if (data) {
          if (data.book) {
            updateBookIndex(data.book);
          }
        }
      });
      socket.on("integrated-event", (data) => {
        if (data) {
          if (data) {
            updateIntegratedApps(data);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [user]);

  return user && user.id ? (
    <>
      <Routes>
        <Route
          path="/my-chatbots"
          element={<BookArea loadingState={loadingState} />}
        />
        <Route
          path="/my-chatbots/create-pdf-chatbot"
          element={<BookArea loadingState={loadingState} />}
        />
        <Route
          path="/my-chatbots/create-url-chatbot"
          element={<BookArea loadingState={loadingState} />}
        />
        <Route
          path="/my-chatbots/create-text-chatbot"
          element={<BookArea loadingState={loadingState} />}
        />
        <Route
          path="/my-chatbots/create-character-chatbot"
          element={<BookArea loadingState={loadingState} />}
        />
        <Route
          path="/books"
          element={<BookArea loadingState={loadingState} />}
        />
        <Route path="/chat-with-book" element={<ChatWithBook />} />
        <Route
          path="/books/:bookId/chatbot-settings/chat"
          element={<BookChat />}
        />
        <Route
          path="/create-chatbot/chatbot-builder/:bookId"
          element={<ChatbotBuilderChatPage />}
        />
        <Route
          path="/books/:bookId/chatbot-settings/data-sources"
          element={<BookChat />}
        />
        <Route
          path="/books/:bookId/chatbot-settings/add-pdf-sources"
          element={<BookChat />}
        />
        <Route
          path="/books/:bookId/chatbot-settings/add-text-sources"
          element={<BookChat />}
        />
        <Route
          path="/books/:bookId/chatbot-settings/add-url-sources"
          element={<BookChat />}
        />
        <Route
          path="/books/:bookId/chatbot-settings/add-shopify-sources"
          element={<BookChat />}
        />
        <Route
          path="/books/:bookId/chatbot-settings/manage-chatbot"
          element={<BookChat />}
        />
        <Route
          path="/books/:bookId/chatbot-settings/analytics"
          element={<BookChat />}
        />
        <Route
          path="/books/:bookId/lead-settings/login-settings"
          element={<BookChat />}
        />
        <Route
          path="/books/:bookId/lead-settings/theme-settings"
          element={<BookChat />}
        />
        <Route
          path="/books/:bookId/lead-settings/chatbot-sessions"
          element={<BookChat />}
        />
        <Route
          path="/books/:bookId/lead-settings/sample-questions"
          element={<BookChat />}
        />
        <Route path="/team/:bookId" element={<TeamBookChat />} />
        <Route path="/search-book" element={<SearchBook />} />
        <Route path="/create-chatbot" element={<ChatbotBuilder />} />
        <Route path="/create-chatbot/chatbot-builder" element={<CreateWithChatbotBuilder/>} />
        <Route path="/create-chatbot/manual" element={<CreateChatbotManually/>} />
        <Route path="/flashcards" element={<Notes />} />
        <Route path="/subscription/*" element={<Subscriptions />} />
        <Route path="/rfp-analyzer/*" element={<PdfTextExtrator />} />
        <Route
          path="/settings/*"
          element={
            // <AdminProvider>
            <UserView />
            // </AdminProvider>
          }
        />
        <Route
          path="/adminsettings/*"
          element={
            <AdminProvider>
              <AdminView />
            </AdminProvider>
          }
        />
      </Routes>
    </>
  ) : (
    <></>
  );
};

export default BackgroundWrapper;
