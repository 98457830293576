import { Navbar } from "react-bootstrap";
import styled from "styled-components";
import TeamSettingsButton from "./team-settings-button";
import { useContext, useEffect } from "react";
import { TeamContext } from "../../../context/team-context";
import WarningIcon from "../../../images/warning-icon.svg";
import { UserContext } from "../../../context/UserContext";
import { PlanContext } from "../../../context/plan-context";

// Styled component for the main Navbar
const NavbarMain = styled(Navbar)`
  padding-left: 2% !important;
  padding-right: 2% !important;
  width: 100% !important;
  z-index: 8 !important;
  background: #d9d3e5 !important;
  display: flex !important;

    flex-direction: column !important; 
`;

const UpperRow = styled.div`
  display: flex !important;
width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between !important;
    margin-bottom: 2%;

`
const LowerRow = styled.div`
  display: flex !important;
width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between !important;

`

// Styled component for labels
const Label = styled.label`
  font-size: 12px;
`;

// Styled component for warning labels
const WarningLabel = styled.label`
  color: #ec2d2d;
  display: flex;
  align-items: center;
  gap: 8px;
    width: auto;
    font-size: 12px;
 
`;

const NavbarForTeamUserMobile = ({ setShowTeamSettingsPopup, setIsButtonCreateNewTeam }: any) => {
    const { userRoleInTeam, findUserRole } = useContext(TeamContext);
    const { user } = useContext(UserContext);
    const { activePlan } = useContext(PlanContext);

    useEffect(() => {
        if (user) {
            findUserRole(user);
        }
    })

    return (
        <NavbarMain>
            <UpperRow>
                {/* Display user role if available */}
                <Label htmlFor="">User Role:
                    {userRoleInTeam && userRoleInTeam === 'owner' && <b> Owner</b>}
                    {userRoleInTeam && userRoleInTeam === 'admin' && <b> Team Admin</b>}
                    {userRoleInTeam && userRoleInTeam === 'member' && <b> Member</b>}
                    {userRoleInTeam && userRoleInTeam === 'viewer' && <b> Viewer</b>}
                </Label>

                {/* Team settings button */}
                <TeamSettingsButton
                    setShowTeamSettingsPopup={setShowTeamSettingsPopup}
                    setIsButtonCreateNewTeam={setIsButtonCreateNewTeam}
                />
            </UpperRow>

            <LowerRow>
                {/* Warning message about chatbot creation restriction */}
                {!activePlan ?
                    <WarningLabel>
                        <img src={WarningIcon} alt="Warning" />
                        <b>Note: Unable to create a chatbot as the team owner doesn't have a valid plan.</b>
                    </WarningLabel>
                    :
                    (
                        activePlan &&
                        activePlan?.meta && activePlan?.planStatics &&
                        activePlan?.meta?.noOfBooks && activePlan?.planStatics?.totalBooksCount &&
                        activePlan?.meta?.noOfBooks <= activePlan?.planStatics?.totalBooksCount &&
                        <WarningLabel>
                            <img src={WarningIcon} alt="Warning" />
                            <b>Note: Unable to create a chatbot as the team owner's plan has expired.</b>
                        </WarningLabel>
                    )
                }
            </LowerRow>
        </NavbarMain>
    );
};

export default NavbarForTeamUserMobile;
