
import { useContext } from "react";
import { TeamContext } from "../../../../context/team-context";
import styled from "styled-components";
import { Navbar } from "react-bootstrap";
import { MOBILE } from "../../../../constants/mobile";
import { MEDIUM } from "../../../../constants/desktop";
import { BookContext } from "../../../../context/BookContext";
import TeamIcon from '../../../../images/teams-icon-for-chatbot.svg'
import TeamPopupForChatPage from "./popup/team-popup-for-chat-page";

const NavbarMain = styled(Navbar)`
padding-left: 2% !important;
padding-right: 2% !important;
border-radius: 4px !important;
/* justify-content: space-between !important;  */
width: 100% !important;
z-index: 8 !important;
color: white !important;
background: #2F3349 !important;
align-items: center !important;
@media (max-width: ${MOBILE.maxWidth}px) {
flex-direction: row !important;  
align-items: center !important;
text-align: center !important;
font-size: 8px !important;
}
@media (min-width: ${MEDIUM.minWidth}px) {
justify-content: start !important; 
}  
`;

const UserRoleLabel = styled.label`
    margin-left: 3%;
`

const MiddleDiv = styled.div`
align-items: center;
`

const MoreTeamButton = styled.button`
cursor: default;
border: none;
border-radius: 20px;
background: #5F647D;
color: white;
position: relative;
:hover div{
display: block;
cursor: default;
}
`

const TeamIconImage = styled.img`
  padding: 2px 5px;
  border-radius: 2px;
  text-align: center;
 margin-right: 3%;
 @media (max-width: ${MOBILE.maxWidth}px) {
width: 60px;
}
`
const HoverDiv = styled.div`
position: absolute;
display: none;
`

const NavbarForChatPage = ({ createMethod }: any) => {
    const { userRoleInTeam } = useContext(TeamContext);
    const { selectedBook } = useContext(BookContext);
    const currentBook: any = selectedBook;
    const bookTeams: any = currentBook && currentBook.teams && currentBook.teams

    return (
        <>
            <NavbarMain>

                {selectedBook?.canTeamMembersView && <TeamIconImage src={TeamIcon} alt="" />}

                <MiddleDiv>
                    <>{bookTeams && bookTeams[0].name}</>&nbsp;&nbsp;
                        {bookTeams && bookTeams.length > 1 &&
                            <MoreTeamButton type="button">+ {bookTeams && bookTeams.length - 1} more
                                <HoverDiv>
                                    <TeamPopupForChatPage />
                                </HoverDiv>
                            </MoreTeamButton>
                        }
                </MiddleDiv>

                <UserRoleLabel htmlFor="">User Role:
                    {userRoleInTeam && userRoleInTeam === 'owner' && <b> Owner</b>}
                    {userRoleInTeam && userRoleInTeam === 'admin' && <b> Team Admin</b>}
                    {userRoleInTeam && userRoleInTeam === 'member' && <b> Member</b>}
                    {userRoleInTeam && userRoleInTeam === 'viewer' && <b> Viewer</b>}
                </UserRoleLabel>

            </NavbarMain>


        </>
    );
}

export default NavbarForChatPage