import { Navbar } from "react-bootstrap";
import styled from "styled-components";
import { MOBILE } from "../../constants/mobile";
import { TAB } from "../../constants/tab";
import { MEDIUM } from "../../constants/desktop";
import BackImage from '../../images/back-button-black.svg'
import { useContext } from "react";
import { TeamContext } from "../../context/team-context";
import { CustomBlurDivContext } from "../../context/custom-blur-div-context";

const NavbarMain = styled(Navbar)`
padding-left: 2% !important;
padding-right: 2% !important;
justify-content: start !important; 
width: 100% !important;
z-index: 12 !important;
background: #D9D3E5 !important;
@media (max-width: ${MOBILE.maxWidth}px) {
flex-direction: row !important;  
align-items: start !important;
font-size: 11px !important;
}
@media (min-width: ${TAB.minWidth}px) {
align-items: start !important;

}
@media (min-width: ${MEDIUM.minWidth}px) {
justify-content: start !important; 

}
  
`;

const Label = styled.label`
margin-left: 3rem;
font-size: 18px;
@media (max-width: ${MOBILE.maxWidth}px) {
margin-left: 3%;
font-size: 12px;

}
`


const BackButton = styled.button`
border: none;
padding: 0;
background: none;
`

const BackButtonImage = styled.img`
@media (max-width: ${MOBILE.maxWidth}px) {
width: 15px;
}

`

const TeamSettingsNavbar = ({ setShowTeamSettingsPopup, isCreateNewTeam }: any) => {
    const { setIsBackgroundBlur } = useContext(CustomBlurDivContext)
    const closePopup = () => {
        setShowTeamSettingsPopup(false)
        setIsBackgroundBlur(false)
    }

    return (
        <>
            <NavbarMain>

                <BackButton type="button" onClick={() => { closePopup() }}> <BackButtonImage src={BackImage} alt="" /></BackButton>


                {/* Changed the label from 'Team Name' to 'Team Settings Method' because, 
               while creating a new team for the user,
                the last selected team's name was displayed,
                 which could be confusing. */}

                {/* {!isCreateNewTeam && <Label htmlFor="">{selectedTeam?.name}</Label>} */}
                {isCreateNewTeam ? <Label>Create New Team</Label> : <Label>Update Team</Label>}

            </NavbarMain>
        </>
    )
}

export default TeamSettingsNavbar