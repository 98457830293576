import { Button, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import CrossButton from "../../../images/cross.svg";

import { useContext, useState } from "react";
import { MEDIUM } from "../../../constants/desktop";
import { MOBILE } from "../../../constants/mobile";
// import "./App.css";

const MainSection = styled(Container)`
display: flex !important;
  position: absolute !important;

`;

const MainDiv = styled.div`
display: flex;
  position: absolute;
  flex-direction: column;
  width: 90%;
  background-color: rgb(0 0 0 / 59%);
  z-index: 9999;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  left:3%;
  @media (min-width: ${MEDIUM.minWidth}px){
  width: 60%;
  left: 20%;

  }
`;

const FormInputTextareaQuestion = styled.textarea`
  border: 1px solid rgba(201, 199, 206, 1);
  border-radius: 4px;
  height: 2rem;
  overflow: hidden;
  padding-left: 5px;
  width: 95%;
`;

const AddButton = styled.button`
background: #1585b5;
height: 40px;
border-radius: 4px;
border: none;
width: 40%;
color: #fff;
margin-top: 3%;
&:hover {
background: #0b5f82;
}
&:focus {
background: #0b5f82;
}

@media (min-width: ${MEDIUM.minWidth}px)
{
width: 30%;
margin-top: 2%;
}

@media (max-width: ${MOBILE.maxWidth}px)
{
width: 60%;
font-size: 14px;
height: 35px;
margin-top: 5%;
}
`;

const CloseButton = styled.button`
  margin-left: 93.5%;
  padding-top: 1%;
  border-radius: 8px;
  border:none;  
  background: #fff;
   &:hover {
    // background: #0b5f82;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  }
  
  @media (max-width: ${MOBILE.maxWidth}px){
    margin-left: 88.5%;
  }
  @media (min-width: ${MEDIUM.minWidth}px){
    margin-left: 96.5%;
  padding-top: 0.5%;
  }
`;

const Content = styled.div`
width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 2%;
  padding-bottom: 5%;
`;

const BlurDiv = styled.div`
position: absolute;
// height: max;
top: 0;
bottom: 0;
right: 0;
left: 0;
background: gray;
opacity: 50%;
z-index: 99;
`

const EditQuestionPopup = ({
  setShowEditPopup,
  questionToEdit,
  setQuestionToEdit,
  setNewQuestion,
}: any) => {
  return (
    <>
      <MainSection>
        <MainDiv>
          <CloseButton
            type="button"
            onClick={() => {
              setShowEditPopup(false);
            }}
          >
            <img src={CrossButton} alt="" />
          </CloseButton>
          <Content>
            <p>Type your question here..</p>
            <FormInputTextareaQuestion
              value={questionToEdit}
              onChange={(e: any) => {
                setQuestionToEdit(e.target.value);
              }}
            />
            <AddButton
              type="button"
              onClick={() => {
                setShowEditPopup(false);
                setNewQuestion(questionToEdit)

              }}
            >
              {" "}
              + Add Question
            </AddButton>
          </Content>
        </MainDiv>
      </MainSection>

      <BlurDiv style={{ height: '100%' }}></BlurDiv>
    </>
  );
};

export default EditQuestionPopup;
