import styled from "styled-components";
import { MOBILE } from "../../../constants/mobile";
import SettingsIcon from "../../../images/settings-button.svg";
import { CustomBlurDivContext } from "../../../context/custom-blur-div-context";
import { useContext } from "react";

// Styled component for the team settings button
const TeamSettingsButtonComponent = styled.button`
  background: #1585b5;
  border-radius: 4px;
  color: #fff;
  border: none;
  padding: 0.4rem 1rem;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s ease;
  &:hover {
    background: #126a8c;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem 1rem;
    font-size: 10px;
    margin-top: 0.2rem;
  }
`;

// Functional component for the team settings button
const TeamSettingsButton = ({
  setShowTeamSettingsPopup,
  setIsButtonCreateNewTeam,
}: any) => {

  const { setIsBackgroundBlur } = useContext(CustomBlurDivContext)


  const handleClick = () => {
    setShowTeamSettingsPopup(true);
    setIsBackgroundBlur(true)
    setIsButtonCreateNewTeam(false);
  };

  return (
    <TeamSettingsButtonComponent type="button" onClick={handleClick}>
      <img src={SettingsIcon} alt="Settings Icon" /> &nbsp; Team Settings
    </TeamSettingsButtonComponent>
  );
};

export default TeamSettingsButton;
