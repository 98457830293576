import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { MEDIUM } from "../../constants/desktop";
import { MOBILE } from "../../constants/mobile";
import { TAB } from "../../constants/tab";
import { BookContext } from "../../context/BookContext";
import { CodeBoxContext } from "../../context/code-box-context";
import { LoaderContext } from "../../context/loader-context";
import { PaginationContext } from "../../context/pagination-context";
import ProgressIndicator from "../../images/loading_bar.gif";
import PdfUrl from "../../images/pdf-url.svg";
import PdfYoutubeUrl from "../../images/pdf-youtube-url.svg";
import PdfYoutube from "../../images/pdf-youtube.svg";
import PDFSign from "../../images/pdfSign.svg";
import TextSign from "../../images/txtSign.svg";
import URLSign from "../../images/urlSign.svg";
import YoutubeUrl from "../../images/youtube-url.svg";
import YouTubeSign from "../../images/youtube.svg";
import myAxios from "../../myaxios";
import { Book } from "../../types";
import Pagination from "../pagination/pagination";
import AddMoreFileBox from "../utility-component/add-more-file-box";
import CodeBox from "../utility-component/code-box";
import { TeamContext } from "../../context/team-context";
import { NotificationManager } from "react-notifications";
import TeamIcon from "../../images/teams-icon-for-chatbot.svg"
import { TeamDropdownContext } from "../../context/team-dropdown-context";


const BookImage = styled.img`
  box-shadow: 2px 2px 10px -4px #000000bd;
  margin-bottom: 5px;
  border-radius: 10px;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
`;
const ImageDiv = styled.div`
  width: 100%;
  height: 230px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 100%;
    height: 214px;
  }
`;

const BookListParent = styled(Col)`
  margin: 0 0 2% 0 !important;
  flex-direction: column !important;
  transition: 0.4s ease !important;
  position: relative !important;
  & p {
  }
  & a {
    text-decoration: none !important;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: 210px !important; 
     margin-right: 1% !important;
  }
`;

const SingleBookTitle = styled.p`
align-items: center;
  width: 100%;
  color: #33303c;
  margin-bottom: 10px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
    font-weight: 400;
    white-space: normal;
  }
  @media (min-width: ${TAB.minWidth}px) {
    white-space: normal;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    white-space: nowrap;
    font-size: 16px;
  }
`;

const BookActions = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0;
  & a {
    display: flex;
  }
`;

const TeamIconImage = styled.img`
  padding: 2px 5px;
  border-radius: 2px;
  position: absolute;
  top: 0.2rem;
  left: 0.7rem;
  text-align: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    top: 0.1rem;
  }
`

const IndexStatus = styled.span`
  background: ${(props: BackgroundColor) => props.background};
  color: #fff;
  padding: 2px 5px;
  border-radius: 5px;
  position: absolute;
  top: 0.2rem;
  right: 1rem;
  font-size: 10px;
  text-align: center;
`;

const PaginationRow = styled(Row)`
  justify-content: center !important;
  width: 100% !important;
  margin-top: 2rem !important;
  width: 100% !important;
  align-items: end !important;
  border-radius: 4px !important;
  & .col {
    text-align: center !important;
  }
`;

const ProgressBarSpan = styled.span`
  font-size: 12px;
  color: #33303c99;
  font-weight: 600;
`;

interface BackgroundColor {
  background: string;
}

const BookLibrary = ({
  allBooks,
  setTotalPage,
  totalPage,
  resetPagination,
  setResetPagination,
  isUserCanChange,
}: any) => {
  const { setShowLoader, showLoader } = useContext(LoaderContext);
  const { selectedTeam, userRoleInTeam } = useContext(TeamContext);
  // eslint-disable-next-line
  const [selectedChatbot, setSelectedChatbot] = useState();
  const { setBooks, setSelectedBook, setNoOfBots } = useContext(BookContext);
  // const { user, setTotalBooksCount } = useContext(UserContext);
  const { pageLimit, setPageLimit, pageSkip } = useContext(PaginationContext);
  const { showConfirmationBox } = useContext(CodeBoxContext);
  const { selectedDropdownValue, setSelectedDropdownValue } = useContext(TeamDropdownContext);


  const pageChange = (currentPage: any) => {
    const skip = (currentPage - 1) * pageLimit;

    if (selectedTeam) {
      if (selectedDropdownValue !== 'All Chatbots') {
        setShowLoader(true);
        myAxios
          .post(process.env.REACT_APP_SERVER_URL + "/books/paginated-books", {
            pagination: { limit: pageLimit, skip: skip },
            team: selectedTeam?._id,
          })
          .then((response) => {
            setBooks(response.data.data.books);
            setTotalPage(response.data.data.count);
            setNoOfBots(response.data.data.count);
            setShowLoader(false);
          })
          .catch((error) => {
            // console.log("Getting Error at the time of get paginated books ", error);
            setShowLoader(false);
          });
      }
      else {
        setShowLoader(true);
        myAxios
          .post(process.env.REACT_APP_SERVER_URL + "/books/get-all-books-for-user", {
            // team: selectedTeam._id,
            pagination: { limit: pageLimit, skip: pageSkip },
          })
          .then((response) => {
            setBooks(response.data.data.books);
            setTotalPage(response.data.data.count);
            setNoOfBots(response.data.data.count);
            setShowLoader(false);

            // setNoOfTeamBooks(
            //   response.data.data.totalTeamBooks.length > 0
            //     ? response.data.data.totalTeamBooks.length
            //     : 0
            // );
          })
          .catch((error) => {
            setShowLoader(false);
          });
      }
    }

  };
  const pageLimitChange = (pageLimit: number) => {
    setPageLimit(pageLimit);

    if (selectedDropdownValue !== 'All Chatbots') {
      myAxios
        .post(process.env.REACT_APP_SERVER_URL + "/books/paginated-books", {
          pagination: { limit: pageLimit, skip: pageSkip },
          team: selectedTeam?._id,
        })
        .then((response) => {
          setBooks(response.data.data.books);
          setTotalPage(response.data.data.count);
          setNoOfBots(response.data.data.count);
        })
        .catch((error) => {
          // console.log("Getting Error at the time of get paginated books ", error);
        });
    }
    else {
      myAxios
        .post(process.env.REACT_APP_SERVER_URL + "/books/get-all-books-for-user", {
          // team: selectedTeam._id,
          pagination: { limit: pageLimit, skip: pageSkip },
        })
        .then((response) => {
          setBooks(response.data.data.books);
          setTotalPage(response.data.data.count);
          setNoOfBots(response.data.data.count);
          // setNoOfTeamBooks(
          //   response.data.data.totalTeamBooks.length > 0
          //     ? response.data.data.totalTeamBooks.length
          //     : 0
          // );
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
  };

  useEffect(() => {
    if (showConfirmationBox) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showConfirmationBox]);

  const bookImageURL = (book: Book): string => {
    // Check if book has an image URL
    if (book.imageUrl && book.imageUrl.length > 0) {
      return book.imageUrl;
    }

    // Default image URL is PDFSign
    let imageUrl = PDFSign;

    // Determine if the book is PDF, URL, or YouTube
    let isPdf = false;
    let isUrl = false;
    let isYT = false;

    // Iterate over sources to determine types
    book.sources?.forEach((source: any) => {
      switch (source.type) {
        case "aws":
          isPdf = true;
          break;
        case "url":
          isUrl = true;
          break;
        case "youtube":
          isYT = true;
          break;
        case "text":
          imageUrl = TextSign;
          break;
        case "character":
          imageUrl = TextSign;
          break;
      }
    });

    // Set image URL based on source types
    if (isPdf && isYT && isUrl) {
      imageUrl = PdfYoutubeUrl;
    } else if (isPdf && isYT && !isUrl) {
      imageUrl = PdfYoutube;
    } else if (isPdf && !isYT && isUrl) {
      imageUrl = PdfUrl;
    } else if (!isPdf && isYT && isUrl) {
      imageUrl = YoutubeUrl;
    } else if (!isPdf && isYT && !isUrl) {
      imageUrl = YouTubeSign;
    } else if (isPdf && !isYT && !isUrl) {
      imageUrl = PDFSign;
    } else if (!isPdf && !isYT && isUrl) {
      imageUrl = URLSign;
    }

    return imageUrl;
  };

  const getFileStatus = (status: number) => {
    if (status === 1) {
      return "UNDER PROCESSING";
    } else if (status === 2) {
      return "READY";
    } else if (status === 3) {
      return "FAILED";
    } else {
      return "UNDER PROCESSING";
    }
  };
  return (
    <>
      {allBooks &&
        allBooks.length > 0 &&
        allBooks.map((book: any, idx: any) => (
          <BookListParent
            key={Math.random()}
            xxl={2}
            xl={2}
            lg={2}
            md={3}
            sm={4}
            xs={6}
            onClick={() => {
              setSelectedBook(allBooks[idx]);
            }}
          >
            {book.planExpiredChatbot ? (
              <div
                onClick={() => {
                  NotificationManager.warning(
                    "Please purchase the plan to access this chatbot!"
                  );
                }}
              >
                <ImageDiv>
                  <BookImage src={bookImageURL(book)} />
                </ImageDiv>
              </div>
            ) : (
              <Link
                to={
                  isUserCanChange
                    ? "/books/" + book._id + "/chatbot-settings/chat"
                    : "/books/" + book._id + "/chatbot-settings/chat"
                }
              >
                <ImageDiv>
                  <BookImage src={bookImageURL(book)} />
                </ImageDiv>
              </Link>
            )}
            {
              book &&
              book.canTeamMembersView === true &&
              <TeamIconImage src={TeamIcon} alt="" />
            }

            {book.indexStatus !== 2 &&
              <IndexStatus
                background={
                  book.chatType === "dropchat"
                    ? book.indexStatus === 1
                      ? "#F49040"
                      : book.indexStatus === 2
                        ? "#21CA51"
                        : book.indexStatus === 3
                          ? "#EA4335"
                          : "#F49040"
                    : "#21CA51"
                }
              >
                {book.chatType === "dropchat"
                  ? getFileStatus(book.indexStatus)
                  : "READY"}
              </IndexStatus>
            }

            {book.indexStatus === 1 &&
              (book.type === "aws" ||
                book.type === "url" ||
                book.type === "youtube") ? (
              <>
                <BookActions
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px",
                  }}
                >
                  {book.chatbotId ? (
                    <>
                      {" "}
                      <ProgressBarSpan>Processing</ProgressBarSpan>
                      <ProgressBarSpan>
                        {book.roundProgressPercentage}%
                      </ProgressBarSpan>
                    </>
                  ) : (
                    <ProgressBarSpan>initializing....</ProgressBarSpan>
                  )}
                </BookActions>
              </>
            ) : (
              <BookActions></BookActions>
            )}

            {book.planExpiredChatbot ? (
              <div
                onClick={() => {
                  NotificationManager.warning(
                    "Please purchase the plan to access this chatbot!"
                  );
                }}
              >
                {book.indexStatus === 1 &&
                  (book.type === "aws" ||
                    book.type === "url" ||
                    book.type === "youtube") ? (
                  <>
                    {book.roundProgressPercentage < 1 ? (
                      <div>
                        <img
                          alt="img6"
                          src={ProgressIndicator}
                          style={{ width: "100%" }}
                        />
                      </div>
                    ) : (
                      <ProgressBar
                        animated
                        variant={
                          book.roundProgressPercentage < 34
                            ? "warning"
                            : book.roundProgressPercentage < 68
                              ? "info"
                              : "success"
                        }
                        now={book.roundProgressPercentage}
                        style={{ height: "8px" }}
                      />
                    )}
                  </>
                ) : null}

                {book.title ? (
                  <SingleBookTitle title={book.title}>
                    {/* <b>Title: </b> */}
                    {book.title}
                  </SingleBookTitle>
                ) : (
                  <SingleBookTitle>
                    <b>No Title </b>
                  </SingleBookTitle>
                )}
                {book.authors && book.authors[0].length > 0 && (
                  <SingleBookTitle
                    style={{
                      fontSize: "14px",
                      color: "rgba(51, 48, 60, 0.6)",
                    }}
                    title={book.authors}
                  >
                    <b>Authors: </b>
                    {book.authors?.map((item: any) => item + "\n")}
                  </SingleBookTitle>
                )}
              </div>
            ) : (
              <Link
                to={
                  isUserCanChange
                    ? "/books/" + book._id + "/chatbot-settings/chat"
                    : "/books/" + book._id + "/chatbot-settings/chat"
                }
              >
                {book.indexStatus === 1 &&
                  (book.type === "aws" ||
                    book.type === "url" ||
                    book.type === "youtube") ? (
                  <>
                    {book.roundProgressPercentage < 1 ? (
                      <div>
                        <img
                          alt="img6"
                          src={ProgressIndicator}
                          style={{ width: "100%" }}
                        />
                      </div>
                    ) : (
                      <ProgressBar
                        animated
                        variant={
                          book.roundProgressPercentage < 34
                            ? "warning"
                            : book.roundProgressPercentage < 68
                              ? "info"
                              : "success"
                        }
                        now={book.roundProgressPercentage}
                        style={{ height: "8px" }}
                      />
                    )}
                  </>
                ) : null}

                {book.title ? (
                  <SingleBookTitle title={book.title}>
                    {/* <b>Title: </b> */}
                    {book.title}
                  </SingleBookTitle>
                ) : (
                  <SingleBookTitle>
                    <b>No Title </b>
                  </SingleBookTitle>
                )}
                {book.authors && book.authors[0].length > 0 && (
                  <SingleBookTitle
                    style={{ fontSize: "14px", color: "rgba(51, 48, 60, 0.6)" }}
                    title={book.authors}
                  >
                    <b>Authors: </b>
                    {book.authors?.map((item: any) => item + "\n")}
                  </SingleBookTitle>
                )}
              </Link>
            )}
          </BookListParent>
        ))}

      {totalPage && totalPage > 16 ? (
        <PaginationRow>
          <Pagination
            itemCount={totalPage}
            perPageItem={pageLimit}
            pageChange={pageChange}
            pageLimitChange={pageLimitChange}
            resetPagination={resetPagination}
            pageLimit={pageLimit}
            setResetPagination={setResetPagination}
          />
        </PaginationRow>
      ) : null}
      <CodeBox />
      <AddMoreFileBox
        chatbot={selectedChatbot}
        pageLimitChange={pageLimitChange}
        pageLimit={pageLimit}
      />
    </>
  );
};

export default BookLibrary;
