import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import CopyToClipboard from "react-copy-to-clipboard";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BookContext } from "../../context/BookContext";
import { UserContext } from "../../context/UserContext";
import { CodeBoxContext } from "../../context/code-box-context";
import { LoaderContext } from "../../context/loader-context";
import { PaginationContext } from "../../context/pagination-context";
import { PlanContext } from "../../context/plan-context";
import {
  USER_CREATED_A_SHARE_URL_CHATBOT,
  USER_DELETE_A_BOOK,
} from "../../customer-io/events";
import {
  ChatBotEvents,
  EventCategory,
  SubCategory,
} from "../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../event-utils/dropchat-event-track";
import { trackEvent } from "../../event-utils/track-event";
import myAxios from "../../myaxios";
import { Book, manageChatbotSettingAll, Team } from "../../types";
import { MANAGE_CHATBOT } from "../../utils/class-names";
import {
  BUBBLE_CHATBOT_WELCOME_MESSAGE,
  CHATBOT_GREETING_TEXT,
  CHATBOT_WAIVER_TEXT,
  CUSTOM_ERROR_RESP,
  QA_PROMPT,
} from "../../utils/place-holder-consts";
import {
  checkSettingsChanges,
  isTestUser,
  parseErrorResponse,
} from "../../utils/utils";
import CodeBox from "../utility-component/code-box";
import ConfirmationBox from "../utility-component/confirmation-box";
import ChatbotEmailSettings from "./components/chat-email-setting";
import ChatInterfaceSettings from "./components/chat-interface-settings";
import ChatLive from "./components/chat-live";
import ChatbotSetting from "./components/chatbot-setting";
import ChatBotWaiverSetting from "./components/chatbot-waiver";
import CustomGreetingText from "./components/custom-greeting-text";
import EscalateEmail from "./components/escalate-email";
import IntegrationAppsSetting from "./components/integration-apps-setting";
import MarkChatbotAsFeatureChatbot from "./components/mark-feature-chatbot";
import SocialMediaSettings from "./components/social-media-settings";
import SummarizedAnswer from "./components/summarized-answer";
import ExternalIndexingApi from "./components/external-indexing-api";
// import GetShopifyProducts from "./components/get-shopify-products";
import DiscordIntegrationAppsSetting from "./components/discord-integration-app-settings";
import BubbleCustomGreetingText from "../bubble-chat/bubble-custom-greeting-text";
import ChatbotHeaderTitle from "./components/chatbot-header-title";
import FooterBrandingSection from "./components/footer-branding-section";
import LockIcon from "../../images/lock-icon.svg";
import { TeamContext } from "../../context/team-context";
import { CustomBlurDivContext } from "../../context/custom-blur-div-context";
import CopyChatbotPopup from "./components/copy-chatbot-to-team-feature/copy-chatbot-popup";
import CopyChatbotPopupMobile from "./components/copy-chatbot-to-team-feature/copy-chatbot-popup-mobile";
import { MOBILE } from "../../constants/mobile";

declare var _cio: any;

const ParentSection = styled.div`
  display: block;
  padding: 1rem;
  width: 100%;
`;
const SidebarHeading = styled.h3`
  color: #33303c;
  font-size: 14px;
  margin-bottom: 1rem;
`;

const MenuHeading = styled.h3`
  color: #33303c;
  font-size: 13px;
`;

const SubHeading = styled.h3`
  color: #33303c;
  font-size: 12px;
  margin-bottom: 1rem;
`;
const SubHeadingCopyChatbot = styled.h3`
  color: #33303c;
  font-size: 12px;
  margin-bottom: 1rem;
  margin-top: 2%;
  @media (max-width: ${MOBILE.maxWidth}px){
    margin-top: 10%;
    font-size: 14px;
  }
`;

const InnerSection = styled.div`
  background: #fff;
  /* padding: 1rem; */
  flex-direction: column;
  width: 100%;
  /* border: 1px solid #eaeaea; */
  /* border-radius: 8px; */
  margin-bottom: 1rem;
`;

const BlueButton = styled.button`
  background: #1585b5;
  padding: 0.3rem 0.1rem;
  color: #fff;
  border: 0;
  width: 100%;
  text-align: center;
  margin-top: 1rem;
  font-size: 13px;
`;

const RedButton = styled.button`
  background: #ec2d2d;
  padding: 0.3rem 0.1rem;
  color: #fff;
  border: 0;
  width: 50%;
  text-align: center;
  font-size: 13px;
`;

const UpgradeSpan = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
  padding: 5px 18px;
  background: #f2ae01;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
`;

// const PlanSpan = styled.span`
//   font-style: normal;
//   font-weight: bold;
//   font-size: 12px;
//   line-height: 15px;
//   color: #585858;
//   margin-left: 5px;
//   margin-top: 1px;
//   display: flex;
//   align-items: center;
// `;
const SaveButton = styled.button`
  background: #1585b5;
  padding: 0.4rem 3rem;
  color: #fff;
  border: 0;
  width: 50%;
  border-radius: 8px;
  &:hover {
    background: #0b5f82;
  }
`;
const ParentInnerSection = styled.div`
  position: relative;
  width: 100%;
`;
const OverlayRestriction = styled.div`
  background: rgba(51, 48, 60, 0.4);
  position: absolute;
  width: 100%;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  height: 100%;
  justify-content: flex-end;
  padding: 10px;
`;
const UpgradeContent = styled.span`
  color: #f2ae01;
  font-size: 16px;
  background: #fff;
  padding: 0.2rem 1rem;
  height: max-content;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
`;

const MobileManageChatbot = ({
  bookSettings,
  setBookSettings,
  selectedBook,
  showCopyChatbotPopup,
  setShowCopyChatbotPopup,
  bookId,
}: any) => {
  const navigate = useNavigate();

  const { setShowLoader } = useContext(LoaderContext);
  const { selectedTeam } = useContext(TeamContext);
  const { user, setTotalBooksCount, integrationAppList } =
    useContext(UserContext);
  const { setBooks, setNoOfBots, setSelectedBook } = useContext(BookContext);
  const { setPlanHighliter } = useContext(PlanContext);
  const { pageLimit, pageSkip } = useContext(PaginationContext);
  const { setBookId, setShowConfirmationBox, setShowIframeCodeBox } =
    useContext(CodeBoxContext);

  const [confirmationAction, setConfirmationAction] = useState<string>("");
  const [file, setSelectedFile] = useState<any>(null);
  // eslint-disable-next-line
  const [isSmallImage, setIsSmallImage] = useState(false);
  const [isTokenVerified, setIsTokenVerified] = useState<boolean>(false);

  const [manageChatbotSettingAll, setManageChatbotSettingAll] =
    useState<manageChatbotSettingAll>({
      greetingSetting: {
        message: CHATBOT_GREETING_TEXT,
        isGreeting: false,
      },
      bubbleIconMessageSettings: {
        message: BUBBLE_CHATBOT_WELCOME_MESSAGE,
        isMessage: false,
      },
      isFeatureChatbot: false,
      canTeamMembersView: false,
      waiverSetting: {
        waiverText: CHATBOT_WAIVER_TEXT,
        isWaiver: false,
      },
      interfaceSetting: {
        interfaceType: "chat",
      },
      emailSetting: {
        isSendEmail: false,
        toEmails: [],
        bccEmails: [],
      },
      escalateEmailSetting: {
        isEscalateEmail: false,
        toEmails: [],
      },
      chatWithHumanSetting: {
        isChatLive: false,
        redirectLink: "",
      },
      seoMetaSetting: {
        seoMetaSettings: false,
        socialMediaTitle: "",
        socialMediaDescription: "",
        socialMediaCustomImage: "",
      },
      integrationApp: {
        isHubspot: false,
        isZendesk: false,
        isShopify: false,
        isDiscord: false,
        discordBotToken: "",
      },
      apiConfiguration: {
        isApiConfiguration: false,
        methodType: "Post",
        url: "",
        header: "",
        body: "",
        isAfternoon: false,
        isMidNight: false,
      },
    });

  const [tempretureValue, setTempretureValue] = useState<number>(
    bookSettings && bookSettings.temperature ? bookSettings.temperature : 0.5
  );
  const [topPValue, setTopPValue] = useState<number>(
    bookSettings && bookSettings.topP ? bookSettings.topP : 0.5
  );
  const [showUtilityConfirmationBox, setShowUtilityConfirmationBox] =
    useState<boolean>(false);
  const [confirmationBoxMessage, setConfirmationBoxMessage] =
    useState<string>("");
  const { setIsBackgroundBlur } = useContext(CustomBlurDivContext);
  const [bookTeams, setBookTeams] = useState(selectedBook && selectedBook.teams && selectedBook.teams);
  const [teamToDelete, setTeamToDelete] = useState<Team>();
  const [newTeamArray, setNewTeamArray] = useState<Team[]>(selectedBook.teams);
  const [deleteItem, setDeleteItem] = useState<string>('')


  const isURLValid = (url: string) => {
    const urlRegex =
      /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;%=]*)?$/i;

    return urlRegex.test(url);
  };

  const validateChatbotSettings = (values: any) => {
    const errors: any = {};
    if (values.chatWithHumanSetting.isChatLive) {
      if (values.chatWithHumanSetting.redirectLink.length < 1) {
        errors.chatWithHumanSetting = "URL is required";
      }

      // Check if the input value is a valid URL
      if (!isURLValid(values.chatWithHumanSetting.redirectLink)) {
        errors.chatWithHumanSetting = "Invalid URL";
      }
    }
    if (values.emailSetting.isSendEmail) {
      if (values.emailSetting.toEmails < 1) {
        errors.toEmails = "Please Add toEmail";
      }

      // Check if the input value is a valid URL
      // if (values.emailSetting.bccEmails < 1) {
      //   errors.bccEmails = "Please Add bccEmail";
      // }
    }
    if (values.escalateEmailSetting.isEscalateEmail) {
      if (values.escalateEmailSetting.toEmails < 1) {
        errors.toEscalateEmails = "Please Add Email";
      }
    }

    if (
      values.apiConfiguration.isApiConfiguration &&
      !values.apiConfiguration.url
    ) {
      errors.url = "URL field required";
    }
    if (values.apiConfiguration.isApiConfiguration) {
      if (
        !values.apiConfiguration.isAfternoon &&
        !values.apiConfiguration.isMidNight
      ) {
        errors.isAfternoon = "Select at least one option";
      }
    }
    if (
      values.apiConfiguration.isApiConfiguration &&
      !values.apiConfiguration.header
    ) {
      errors.header = "Header field required";
    } else {
      if (values.apiConfiguration.isApiConfiguration) {
        try {
          let cleanHeader = values.apiConfiguration.header.replace(
            /[^\x20-\x7E]/g,
            ""
          );
          JSON.parse(cleanHeader);
        } catch (error) {
          errors.header = "Please add valid JSON";
        }
      }
    }

    // if (
    //   values.apiConfiguration.isApiConfiguration &&
    //   values.apiConfiguration.body
    // ) {
    //   try {
    //     let cleanBody = values.apiConfiguration.body.replace(
    //       /[^\x20-\x7E]/g,
    //       ""
    //     );
    //     JSON.parse(cleanBody);
    //   } catch (error) {
    //     errors.body = "Please add valid JSON";
    //   }
    // }

    return errors;
  };

  useEffect(() => {
    if (selectedBook) {
      setShowLoader(true);
      myAxios
        .get(`${process.env.REACT_APP_SERVER_URL}/chatbot/${selectedBook._id}`)
        .then((res) => {
          if (res.data.data) {
            setManageChatbotSettingAll((prevSettings) => ({
              ...prevSettings,
              ...res.data.data,
              emailSetting: {
                ...res.data.data.emailSetting,
                toEmails: (res.data.data.emailSetting?.toEmails || []).map(
                  (email: any) => ({
                    id: email,
                    text: email,
                  })
                ),
                bccEmails: (res.data.data.emailSetting?.bccEmails || []).map(
                  (email: any) => ({
                    id: email,
                    text: email,
                  })
                ),
              },
              escalateEmailSetting: {
                ...res.data.data.escalateEmailSetting,
                toEmails: (
                  res.data.data.escalateEmailSetting?.toEmails || []
                ).map((email: any) => ({
                  id: email,
                  text: email,
                })),
              },
            }));

            setShowLoader(false);
          }
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
    // eslint-disable-next-line
  }, [selectedBook]);

  const deleteBook = async (book: Book) => {
    let dataToDelete = {
      bookID: book._id,
      userID: user?.id,
    };
    setShowLoader(true);
    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/delete-book", {
        dataToDelete,
      })
      .then((response) => {
        if (response.data.success) {
          if (!isTestUser(user?.email))
            _cio.track(USER_DELETE_A_BOOK, {
              name: "user-delete-a-book",
            });
          NotificationManager.success(response.data.message);

          myAxios
            .post(process.env.REACT_APP_SERVER_URL + "/books/paginated-books", {
              pagination: { limit: pageLimit, skip: pageSkip },
              team: selectedTeam?._id,
            })
            .then((response) => {
              setBooks(response.data.data.books);
              setTotalBooksCount(response.data.data.count);
              setNoOfBots(response.data.data.count);
              // setShowLoader(false);
            })
            .catch((error) => {
              // console.log("Getting Error at the time of get paginated books ", error);
              // setShowLoader(false);
            });

          setShowLoader(false);
          navigate("/my-chatbots/create-pdf-chatbot");
        } else {
          NotificationManager.error(response.data.message);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        NotificationManager.error(parseErrorResponse(error));
        setShowLoader(false);
        // console.error("Getting Error at the time of delete chatbot ", error);
      });
  };

  const deleteConfirmationBox = () => {
    setDeleteItem('chatbot')
    setConfirmationBoxMessage("Do you really want to delete this chatbot?");
    setConfirmationAction("delete");
    setShowUtilityConfirmationBox(true);
  };

  const removeTeamConfirmationBox = (teamName: string) => {
    setDeleteItem('team')
    setConfirmationBoxMessage(`Do you really want to remove this chatbot from '${teamName}' team?`);
    setConfirmationAction("delete");
    setShowUtilityConfirmationBox(true);
  };

  const confirmClick = (action: string) => {
    if (action === "delete") {
      trackEvent({
        email: getUsersEmail(user),
        eventCategory: EventCategory.CHATBOT,
        event: ChatBotEvents.USER_DELETE_CHATBOT,
        subCategory: SubCategory.EVENT,
        chatbotType: selectedBook.fileType,
        bookId: selectedBook._id,
        rawData: {
          bookId: selectedBook._id,
          title: selectedBook.title,
        },
      });
      deleteBook(selectedBook);
    }
  };
  const [additionalInitialValues, setAdditionalInitialValues] = useState({
    bookId: "",
    bookSetting: {
      model: "gpt-4o",
      customAnswer: CUSTOM_ERROR_RESP,
      title: "",
      qaTemplate: QA_PROMPT,
      temperature: 0.5,
      topP: 0.5,
      allowMemory: false,
      isSummarized: false,
      chatbotHeaderTitle: "",
      footerBrandingText: "",
      footerBrandingLink: "",
    },
    isFeatureChatbot:
      manageChatbotSettingAll && manageChatbotSettingAll.isFeatureChatbot,
    canTeamMembersView:
      manageChatbotSettingAll && manageChatbotSettingAll.canTeamMembersView,
    chatInterfaceType: manageChatbotSettingAll.interfaceSetting.interfaceType,
    greetingSetting: {
      message: manageChatbotSettingAll.greetingSetting.message,
      isGreeting: manageChatbotSettingAll.greetingSetting.isGreeting,
    },
    bubbleIconMessageSettings: {
      message: manageChatbotSettingAll.bubbleIconMessageSettings.message,
      isMessage: manageChatbotSettingAll.bubbleIconMessageSettings.isMessage,
    },
    waiverSetting: {
      isWaiver: manageChatbotSettingAll.waiverSetting.isWaiver,
      waiverText: manageChatbotSettingAll.waiverSetting.waiverText,
    },
    emailSetting: {
      isSendEmail: manageChatbotSettingAll.emailSetting.isSendEmail,
      toEmails: manageChatbotSettingAll.emailSetting.toEmails,
      bccEmails: manageChatbotSettingAll.emailSetting.bccEmails,
    },
    escalateEmailSetting: {
      isEscalateEmail:
        manageChatbotSettingAll.escalateEmailSetting.isEscalateEmail,
      toEmails: manageChatbotSettingAll.escalateEmailSetting.toEmails,
    },
    chatWithHumanSetting: {
      isChatLive: manageChatbotSettingAll.chatWithHumanSetting.isChatLive,
      redirectLink: manageChatbotSettingAll.chatWithHumanSetting.redirectLink,
    },
    seoMetaSetting: {
      seoMetaSettings: manageChatbotSettingAll.seoMetaSetting.seoMetaSettings,
      socialMediaTitle: manageChatbotSettingAll.seoMetaSetting.socialMediaTitle,
      socialMediaDescription:
        manageChatbotSettingAll.seoMetaSetting.socialMediaDescription,
      socialMediaCustomImage:
        manageChatbotSettingAll.seoMetaSetting.socialMediaCustomImage,
    },
    integrationApp: {
      isHubspot: manageChatbotSettingAll.integrationApp.isHubspot,
      isZendesk: manageChatbotSettingAll.integrationApp.isZendesk,
      isShopify: manageChatbotSettingAll.integrationApp.isShopify,
      isDiscord: manageChatbotSettingAll.integrationApp.isDiscord,
      discordBotToken: manageChatbotSettingAll.integrationApp.discordBotToken,
    },
    apiConfiguration: {
      isApiConfiguration:
        manageChatbotSettingAll.apiConfiguration.isApiConfiguration,
      methodType: manageChatbotSettingAll.apiConfiguration.methodType,
      url: manageChatbotSettingAll.apiConfiguration.url,
      header: manageChatbotSettingAll.apiConfiguration.header,
      body: manageChatbotSettingAll.apiConfiguration.body,
      isAfternoon: manageChatbotSettingAll.apiConfiguration.isAfternoon,
      isMidNight: manageChatbotSettingAll.apiConfiguration.isMidNight,
    },
  });

  useEffect(() => {
    setAdditionalInitialValues({
      bookId: selectedBook && selectedBook._id,
      bookSetting: {
        model:
          bookSettings && bookSettings.model ? bookSettings.model : "gpt-4o",
        customAnswer:
          bookSettings && bookSettings.customAnswer
            ? bookSettings.customAnswer
            : CUSTOM_ERROR_RESP,
        title: selectedBook && selectedBook.title ? selectedBook.title : "",
        qaTemplate:
          bookSettings &&
            bookSettings.qaTemplate &&
            bookSettings.qaTemplate.length > 0
            ? bookSettings.qaTemplate
            : QA_PROMPT,
        temperature:
          bookSettings && bookSettings.temperature
            ? bookSettings.temperature
            : 0.5,
        topP: bookSettings && bookSettings.topP ? bookSettings.topP : 0.5,
        allowMemory: bookSettings && bookSettings.allowMemory,
        isSummarized:
          bookSettings && bookSettings.isSummarized
            ? bookSettings.isSummarized
            : false,
        chatbotHeaderTitle:
          selectedBook && selectedBook.title
            ? selectedBook.title
            : "Welcome to Dropchat",
        footerBrandingText:
          bookSettings && bookSettings.footerBrandingText
            ? bookSettings.footerBrandingText
            : "Powered By Dropchat",
        footerBrandingLink:
          bookSettings && bookSettings.footerBrandingLink
            ? bookSettings.footerBrandingLink
            : "https://dropchat.co/",
      },
      isFeatureChatbot:
        manageChatbotSettingAll && manageChatbotSettingAll.isFeatureChatbot,
      canTeamMembersView:
        manageChatbotSettingAll && manageChatbotSettingAll.canTeamMembersView,
      chatInterfaceType: manageChatbotSettingAll.interfaceSetting.interfaceType,
      greetingSetting: {
        message: manageChatbotSettingAll.greetingSetting.message,
        isGreeting: manageChatbotSettingAll.greetingSetting.isGreeting,
      },
      bubbleIconMessageSettings: {
        message: manageChatbotSettingAll.bubbleIconMessageSettings.message,
        isMessage: manageChatbotSettingAll.bubbleIconMessageSettings.isMessage,
      },
      waiverSetting: {
        isWaiver: manageChatbotSettingAll.waiverSetting.isWaiver,
        waiverText: manageChatbotSettingAll.waiverSetting.waiverText,
      },
      emailSetting: {
        isSendEmail: manageChatbotSettingAll.emailSetting.isSendEmail,
        toEmails: manageChatbotSettingAll.emailSetting.toEmails,
        bccEmails: manageChatbotSettingAll.emailSetting.bccEmails,
      },
      escalateEmailSetting: {
        isEscalateEmail:
          manageChatbotSettingAll.escalateEmailSetting.isEscalateEmail,
        toEmails: manageChatbotSettingAll.escalateEmailSetting.toEmails,
      },
      chatWithHumanSetting: {
        isChatLive: manageChatbotSettingAll.chatWithHumanSetting.isChatLive,
        redirectLink: manageChatbotSettingAll.chatWithHumanSetting.redirectLink,
      },
      seoMetaSetting: {
        seoMetaSettings: manageChatbotSettingAll.seoMetaSetting.seoMetaSettings,
        socialMediaTitle:
          manageChatbotSettingAll.seoMetaSetting.socialMediaTitle,
        socialMediaDescription:
          manageChatbotSettingAll.seoMetaSetting.socialMediaDescription,
        socialMediaCustomImage:
          manageChatbotSettingAll.seoMetaSetting.socialMediaCustomImage,
      },
      integrationApp: {
        isHubspot: manageChatbotSettingAll.integrationApp.isHubspot,
        isZendesk: manageChatbotSettingAll.integrationApp.isZendesk,
        isShopify: manageChatbotSettingAll.integrationApp.isShopify,
        isDiscord: manageChatbotSettingAll.integrationApp.isDiscord,
        discordBotToken: manageChatbotSettingAll.integrationApp.discordBotToken,
      },
      apiConfiguration: {
        isApiConfiguration:
          manageChatbotSettingAll.apiConfiguration.isApiConfiguration,
        methodType: manageChatbotSettingAll.apiConfiguration.methodType,
        url: manageChatbotSettingAll.apiConfiguration.url,
        header: manageChatbotSettingAll.apiConfiguration.header,
        body: manageChatbotSettingAll.apiConfiguration.body,
        isAfternoon: manageChatbotSettingAll.apiConfiguration.isAfternoon,
        isMidNight: manageChatbotSettingAll.apiConfiguration.isMidNight,
      },
    });
    // eslint-disable-next-line
  }, [selectedBook, manageChatbotSettingAll, setManageChatbotSettingAll]);

  const removeTeam = () => {
    if (bookId && teamToDelete) {
      myAxios.post(`${process.env.REACT_APP_SERVER_URL}/books/change-book-teams`, {
        bookId: bookId ? bookId : selectedBook._id,
        team: teamToDelete,
        operation: 'remove',
      }).then((response) => {
        console.log('response.data.message >>>>>>>>>', response.data.message);
        setBookTeams(response.data.data);
        setNewTeamArray(response.data.data);
        setShowUtilityConfirmationBox(false);
        setTeamToDelete(undefined);
        NotificationManager.success('Chatbot removed from the team');
      })
        .catch((error) => {

        });

      myAxios
        .get(process.env.REACT_APP_SERVER_URL + "/books/book/" + bookId)
        .then((response) => {
          if (response && response.data) {
            setSelectedBook(response.data.data);
          }
        })
        .catch((error) => {

        });
    }
  }

  const addTeam = () => {
    if (bookId && newTeamArray) {
      myAxios.post(`${process.env.REACT_APP_SERVER_URL}/books/change-book-teams`, {
        bookId: bookId ? bookId : selectedBook._id,
        teams: newTeamArray,
        operation: 'add',
      })
        .then((response) => {
          console.log('response.data.message >>>>>>>>>', response.data.message);
          setBookTeams(response.data.data);
          setNewTeamArray(response.data.data);
          setShowUtilityConfirmationBox(false);
          setShowCopyChatbotPopup(false);
          NotificationManager.success('Chatbot copied to the teams')
        })
        .catch((error) => {

        });

      myAxios
        .get(process.env.REACT_APP_SERVER_URL + "/books/book/" + bookId)
        .then((response) => {
          if (response && response.data) {
            setSelectedBook(response.data.data);
          }
        })
        .catch((error) => {

        });

    }
  }


  return (
    <ParentSection>
      <Formik
        initialValues={additionalInitialValues}
        validate={validateChatbotSettings}
        enableReinitialize
        onSubmit={(values) => {
          setShowLoader(true);
          let toEmailsData: any = [];
          values.emailSetting.toEmails.map((email: any) => {
            return toEmailsData.push(email.text);
          });
          let bccEmailsData: any = [];
          values.emailSetting.bccEmails.map((email: any) => {
            return bccEmailsData.push(email.text);
          });

          let toEscalateEmailsData: any = [];
          values.escalateEmailSetting.toEmails.map((email: any) => {
            return toEscalateEmailsData.push(email.text);
          });

          let dataToSave = {
            ...values,
            emailSetting: {
              ...values.emailSetting,
              toEmails: toEmailsData,
              bccEmails: bccEmailsData,
            },
            escalateEmailSetting: {
              ...values.escalateEmailSetting,
              toEmails: toEscalateEmailsData,
            },
          };
          let createSocialDetails;
          if (file) {
            const newData = new FormData();
            newData.append("files.image", file);
            newData.append("data", JSON.stringify(dataToSave));
            createSocialDetails = newData;
          } else {
            createSocialDetails = dataToSave;
          }

          myAxios
            .post(
              process.env.REACT_APP_SERVER_URL + "/chatbot/manage-setting",
              createSocialDetails
            )
            .then((response) => {
              if (response.data && response.data.success) {
                setShowLoader(false);
                setBookSettings(response.data.data.saveBookSettings);
                NotificationManager.success(
                  response.data.message
                    ? response.data.message
                    : "Settings saved successfully."
                );
                myAxios
                  .get(
                    `${process.env.REACT_APP_SERVER_URL}/book-settings/get-book-settings/${selectedBook._id}`
                  )
                  .then((res) => {
                    if (res.data.data) {
                      setBookSettings(res.data.data);
                    }
                  });
              } else {
                setShowLoader(false);
                NotificationManager.error(
                  response.data.message
                    ? response.data.message
                    : "Unable to process your request, please try later"
                );
              }
            })
            .catch((error) => {
              // handle error
              setShowLoader(false);
              NotificationManager.error(parseErrorResponse(error));
            });
          const updatedSettings = checkSettingsChanges(
            additionalInitialValues,
            values
          );
          if (Object.keys(updatedSettings).length > 0) {
            for (const settingKey in updatedSettings) {
              if (updatedSettings.hasOwnProperty(settingKey)) {
                trackEvent({
                  email: getUsersEmail(user),
                  eventCategory: EventCategory.CHATBOT,
                  event:
                    settingKey === "title"
                      ? ChatBotEvents.USER_UPDATED_CUSTOM_TITLE_FROM_MANAGE_CHATBOT
                      : settingKey === "model"
                        ? ChatBotEvents.USER_UPDATED_CHAT_MODEL_FROM_MANAGE_CHATBOT
                        : settingKey === "temperature"
                          ? ChatBotEvents.USER_UPDATED_TEMPERATURE_FROM_MANAGE_CHATBOT
                          : settingKey === "topP"
                            ? ChatBotEvents.USER_UPDATED_TOP_P_FROM_MANAGE_CHATBOT
                            : settingKey === "allowMemory"
                              ? ChatBotEvents.USER_UPDATED_ALLOW_CHATBOT_MEMORY_FROM_MANAGE_CHATBOT
                              : settingKey === "qaTemplate"
                                ? ChatBotEvents.USER_UPDATED_CUSTOM_PROMPT_FROM_MANAGE_CHATBOT
                                : settingKey === "customAnswer"
                                  ? ChatBotEvents.USER_UPDATED_CUSTOM_ERROR_RESPONSE_FROM_MANAGE_CHATBOT
                                  : settingKey === "isSummarized"
                                    ? ChatBotEvents.USER_UPDATED_MANAGE_ANSWER_LENGTH_SETTINGS_FROM_MANAGE_CHATBOT
                                    : settingKey === "seoMetaSetting"
                                      ? ChatBotEvents.USER_UPDATED_SOCIAL_MEDIA_SETTINGS_FROM_MANAGE_CHATBOT
                                      : settingKey === "chatInterfaceType"
                                        ? ChatBotEvents.USER_UPDATED_CHATBOT_INTERFACE_SETTINGS_FROM_MANAGE_CHATBOT
                                        : settingKey === "greetingSetting"
                                          ? ChatBotEvents.USER_UPDATED_GREETING_MESSAGE_SETTINGS_FROM_MANAGE_CHATBOT
                                          : settingKey === "waiverSetting"
                                            ? ChatBotEvents.USER_UPDATED_CHATBOT_WAVIER_SETTINGS_FROM_MANAGE_CHATBOT
                                            : settingKey === "isFeatureChatbot"
                                              ? ChatBotEvents.USER_UPDATED_MARK_AS_FEATURE_CHATBOT_FROM_MANAGE_CHATBOT
                                              : settingKey === "emailSetting"
                                                ? ChatBotEvents.USER_UPDATED_EMAIL_SETTINGS_FROM_MANAGE_CHATBOT
                                                : settingKey === "escalateEmailSetting"
                                                  ? ChatBotEvents.USER_UPDATED_ESCALATE_TO_EMAIL_SETTINGS_FROM_MANAGE_CHATBOT
                                                  : settingKey === "chatWithHumanSetting"
                                                    ? ChatBotEvents.USER_UPDATED_CHAT_WITH_HUMAN_LIVE_SETTINGS_FROM_MANAGE_CHATBOT
                                                    : "",
                  subCategory: SubCategory.EVENT,
                  chatbotType: selectedBook.fileType,
                  bookId: selectedBook._id,
                  rawData: updatedSettings[settingKey],
                });
              }
            }
          }
        }}
      >
        {() => (
          <Form className={MANAGE_CHATBOT}>
            <Accordion
              style={{ display: "block", marginBottom: "5rem" }}
              defaultActiveKey="0"
            >
              <Accordion.Item eventKey="0" className="acc-item">
                <Accordion.Header>Chatbot Settings</Accordion.Header>
                <Accordion.Body>
                  <InnerSection>
                    <ChatbotSetting
                      bookSettings={bookSettings}
                      selectedBook={selectedBook}
                      tempretureValue={tempretureValue}
                      setTempretureValue={setTempretureValue}
                      topPValue={topPValue}
                      setTopPValue={setTopPValue}
                    />
                  </InnerSection>
                </Accordion.Body>
              </Accordion.Item>
              {selectedBook?.type === "google" ||
                selectedBook?.chatType === "chatgpt" ||
                !user?.currentSubscription?.meta.APIConfiguration ? null : (
                <Accordion.Item eventKey="1" className="acc-item">
                  <Accordion.Header>Chatbot API Configuration</Accordion.Header>
                  <Accordion.Body>
                    <>
                      <InnerSection>
                        <ExternalIndexingApi />
                      </InnerSection>
                    </>
                  </Accordion.Body>
                </Accordion.Item>
              )}
              <Accordion.Item eventKey="2" className="acc-item">
                <Accordion.Header>Copy Chatbot</Accordion.Header>
                <Accordion.Body>
                  <InnerSection>
                    <Row style={{ alignItems: "center" }}>
                      <Col sm={8}>
                        <Row style={{ alignItems: "center" }}>
                          <MenuHeading>Copy Chatbot</MenuHeading>
                          <SubHeading>
                            You can copy this chatbot to the multiple teams, but ensure that you are the team owner of the destination team.
                          </SubHeading>
                        </Row>
                      </Col>
                      <Col sm={4}>
                        <BlueButton
                          type="button"
                          onClick={() => {
                            setShowCopyChatbotPopup(true)
                            setIsBackgroundBlur(true)
                          }
                          }
                        >
                          Copy Chatbot
                        </BlueButton>
                      </Col>
                    </Row>
                    <Row>
                      <SubHeadingCopyChatbot>
                        <b>
                          Currently this chatbot is available in below teams:
                        </b>
                      </SubHeadingCopyChatbot>
                      <ul>
                        {bookTeams?.map((t: any) => (
                          <>
                            {user?.id === t.createdBy &&
                              <Row>
                                <Col style={{ justifyContent: 'space-between' }}>
                                  <SubHeading style={{ marginBottom: '3px' }}>{t.name}</SubHeading>

                                  {bookTeams?.length > 1 &&

                                    <button type="button"
                                      style={{ border: 'none', width: 'auto', height: '15px', background: 'none' }}
                                      onClick={() => {
                                        removeTeamConfirmationBox(t.name);
                                        setTeamToDelete(t);
                                      }}
                                    >
                                      <SubHeading style={{ color: 'red' }}>
                                        Remove
                                      </SubHeading>
                                    </button>
                                  }
                                </Col>
                                <hr></hr>
                              </Row>
                            }
                          </>
                        ))}
                      </ul>
                    </Row>
                  </InnerSection>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="acc-item">
                <Accordion.Header>Chatbot ID</Accordion.Header>
                <Accordion.Body>
                  <InnerSection>
                    <Row
                      style={{ alignItems: "center", marginBottom: ".5rem" }}
                    >
                      <Col sm={8}>
                        <Row
                          style={{
                            alignItems: "center",
                            marginBottom: ".5rem",
                          }}
                        >
                          <MenuHeading>Chatbot ID</MenuHeading>
                          <SubHeading>
                            Copy the chatbot id for the external usage. (ex.
                            wordpress dropchat plugin etc.)
                          </SubHeading>
                        </Row>
                      </Col>
                      <Col sm={4}>
                        <BlueButton
                          type="button"
                          onClick={() => {
                            navigator.clipboard.writeText(selectedBook._id);
                            NotificationManager.success("Chatbot ID copied.");
                          }}
                        >
                          Copy ID
                        </BlueButton>
                      </Col>
                    </Row>
                  </InnerSection>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="acc-item">
                <Accordion.Header>Share Chatbot</Accordion.Header>
                <Accordion.Body>
                  <InnerSection>
                    <SidebarHeading>Share Chatbot</SidebarHeading>
                    {/* <OverlayPanelStyle ref={op}> */}
                    <Row style={{ alignItems: "center", marginBottom: "1rem" }}>
                      <Col sm={8} style={{ flexDirection: "column" }}>
                        <MenuHeading>Embed on website</MenuHeading>
                        <SubHeading>
                          Integrate a chatbot into a website using one of two
                          distinct methods. The first method involves
                          implementing a chat bubble, which will appear in the
                          lower right corner of the website. The second method
                          requires using iframe code, enabling the chatbot to be
                          directly displayed within the webpage itself.
                        </SubHeading>
                      </Col>
                      <Col sm={4}>
                        <BlueButton
                          type="button"
                          onClick={() => {
                            setBookId(selectedBook._id);
                            setShowConfirmationBox(true);
                            setShowIframeCodeBox(true);
                          }}
                        >
                          Embed
                        </BlueButton>
                      </Col>
                    </Row>
                    <Row
                      style={{ alignItems: "center", marginBottom: ".5rem" }}
                    >
                      <Col sm={8} style={{ flexDirection: "column" }}>
                        <MenuHeading>Shared URL</MenuHeading>
                        <SubHeading>
                          Create a shareable public URL for your chatbot,
                          allowing others to access it through the link.
                        </SubHeading>
                      </Col>
                      <Col sm={4}>
                        <CopyToClipboard
                          text={`${process.env.REACT_APP_SERVER_URL
                            }/public/shared-chat/${selectedBook && selectedBook._id
                            }`}
                          onCopy={(e) => {
                            NotificationManager.success("Chatbot URL copied.");
                            if (!isTestUser(user?.email))
                              _cio.track(USER_CREATED_A_SHARE_URL_CHATBOT, {
                                name: "user-created-a-share-url-chatbot",
                              });
                            trackEvent({
                              email: getUsersEmail(user),
                              eventCategory: EventCategory.CHATBOT,
                              event:
                                ChatBotEvents.USER_COPIED_SHARE_URL_FROM_MANAGE_CHATBOT,
                              subCategory: SubCategory.EVENT,
                              chatbotType: selectedBook.fileType,
                              bookId: selectedBook._id,
                              description: "Chatbot URL copied.",
                            });
                            try {
                              // op.current.toggle(e);
                            } catch (error) { }
                          }}
                        >
                          <BlueButton type="button">Copy URL</BlueButton>
                        </CopyToClipboard>
                      </Col>
                    </Row>
                    <InnerSection>
                      <Row>
                        <SummarizedAnswer />
                      </Row>
                    </InnerSection>
                    <InnerSection>
                      <Row>
                        <SocialMediaSettings
                          setSelectedFile={setSelectedFile}
                          setIsSmallImage={setIsSmallImage}
                        />
                      </Row>
                    </InnerSection>
                  </InnerSection>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5" className="acc-item">
                <Accordion.Header>Chatbot Interface</Accordion.Header>
                <Accordion.Body>
                  <InnerSection>
                    <Row>
                      <ChatInterfaceSettings />
                    </Row>
                  </InnerSection>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6" className="acc-item">
                <Accordion.Header>Bubble Chat Message</Accordion.Header>
                <Accordion.Body>
                  <InnerSection>
                    <BubbleCustomGreetingText />
                  </InnerSection>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7" className="acc-item">
                <Accordion.Header>Greeting Message</Accordion.Header>
                <Accordion.Body>
                  <InnerSection>
                    <CustomGreetingText />
                  </InnerSection>
                </Accordion.Body>
              </Accordion.Item>

              {/* <Accordion.Item eventKey="6" className="acc-item">
                <Accordion.Header>Shopify Products</Accordion.Header>
                <Accordion.Body>
                  <InnerSection>
                    <Row>
                      <GetShopifyProducts />
                    </Row>
                  </InnerSection>
                </Accordion.Body>
              </Accordion.Item> */}

              <Accordion.Item eventKey="8" className="acc-item">
                <Accordion.Header>Chatbot Waiver</Accordion.Header>
                <Accordion.Body>
                  <InnerSection>
                    <ChatBotWaiverSetting />
                  </InnerSection>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9" className="acc-item">
                <Accordion.Header>Chatbot Header Title</Accordion.Header>
                <Accordion.Body>
                  <InnerSection>
                    <ChatbotHeaderTitle />
                  </InnerSection>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10" className="acc-item">
                <Accordion.Header>Footer Branding</Accordion.Header>
                <Accordion.Body>
                  <ParentInnerSection>
                    {user?.currentSubscription &&
                      !user?.currentSubscription.meta
                        ?.removeDropChatBranding && (
                        <OverlayRestriction>
                          <UpgradeContent
                            onClick={(e) => {
                              setPlanHighliter({
                                isBookHighliter: false,
                                bookLimit: 0,
                                isDeleteBookHighliter: false,
                                isFlashcardHighliter: false,
                                isCharacterLimit: false,
                                pdfLimit: 0,
                                isDeleteAccount: false,
                                isLeadLoginSettings: false,
                                isRemoveDropChatBrandingHighliter: true,
                              });
                              navigate("/subscription");
                            }}
                          >
                            <img
                              src={LockIcon}
                              width={20}
                              alt="lock-icon"
                              style={{ marginRight: "5px" }}
                            />{" "}
                            Upgrade Required
                          </UpgradeContent>
                        </OverlayRestriction>
                      )}
                    <InnerSection>
                      <FooterBrandingSection />
                    </InnerSection>
                  </ParentInnerSection>
                </Accordion.Body>
              </Accordion.Item>
              {user && user.role === "super_admin" && (
                <Accordion.Item eventKey="11" className="acc-item">
                  <Accordion.Header>Feature Chatbot Setting</Accordion.Header>
                  <Accordion.Body>
                    <div className="d-block">
                      <InnerSection>
                        <MarkChatbotAsFeatureChatbot />
                      </InnerSection>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}
              <Accordion.Item eventKey="12" className="acc-item">
                <Accordion.Header>Email Settings</Accordion.Header>
                <Accordion.Body>
                  <InnerSection>
                    <ChatbotEmailSettings />
                  </InnerSection>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="13" className="acc-item">
                <Accordion.Header>Escalate Email</Accordion.Header>
                <Accordion.Body>
                  <InnerSection>
                    <Row>
                      <EscalateEmail />
                    </Row>
                  </InnerSection>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="14" className="acc-item">
                <Accordion.Header>Integration Apps</Accordion.Header>
                <Accordion.Body>
                  <InnerSection>
                    <Row>
                      <IntegrationAppsSetting />
                    </Row>
                  </InnerSection>
                </Accordion.Body>
              </Accordion.Item>
              {integrationAppList.discord && (
                <Accordion.Item eventKey="15" className="acc-item">
                  <Accordion.Header>Discord</Accordion.Header>
                  <Accordion.Body>
                    <InnerSection>
                      <DiscordIntegrationAppsSetting
                        additionalInitialValues={additionalInitialValues}
                        isTokenVerified={isTokenVerified}
                        setIsTokenVerified={setIsTokenVerified}
                      />
                    </InnerSection>
                  </Accordion.Body>
                </Accordion.Item>
              )}

              <Accordion.Item eventKey="16" className="acc-item">
                <Accordion.Header>Chat with a human/live chat</Accordion.Header>
                <Accordion.Body>
                  <InnerSection>
                    <Row>
                      <ChatLive />
                    </Row>
                  </InnerSection>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="17" className="acc-item">
                <Accordion.Header>Delete Chatbot</Accordion.Header>
                <Accordion.Body>
                  <InnerSection>
                    <SidebarHeading>Delete chatbot</SidebarHeading>
                    <SubHeading>
                      Do you really want to delete chatbot. It includes all
                      coversations and all data sources deleted permanently.
                    </SubHeading>
                    {user?.currentSubscription ? (
                      user?.currentSubscription.meta?.deleteChat ? (
                        <RedButton
                          type="button"
                          onClick={(e) => {
                            // op.current.toggle(e);
                            deleteConfirmationBox();
                          }}
                        >
                          Delete Chatbot
                        </RedButton>
                      ) : (
                        <Col>
                          <RedButton
                            type="button"
                            style={{ opacity: 0.5, cursor: "not-allowed" }}
                          >
                            Delete Chatbot
                          </RedButton>
                          <div style={{ paddingLeft: "2rem" }}>
                            <UpgradeSpan
                              onClick={(e) => {
                                setPlanHighliter({
                                  isBookHighliter: false,
                                  bookLimit: 0,
                                  isDeleteBookHighliter: true,
                                  isFlashcardHighliter: false,
                                  isCharacterLimit: false,
                                  pdfLimit: 0,
                                });
                                navigate("/subscription");
                              }}
                            >
                              Upgrade Plan
                            </UpgradeSpan>
                            {/* <PlanSpan>Your plan</PlanSpan> */}
                          </div>
                        </Col>
                      )
                    ) : (
                      <>
                        <RedButton
                          type="button"
                          style={{ opacity: 0.5, cursor: "not-allowed" }}
                        >
                          Delete Chatbot
                        </RedButton>
                        <div style={{ paddingLeft: "2rem" }}>
                          <UpgradeSpan
                            onClick={(e) => {
                              setPlanHighliter({
                                isBookHighliter: false,
                                bookLimit: 0,
                                isDeleteBookHighliter: true,
                                isFlashcardHighliter: false,
                                isCharacterLimit: false,
                                pdfLimit: 0,
                              });
                              navigate("/subscription");
                            }}
                          >
                            Purchase Plan
                          </UpgradeSpan>
                          {/* <PlanSpan>Your plan</PlanSpan> */}
                        </div>
                      </>
                    )}
                  </InnerSection>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Row
              style={{
                padding: "0.5rem",
                background: "white",
                alignItems: "center",
                justifyContent: "center",
                position: "fixed",
                bottom: 0,
                width: "100%",
              }}
            >
              <SaveButton type="submit">SAVE</SaveButton>
            </Row>
          </Form>
        )}
      </Formik>
      {
        showUtilityConfirmationBox && deleteItem === 'chatbot' && (
          <ConfirmationBox
            setShow={setShowUtilityConfirmationBox}
            name="Confirmation"
            message={confirmationBoxMessage}
            okButtonText="Yes"
            messageDesc={`All conversations and all data sources will be permanently deleted.`}
            action={confirmationAction}
            okButtonClick={confirmClick}
          />
        )
      }
      {
        showUtilityConfirmationBox && deleteItem === 'team' && (
          <ConfirmationBox
            setShow={setShowUtilityConfirmationBox}
            name="Confirmation"
            message={confirmationBoxMessage}
            okButtonText="Yes"
            action={confirmationAction}
            okButtonClick={removeTeam}
          />
        )
      }
      <CodeBox />
      {
        showCopyChatbotPopup &&
        <CopyChatbotPopupMobile
          setShowCopyChatbotPopup={setShowCopyChatbotPopup}
          updateTeams={addTeam}
          setNewTeamArray={setNewTeamArray}
          newTeamArray={newTeamArray}
        />
      }
    </ParentSection>
  );
};

export default MobileManageChatbot;
