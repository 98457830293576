import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MOBILE } from "../../constants/mobile";
import { Field, Formik } from "formik";
import Delete from "../../images/trash.svg";
import BluePlusIcon from "../../images/blue-plus-icon.svg";
import ConfirmationBox from "../utility-component/confirmation-box";
import { useContext, useEffect, useState } from "react";
import { isValidEmail } from "../../utils/utils";
import myAxios from "../../myaxios";
import { Member, Team } from "../../types";
import { LoaderContext } from "../../context/loader-context";
import { NotificationManager } from "react-notifications";
import { UserContext } from "../../context/UserContext";
import { TeamContext } from "../../context/team-context";
import TeamSettingsNavbar from "./team-settings-navbar";
import { MEDIUM } from "../../constants/desktop";

const MainRow = styled(Row)`
  flex-direction: column;
  background-color: #fff;
  background-color: red;
  padding: 10px;
  border-radius: 5px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    height: 100%;
    padding: 5px 0px 0px 5px;
  }
`;

const FirstRow = styled(Row)`
  gap: 5px 0px;
`;

const ColumnBox = styled.div`
//   padding: 10px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  flex-direction: column;
  font-size: 14px;
  width: 100%;
`;

const FormMainDiv = styled.div`
  margin-bottom: 20px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-bottom: 0px;
  }
`;

const LabelHeading = styled.label`
margin-top: 3%;
  width: 100%;
  color: #000;
  font-size: 22px;
  font-weight: bold;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 15px;
  }
@media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 20px;
  }
`;

const InputField = styled(Field)`
  border: 1px solid #eaeaea;
  width: 30rem;;
  height: 3rem;
  margin-bottom: 4rem;
  font-size: 18px;
  background: #eaeaea;
  border-radius: 4px;
  padding: 10px 28px 10px 20px;
  position: relative;
  &::placeholder {
    padding-left: 10px;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
    padding: 5px;
    height: 2rem;
    width: 20rem;
    &::placeholder {
      padding-left: 5px;
    }
  }
`;

const InputFieldNormal = styled.input`
  border: 1px solid #eaeaea;
  width: 100%;
  height: 40px;
  background: #eaeaea;
  border-radius: 4px;
  padding: 10px 28px 10px 10px;
  position: relative;
  &::placeholder {
    padding-left: 10px;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
    padding: 5px;
    height: 30px;
    &::placeholder {
      padding-left: 5px;
    }
  }
`;

const SubmitBtn = styled.button`
margin: 2rem;
font-size: 20px;
background: #1585b5;
padding: 0.5rem 0.1rem;
color: #fff;
border: 0;
width: 30rem;
height: 4rem;
align-items: center;
justify-content: center;
border-radius: 4px;
cursor: pointer;
&:hover {
background: #0b5f82;
}
&:disabled {
opacity: 0.5;
cursor: not-allowed !important;
}
@media (max-width: ${MOBILE.maxWidth}px) {
font-size: 12px;
padding: 5px;
height: 2.5rem;
width: 20rem;
margin: 0.5rem;

}
@media (min-width: ${MEDIUM.minWidth}px) {
height: 3rem;
width: 30rem;
font-size: 18px;
}
`;

const NormalFieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: row;
  margin-left: 1rem;
`;

const SetExtraUrlButton = styled.button`
  background: #1585b5;
  color: #fff;
  border: none;
  width: max-content;
  
`;

const NormalInput = styled.input`
  width: 100%;
  padding: 1rem;
  font-size: 20px;
  @media (max-width: ${MOBILE.maxWidth}px) {
font-size: 12px;
padding: 5px;
height: 2.5rem;
width: 20rem;
}
`;

const SingleMember = styled.div`
  border-bottom: 1px solid #eaeaea;
  padding: 0.8rem 2rem;
  font-size: 18px;
  & span {
    background: #eb922c;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
  }
  & select {
    outline: none;
    border: none;
    cursor: pointer;
  }
  & img {
    color: #ea5455;
    cursor: pointer;
    width: 24px;
  }
    @media (max-width: ${MOBILE.maxWidth}px){
  font-size: 12px;
  padding: 0.8rem;
  & img {
    
    width: 18px;
  }
    
    }
`;

const InvitationPending = styled.span`
  opacity: 0.5;
  cursor: not-allowed;
`;
const Invite = styled.span``;
const SpanError = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #df5c04;
  padding: 3px;
  display: flex;
`;

const TeamBox = styled.div`
  padding: 1rem;
  flex-direction: column;
  border: 1px solid #eaeaea;
  margin: 0.5rem;
`;

const MainPopupDiv = styled.div`
flex-direction: column;
position: absolute;
top: 45px;
right: 0px;
bottom: 0;
width: 100%;
z-index: 12; 
background-color: white;
@media (min-width: ${MEDIUM.minWidth}px){
width: 45%;
top: 49px;
/* left: 54.5%; */
}
`

const ContainerRow = styled(Row)`
text-align: center;
align-items: center;
width: 100%;
`

const ContainerCol = styled(Col)`

`

const TextPara = styled.p`
font-size: 22px;
@media (max-width: ${MOBILE.maxWidth}px) {
font-size: 15px;
}
@media (min-width: ${MEDIUM.minWidth}px) {
font-size: 20px;
}
`


const TeamSettingsSidebarPopup = ({
    user,
    selectedTeam,
    membersToAdd,
    createTeam,
    updateTeam,
    isUserCanChange,
    searchUserByEmail,
    setEmailAddress,
    emailAddress,
    inviteMember,
    setMembersToAdd,
    deleteMember,
    currentMemberRole,
    setShowTeamSettingsPopup,
    isCreateNewTeam,
}: any) => {

    return (
        <>
            {/* <MainPopupDiv> */}
            <TeamSettingsNavbar setShowTeamSettingsPopup={setShowTeamSettingsPopup} isCreateNewTeam={isCreateNewTeam} />
            <Formik
                initialValues={{
                    id: !isCreateNewTeam ? selectedTeam && selectedTeam._id : undefined,
                    name: !isCreateNewTeam ? selectedTeam && selectedTeam.name : '',
                    members: !isCreateNewTeam ? selectedTeam && selectedTeam.members : [],
                }}
                validate={(values: any) => {
                    const errors: any = {};

                    if (!values.name) {
                        errors.name = "Name is required!";
                    }

                    return errors;
                }}
                onSubmit={(values) => {
                    if (!values.id) {
                        const datatosave = {
                            name: values.name,
                            description: "This is the team description",
                            type: "group",
                            members: membersToAdd,
                        };
                        createTeam(datatosave);
                    } else {
                        const datatoupdate = {
                            id: values.id,
                            name: values.name,
                            description: "This is the team description",
                            type: "group",
                            members: membersToAdd,
                        };
                        updateTeam(datatoupdate);
                    }
                    setShowTeamSettingsPopup(false);
                }}
                enableReinitialize
            >
                {({ errors, touched, handleChange, handleSubmit, values }: any) => (
                    <form onSubmit={handleSubmit}>
                        <ContainerRow>
                            <Col style={{ flexDirection: "column" }}>
                                <FormMainDiv className=" d-block">
                                    <InputField
                                        type="text"
                                        placeholder="team-id"
                                        className=""
                                        name="id"
                                        hidden
                                        disabled={!isUserCanChange}
                                    />
                                    <LabelHeading>Team Name</LabelHeading>
                                    <InputField
                                        type="text"
                                        placeholder="Enter team name"
                                        className=""
                                        name="name"
                                        onChange={handleChange}
                                        onKeyDown={(e: any) => {
                                            if (e.key === "Enter") {
                                                e.preventDefault();
                                            }
                                        }}
                                        disabled={!isUserCanChange}
                                        style={{
                                            borderColor: errors.name ? "#df5c04" : "#eaeaea",
                                        }}
                                    />
                                    {errors.name && touched.name && (
                                        <SpanError>{errors.name}</SpanError>
                                    )}
                                </FormMainDiv>
                                <TextPara>
                                    <b>{isUserCanChange ? "Add Team Members" : "Team Members"}</b>
                                </TextPara>
                                {isUserCanChange && (
                                    <NormalFieldSection>
                                        <NormalInput
                                            name="emailAddress"
                                            value={emailAddress}
                                            placeholder="Search user by email address"
                                            onChange={(e: any) => setEmailAddress(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    e.preventDefault();
                                                    if (emailAddress && isValidEmail(emailAddress)) {
                                                        searchUserByEmail();
                                                        setEmailAddress("");
                                                    }
                                                }
                                            }}
                                        />
                                        <SetExtraUrlButton
                                            type="button"
                                            onClick={() => {
                                                if (emailAddress && isValidEmail(emailAddress)) {
                                                    searchUserByEmail();
                                                    setEmailAddress("");
                                                }
                                            }}
                                        >
                                            <img src={BluePlusIcon} alt="add" />
                                        </SetExtraUrlButton>
                                    </NormalFieldSection>
                                )}
                                {membersToAdd &&
                                    membersToAdd.length > 0 &&
                                    membersToAdd.map((member: Member) => {
                                        let isOwner = member.role === "owner";
                                        return (
                                            <SingleMember>
                                                <Col sm={6}>{member.email}</Col>

                                                <Col sm={2}>
                                                    {member.id && (
                                                        <>
                                                            {member.status ===
                                                                "active" ? null : member.status === "invited" ? (
                                                                    <InvitationPending>
                                                                        Invitation sent
                                                                    </InvitationPending>
                                                                ) : (
                                                                <Invite
                                                                    style={{
                                                                        opacity: isUserCanChange ? "1" : "0.5",
                                                                        cursor: isUserCanChange
                                                                            ? "pointer"
                                                                            : "not-allowed",
                                                                    }}
                                                                    onClick={() => {
                                                                        inviteMember(member);
                                                                    }}
                                                                >
                                                                    Invite
                                                                </Invite>
                                                            )}
                                                        </>
                                                    )}
                                                </Col>

                                                <Col sm={3}>
                                                    {!isOwner ? (
                                                        <>
                                                            {currentMemberRole === "admin" &&
                                                                currentMemberRole === member.role ? (
                                                                <>
                                                                    <p
                                                                        style={{
                                                                            margin: 0,
                                                                            padding: "0rem 0.2rem",
                                                                        }}
                                                                    >
                                                                        Team Admin
                                                                    </p>
                                                                </>
                                                            ) : (
                                                                <select
                                                                    disabled={!isUserCanChange}
                                                                    onChange={(e: any) => {
                                                                        // eslint-disable-next-line
                                                                        membersToAdd.map((m: any) => {
                                                                            if (m.email === member.email) {
                                                                                m.role = e.target.value;
                                                                            }
                                                                        });
                                                                        setMembersToAdd(membersToAdd);
                                                                    }}
                                                                >
                                                                    <option
                                                                        value={"admin"}
                                                                        selected={member.role === "admin"}
                                                                    >
                                                                        Team Admin
                                                                    </option>
                                                                    <option
                                                                        value={"member"}
                                                                        selected={member.role === "member"}
                                                                    >
                                                                        Member
                                                                    </option>
                                                                    <option
                                                                        value={"viewer"}
                                                                        selected={member.role === "viewer"}
                                                                    >
                                                                        Viewer
                                                                    </option>
                                                                </select>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                padding: "0rem 0.2rem",
                                                            }}
                                                        >
                                                            Account Admin
                                                        </p>
                                                    )}
                                                </Col>
                                                <Col sm={1}>
                                                    {!isOwner && currentMemberRole !== member.role ? (
                                                        <>
                                                            {isUserCanChange ? (
                                                                <img
                                                                    src={Delete}
                                                                    alt="delete"
                                                                    onClick={() => deleteMember(member)}
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={Delete}
                                                                    alt="delete"
                                                                    style={{
                                                                        opacity: "0.5",
                                                                        cursor: "not-allowed",
                                                                    }}
                                                                />
                                                            )}
                                                        </>
                                                    ) : null}
                                                </Col>
                                            </SingleMember>
                                        );
                                    })}
                            </Col>
                        </ContainerRow>
                        <ContainerRow>
                            <Col style={{ width: "100%", justifyContent: 'center' }}>
                                <SubmitBtn
                                    type="submit"
                                    disabled={!isUserCanChange}
                                >
                                    {values.id ? "Update Team" : "Create Team"}
                                </SubmitBtn>
                            </Col>
                        </ContainerRow>
                    </form>
                )}
            </Formik>
            {/* </MainPopupDiv> */}
        </>
    );
};

const TeamSettingsNew = ({ setShowTeamSettingsPopup, isButtonCreateNewTeam }: any) => {
    const { setShowLoader } = useContext(LoaderContext);
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const {
        selectedTeam,
        addUserTeams,
        userTeams,
        updateUserTeam,
        setSelectedTeam,
        findUserRole,
        userRoleInTeam
    } = useContext(TeamContext);

    const [isCreateNewTeam, setIsCreateNewTeam] = useState<boolean>(false);

    const [isUserCanChange, setIsUserCanChange] = useState<boolean>(true);

    const [isOwnerOfGroupTeam, setIsOwnerOfGroupTeam] = useState(false);

    const [memberToDelete, setMemberToDelete] = useState<any>();

    const [membersToAdd, setMembersToAdd] = useState<any>([]);

    const [showUtilityConfirmationBox, setShowUtilityConfirmationBox] =
        useState<boolean>(false);

    const [emailAddress, setEmailAddress] = useState<any>();
    // eslint-disable-next-line
    const [groupTeam, setGroupTeam] = useState<any>([]);

    const [confirmationBoxMessage, setConfirmationBoxMessage] =
        useState<string>("");

    const [confirmationAction, setConfirmationAction] = useState<string>("");

    const [currentMemberRole, setcurrentMemberRole] = useState<string>("");

    const deleteMember = (member: any) => {
        setMemberToDelete(member);
        setShowUtilityConfirmationBox(true);
        setConfirmationBoxMessage(`Remove ${member.email} from the team!`);
        setConfirmationAction("delete");
    };

    useEffect(() => {
        if (isButtonCreateNewTeam === true) {
            setIsCreateNewTeam(true)
            setMembersToAdd([]);
        }
        else {
            setIsCreateNewTeam(false);
            setMembersToAdd(selectedTeam?.members);
        }

    }, [])

    useEffect(() => {
        if (user) {
            findUserRole(user)
        }
    }, [selectedTeam]);

    console.log("isCreateNewTeam: ", isCreateNewTeam)
    console.log("membersToAdd: ", membersToAdd)


    const confirmClick = (action: string) => {
        if (action === "delete") {
            setShowLoader(false);
            if (memberToDelete.id) {
                // delete API call
                myAxios
                    .post(`/teams/delete-member`, {
                        memberId: memberToDelete.id,
                        teamId: selectedTeam?._id,
                    })
                    .then(async (response) => {
                        if (response.data.success) {
                            setShowLoader(false);
                            updateUserTeam(
                                response.data.message.resData.lastSelectedTeam,
                                response.data.message.resData.lastSelectedTeam.id
                            );
                            NotificationManager.success("Member successfully deleted.");
                        } else {
                            setShowLoader(false);
                            NotificationManager.error("Unable to delete member!");
                        }
                    })
                    .catch((error) => {
                        setShowLoader(false);
                        NotificationManager.error("Unable to delete member!");
                    });
            }
            let remainingMembers = membersToAdd.filter(
                (m: any) => m.email !== memberToDelete.email
            );
            setMembersToAdd(remainingMembers);
            setShowUtilityConfirmationBox(false);
        }
    };

    const searchUserByEmail = () => {
        if (emailAddress === user?.email) {
            if (groupTeam)
                return NotificationManager.error(
                    "You are already a member of the team."
                );
            else
                return NotificationManager.error(
                    "You would be added as a owner of the team."
                );
        }
        setShowLoader(true);
        myAxios
            .post(`/user/check-user-exist`, {
                email: emailAddress,
            })
            .then(async (response) => {
                let member: any;
                if (response.data.success) {
                    setShowLoader(false);
                    member = {
                        userId: response.data.data._id,
                        role: "member",
                        status: "active",
                        email: response.data.data.email,
                    };
                } else {
                    setShowLoader(false);
                    member = {
                        userId: "",
                        role: "member",
                        status: "invite",
                        email: emailAddress,
                    };
                }
                if (!membersToAdd.find((m: any) => m.email === member.email)) {
                    setMembersToAdd([...membersToAdd, member]);
                } else
                    NotificationManager.error(
                        "User is already availble in the member list."
                    );
            })
            .catch((error) => {
                setShowLoader(false);
            });
    };

    useEffect(() => {
        if (user) {
            let currentTeamMember: any =
                selectedTeam &&
                selectedTeam.members &&
                selectedTeam?.members.find((m: any) => m.email === user?.email);
            if (currentTeamMember) setcurrentMemberRole(currentTeamMember.role);
            if (
                (currentTeamMember && currentTeamMember.role === "owner") ||
                (currentTeamMember && currentTeamMember.role === "admin")
            ) {
                setIsUserCanChange(true);
            } else {
                setIsUserCanChange(false);
            }
            // setMembersToAdd(selectedTeam?.members);
        }

        // eslint-disable-next-line
    }, [selectedTeam]);

    const createTeam = (datatosave: any) => {
        myAxios
            .post(process.env.REACT_APP_SERVER_URL + "/teams/create-team", {
                inputDetails: { ...datatosave },
            })
            .then((response) => {
                if (response.data && response.data.data) {
                    if (userTeams) {
                        addUserTeams([...userTeams, response.data.data]);
                        setSelectedTeam(response.data.data);
                        setIsCreateNewTeam(false);
                    }
                    NotificationManager.success("Team successfully created.");
                }
            })
            .catch((error) => {
                NotificationManager.error("Unable to create team.");
            });
    };

    const updateTeam = (datatoupdate: any) => {
        let data = {
            teamId: datatoupdate.id,
            inputData: {
                team: {
                    name: datatoupdate.name,
                    description: datatoupdate.description,
                },
                members: datatoupdate.members,
            },
        };
        myAxios
            .post(process.env.REACT_APP_SERVER_URL + "/teams/update-team", {
                ...data,
            })
            .then((response) => {
                if (response.data && response.data.data) {
                    updateUserTeam(response.data.data, data.teamId);
                    NotificationManager.success("Team successfully updated.");
                }
            })
            .catch((error) => {
                NotificationManager.error("Unable to update team.");
            });
    };

    const inviteMember = (member: Member) => {
        let memberToInvite = {
            email: member.email,
            memberID: member.id,
            senderEmail: user?.email,
            teamName: selectedTeam?.name,
            teamId: selectedTeam?.id,
        };
        myAxios
            .post(process.env.REACT_APP_SERVER_URL + "/teams/send-Invitation-email", {
                ...memberToInvite,
            })
            .then((response) => {
                if (response.data.success) {
                    NotificationManager.success("User invited successfully.");
                    updateUserTeam(response.data.data, response.data.data.id);
                }
            })
            .catch((error) => {
                NotificationManager.error("Unable to invite user.");
            });
    };

    useEffect(() => {
        if (user) {
            let groupTeams =
                userTeams && userTeams.filter((team: Team) => team.type === "group");
            let isUserHasGroupTeam =
                groupTeams &&
                groupTeams.length > 0 &&
                groupTeams?.map((team: Team) =>
                    team.createdBy === user.id ? true : false
                );
            if (
                isUserHasGroupTeam &&
                isUserHasGroupTeam.length > 0 &&
                isUserHasGroupTeam.includes(true)
            ) {
                setIsOwnerOfGroupTeam(true);
            } else setIsOwnerOfGroupTeam(false);
        }
        // eslint-disable-next-line
    }, [user]);

    const [hasGroupTeam, setHasGroupTeam] = useState<any>();

    useEffect(() => {
        if (user) {
            let groupTeams =
                userTeams && userTeams.filter((team: Team) => team.type === "group");

            if (groupTeams && groupTeams.length > 0) {
                setHasGroupTeam(true);
            } else setHasGroupTeam(false);
        }
        // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        myAxios
            .get(process.env.REACT_APP_SERVER_URL + "/teams/teamsByUser")
            .then((response) => {
                // console.log("response is , ", response.data);
                if (response.data && response.data.success) {
                    addUserTeams(response.data.data.teamsWithMembers);
                    setSelectedTeam(response.data.data.lastSelectedTeam);
                } else {
                }
            })
            .catch((error) => {
                // handle error
                // resetForm();
            });
        // eslint-disable-next-line
    }, []);

    return (
        <MainPopupDiv>
            {/* <MainRow> */}
            {/* {userRoleInTeam === 'owner' && <>
                    {!isCreateNewTeam ? (
                        <SubmitBtn
                            type="button"
                            style={{
                                width: "max-content",
                                padding: "0.5rem 1rem",
                                alignSelf: "end",
                            }}
                            onClick={() => {
                                setIsCreateNewTeam(true);
                                setMembersToAdd([]);
                            }}
                        >
                            Create New Team
                        </SubmitBtn>
                    ) : (
                        <SubmitBtn
                            type="button"
                            style={{
                                width: "max-content",
                                padding: "0.5rem 1rem",
                                alignSelf: "end",
                            }}
                            onClick={() => {
                                setIsCreateNewTeam(false);
                                setMembersToAdd(selectedTeam?.members);
                            }}
                        >
                            Cancel Create Team
                        </SubmitBtn>
                    )}
                </>
                } */}
            {/* <FirstRow> */}
            <Col>
                {isCreateNewTeam ? (
                    <ColumnBox>
                        <TeamSettingsSidebarPopup
                            user={user}
                            membersToAdd={membersToAdd}
                            createTeam={createTeam}
                            updateTeam={updateTeam}
                            isUserCanChange={true}
                            searchUserByEmail={searchUserByEmail}
                            setEmailAddress={setEmailAddress}
                            emailAddress={emailAddress}
                            inviteMember={inviteMember}
                            setMembersToAdd={setMembersToAdd}
                            deleteMember={deleteMember}
                            currentMemberRole={currentMemberRole}
                            setShowTeamSettingsPopup={setShowTeamSettingsPopup}
                            isCreateNewTeam={isCreateNewTeam}
                        />
                    </ColumnBox>
                ) : (
                    <ColumnBox>
                        {selectedTeam?.type === "individual" &&
                            hasGroupTeam &&
                            !isOwnerOfGroupTeam && (
                                // console.log(
                                //   "inside individual, has group team, not an owner of group team"
                                // )
                                <TeamSettingsSidebarPopup
                                    user={user}
                                    selectedTeam={selectedTeam}
                                    membersToAdd={membersToAdd}
                                    createTeam={createTeam}
                                    updateTeam={updateTeam}
                                    isUserCanChange={isUserCanChange}
                                    searchUserByEmail={searchUserByEmail}
                                    setEmailAddress={setEmailAddress}
                                    emailAddress={emailAddress}
                                    inviteMember={inviteMember}
                                    setMembersToAdd={setMembersToAdd}
                                    deleteMember={deleteMember}
                                    currentMemberRole={currentMemberRole}
                                    setShowTeamSettingsPopup={setShowTeamSettingsPopup}

                                />
                            )}

                        {selectedTeam?.type === "individual" &&
                            hasGroupTeam &&
                            isOwnerOfGroupTeam && (
                                // console.log(
                                //   "inside individual, has group team, owner of group team"
                                // )
                                <TeamSettingsSidebarPopup
                                    user={user}
                                    selectedTeam={selectedTeam}
                                    membersToAdd={membersToAdd}
                                    createTeam={createTeam}
                                    updateTeam={updateTeam}
                                    isUserCanChange={isUserCanChange}
                                    searchUserByEmail={searchUserByEmail}
                                    setEmailAddress={setEmailAddress}
                                    emailAddress={emailAddress}
                                    inviteMember={inviteMember}
                                    setMembersToAdd={setMembersToAdd}
                                    deleteMember={deleteMember}
                                    currentMemberRole={currentMemberRole}
                                    setShowTeamSettingsPopup={setShowTeamSettingsPopup}

                                />
                            )}

                        {selectedTeam?.type === "individual" && !hasGroupTeam && (
                            // console.log("inside individual, not has group team")
                            <TeamSettingsSidebarPopup
                                user={user}
                                selectedTeam={selectedTeam}
                                membersToAdd={membersToAdd}
                                createTeam={createTeam}
                                updateTeam={updateTeam}
                                isUserCanChange={isUserCanChange}
                                searchUserByEmail={searchUserByEmail}
                                setEmailAddress={setEmailAddress}
                                emailAddress={emailAddress}
                                inviteMember={inviteMember}
                                setMembersToAdd={setMembersToAdd}
                                deleteMember={deleteMember}
                                currentMemberRole={currentMemberRole}
                                setShowTeamSettingsPopup={setShowTeamSettingsPopup}

                            />
                        )}

                        {selectedTeam?.type !== "individual" && (
                            // console.log("inside not individual")
                            <TeamSettingsSidebarPopup
                                user={user}
                                selectedTeam={selectedTeam}
                                membersToAdd={membersToAdd}
                                createTeam={createTeam}
                                updateTeam={updateTeam}
                                isUserCanChange={isUserCanChange}
                                searchUserByEmail={searchUserByEmail}
                                setEmailAddress={setEmailAddress}
                                emailAddress={emailAddress}
                                inviteMember={inviteMember}
                                setMembersToAdd={setMembersToAdd}
                                deleteMember={deleteMember}
                                currentMemberRole={currentMemberRole}
                                setShowTeamSettingsPopup={setShowTeamSettingsPopup}

                            />
                        )}
                    </ColumnBox>
                )}
            </Col>
            {/* </FirstRow> */}
            {showUtilityConfirmationBox && (
                <ConfirmationBox
                    setShow={setShowUtilityConfirmationBox}
                    name="Confirmation"
                    message={confirmationBoxMessage}
                    okButtonText="Yes"
                    action={confirmationAction}
                    okButtonClick={confirmClick}
                />
            )}
            {/* </MainRow> */}
        </MainPopupDiv>
    );
};

export default TeamSettingsNew