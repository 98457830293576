import { useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import MainHeader from "../../header";
import styled from "styled-components";
import { MOBILE } from "../../../constants/mobile";
import { TAB } from "../../../constants/tab";
import { MEDIUM } from "../../../constants/desktop";
import FlowIcon from "../../../images/flow-icon.svg";
import CreateChatbotIcon from "../../../images/create-chatbot-icon.svg";
import CreateWithChatbotBuilderButton from "./create-with-chatbot-builder-button";
import CreateChatbotManuallyButton from "./create-chatbot-manually-button";

// Styled components for layout and responsiveness
const AppDescriptionDiv = styled.div`
  display: block;
  padding-top: 2%;
  padding-bottom: 2%;
  background-color: #e2edef;

  @media (max-width: ${MOBILE.maxWidth}px) {
    flex-direction: column; 
    padding: 10px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    padding: 14px;
  }
`;

const MainHeaderPara = styled.p`
  font-size: 24px;
  margin: 0;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 20px;
    margin-bottom: 13px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 33px;
    margin-bottom: 18px;
  }
`;

const MainContentPara = styled.p`
  margin: 0;
  font-size: 16px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 14px;
    margin-bottom: 13px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 20px;
    margin-bottom: 13px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 18px;
    margin-bottom: 16px;
  }
`;

const ContentDiv = styled.div`
padding: 4%;
margin-top: 1%;
margin-bottom: 5%;
background: #FFFFFF;
border: 3px solid #85838A33;
flex-direction: column;
@media (max-width: ${MOBILE.maxWidth}px)
{
  align-items: center;
  text-align: center;  
  padding-bottom: 8%;  
  padding-top: 2.5%;
}
@media (min-width: ${TAB.minWidth}px) {
  align-items: center;
  text-align: center;  
  padding-bottom: 8%;  
  padding-top: 2.5%;
}
  @media (min-width: ${MEDIUM.minWidth}px) {
  align-items: start;
  text-align: start; 
  padding: 2%;
  width: 35%;
  justify-content: space-between;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 5%;
padding-bottom: 5%;
}
`

const BottomRow = styled.div`
margin: 3%;
@media (max-width: ${MOBILE.maxWidth}px)
{
flex-direction: column;
}
@media (min-width: ${TAB.minWidth}px) {
  flex-direction: column;
}
@media (min-width: ${MEDIUM.minWidth}px) {
  flex-direction: row;
  justify-content: center;
  margin: 3% 10%;
  margin-bottom: 2%;
}
`

const IconImage = styled.img`
@media (min-width: ${MEDIUM.minWidth}px) {
margin: 2% 0%;
margin-bottom: 7%;
}
`

const HeaderPara = styled.p`
  font-size: 24px;
  margin: 0;
  text-align: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 20px;
    margin-bottom: 13px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 30px;
    margin-bottom: 13px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 30px;
    margin-bottom: 7%;
  }
`;

const ContentPara = styled.p`
  font-size: 16px;
  margin: 0;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 14px;
    margin-bottom: 13px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 20px;
    margin-bottom: 13px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 20px;
    margin-bottom: 7%;
  }
`;

const ChatbotBuilder = () => {
  const { user } = useContext(UserContext);

  return (
    <div style={{ flexDirection: "column", position: "relative" }}>
      {user && <MainHeader isUserPage={true} activeFrom={"fromDemo"} />}
      <AppDescriptionDiv>
        <center>
          <MainHeaderPara>
            <b>Welcome to Dropchat Chatbot Builder</b>
          </MainHeaderPara>
          <MainContentPara>
            Build your first chatbot in minutes! Just tell us about your company
            or website, and we’ll use that info as the chatbot’s knowledge base.
          </MainContentPara>
          <MainContentPara>
            Create a smart, human-like chat experience for your audience—simple,
            fast, and easy.
          </MainContentPara>
        </center>
      </AppDescriptionDiv>
      <BottomRow>
        {/* Chatbot Builder Section */}
        <ContentDiv>
          <IconImage src={FlowIcon} alt="Chatbot Builder Icon" />
          <HeaderPara>Chatbot Builder</HeaderPara>
          <ContentPara>
            Create a new chatbot using the chatbot builder. It's simple and
            easy!
          </ContentPara>
          <CreateWithChatbotBuilderButton />
        </ContentDiv>

        {/* Manual Chatbot Creation Section */}
        <ContentDiv>
          <IconImage src={CreateChatbotIcon} alt="Manual Chatbot Icon" />
          <HeaderPara>Create Chatbot Manually</HeaderPara>
          <ContentPara>
            Enter chatbot details manually and start using it within minutes.
          </ContentPara>
          <CreateChatbotManuallyButton />
        </ContentDiv>
      </BottomRow>
    </div>
  );
};

export default ChatbotBuilder;
