import { useState, useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { UserContext } from "../../context/UserContext";
import BackIcon from "../../images/chat-back.svg";
import MainHeader from "../header";
import UserProfile from "./user-profile";
import UserSettings from "./user-settings";
import { PublicContext } from "../../context/PublicContext";
import {
  DEVICE_MEDIUM,
  DEVICE_MOBILE,
  DEVICE_TAB,
  MEDIUM,
  MOBILE,
  TAB,
} from "../../constants/breakpoints";
import MobileUserProfile from "./mobile-user-profile";
import IntegrationsApps from "../user-settings/integrations-apps";
import TeamSettigs from "../user-settings/team-settings";
import { TeamContext } from "../../context/team-context";

const MainDiv = styled.div`
  flex-direction: column;
`;

const TabContainer = styled.div`
  background-color: #f8f7fa;
  padding: 8px;
  display: flex;
  /* border-top: 1px #21232c48 solid; */
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08); */
`;
const HeaderContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const HeaderText = styled.h3`
  text-align: center;
  color: red;
`;

const AppFeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;
const BackButton = styled.img`
  padding: 0;
  width: 12px;
  cursor: pointer;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 10px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: 12px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    padding: 0;
    width: 12px;
    cursor: pointer;
  }
`;
const Column = styled(Col)`
  @media (max-width: ${MOBILE.maxWidth}px) {
  }
  @media (min-width: ${TAB.minWidth}px) {
    background-color: #f8f7fa;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    background-color: #f8f7fa;
    /* margin-top: 1vh; */
    margin-left: 3rem;
  }
`;
const HeaderSpan = styled.span`
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 16px;
    margin-left: 1rem;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-left: 1rem;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 18px;
    margin-left: 1rem;
    font-weight: 600;
  }
`;
const Tab = styled.button<{
  selected?: boolean;
}>`
  border: none;
  outline: none;
  cursor: pointer;
  padding: 6.5px 16px;
  min-width: 100px;
  transition: 0.3s;
  font-size: 17px;
  position: relative;
  background-color: #fff;
  border-bottom: ${(props) =>
    props.selected ? "3px solid #1585b5" : "3px solid #eaeaea"};
  color: ${(props) => (props.selected ? "#1585B5" : "#33303C61")};
  font-weight: 700;
`;

const UserProfileTab = () => {
  return (
    <>
      <AppFeatureContainer>
        <UserProfile />
      </AppFeatureContainer>
    </>
  );
};

const UserSettingsTab = () => {
  return (
    <>
      <AppFeatureContainer>
        <UserSettings />
      </AppFeatureContainer>
    </>
  );
};
const IntegrationsAppTab = () => {
  return (
    <>
      <AppFeatureContainer>
        <IntegrationsApps />
      </AppFeatureContainer>
    </>
  );
};

// const TeamSettingsTab = () => {
//   return (
//     <>
//       <AppFeatureContainer style={{ padding: "1rem" }}>
//         <TeamSettigs />
//       </AppFeatureContainer>
//     </>
//   );
// };

const UserView = ({ isOpen }: any) => {
  let navigate = useNavigate();
  const location = useLocation();
  const { deviceWidth } = useContext(PublicContext);
  const { user } = useContext(UserContext);
  const [selectTabName, setSelectTabName] = useState("User Profile");
  const { selectedTeam, findUserRole, userRoleInTeam } = useContext(TeamContext);


  // useEffect(() => {
  //   // console.log("selectedTeam", selectedTeam);
  //   if (user) {
  //     findUserRole(user)
  //     if (userRoleInTeam !== 'owner') {
  //       setSelectTabName("Team Settings")
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [selectedTeam]);

  return (
    <>
      <MainDiv>
        {user && user.role === "public" ? (
          <HeaderContainer>
            <HeaderText>You are not authorized to view this page</HeaderText>
          </HeaderContainer>
        ) : (
          <MainHeader isUserPage={true} activeFrom={"fromUser"} />
        )}
        <TabContainer>
          <Column>
            <BackButton
              src={BackIcon}
              onClick={() => {
                navigate("/my-chatbots/create-pdf-chatbot");
              }}
            />
            <HeaderSpan>{selectTabName}</HeaderSpan>
          </Column>
        </TabContainer>
        {/* This Tabbar is not visible on mobile and tablet devices. it is only visible on desktop */}
        {deviceWidth !== (DEVICE_TAB || DEVICE_MOBILE) &&
          deviceWidth === DEVICE_MEDIUM && (
            <TabContainer
              style={{
                borderTop: "0",
                backgroundColor: "#fff",
                width: "90vw",
                marginLeft: "3rem",
                // marginTop: "1rem",
                boxShadow: " 0px 0px 10px 0px rgba(0, 0, 0, 0.08)",
              }}
            >
              {user &&
                (user.role === "authenticated" ||
                  user.role === "super_admin") && (
                  <>

                    <Tab
                      onClick={() => {
                        navigate("/settings/user-profile");
                        setSelectTabName("User Profile");
                      }}
                      selected={location.pathname === "/settings/user-profile"}
                    >
                      User Profile
                    </Tab>

                    <Tab
                      onClick={() => {
                        navigate("/settings/user-settings");
                        setSelectTabName("User Settings");
                      }}
                      selected={location.pathname === "/settings/user-settings"}
                    >
                      User Settings
                    </Tab>
                    <Tab
                      onClick={() => {
                        navigate("/settings/integration-settings");
                        setSelectTabName("Integration Settings");
                      }}
                      selected={
                        location.pathname === "/settings/integration-settings"
                      }
                    >
                      Integration
                    </Tab>

                    {/* <Tab
                      onClick={() => {
                        navigate("/settings/team-settings");
                        setSelectTabName("Team Settings");
                      }}
                      selected={location.pathname === "/settings/team-settings"}
                    >
                      Team Settings
                    </Tab> */}
                  </>
                )}
            </TabContainer>
          )}

        {/* This section is not visible on mobile and tablet devices.it is only visible on desktop */}
        {deviceWidth !== (DEVICE_TAB || DEVICE_MOBILE) &&
          deviceWidth === DEVICE_MEDIUM && (
            <Row
              style={{
                backgroundColor: "#fff",
                width: "90vw",
                marginLeft: "3rem",
                marginTop: "1rem",
                boxShadow: " 0px 0px 10px 0px rgba(0, 0, 0, 0.08)",
              }}
            >
              <Routes>
                {user &&
                  (user.role === "authenticated" ||
                    user.role === "super_admin") && (
                    <>
                      <Route
                        path="/user-profile"
                        element={<UserProfileTab />}
                      />
                      <Route
                        path="/user-settings"
                        element={<UserSettingsTab />}
                      />
                      <Route
                        path="/integration-settings"
                        element={<IntegrationsAppTab />}
                      />
                      {/* <Route
                        path="/team-settings"
                        element={<TeamSettingsTab />}
                      /> */}
                    </>
                  )}
              </Routes>
            </Row>
          )}

        {/*  a new component has been created for mobile and tablet devices, incorporating accordions.*/}
        {deviceWidth === (DEVICE_MOBILE || DEVICE_TAB) && <MobileUserProfile />}
      </MainDiv>
    </>
  );
};

export default UserView;
