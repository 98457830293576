// import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Navbar, Row } from "react-bootstrap";
// import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BookContext } from "../../context/BookContext";
import { UserContext } from "../../context/UserContext";
// import { LoaderContext } from "../../context/loader-context";
import { PaginationContext } from "../../context/pagination-context";
import myAxios from "../../myaxios";
// import { parseErrorResponse } from "../../utils/utils";
import MainHeader from "../header";
import SubscriptionExpiredPopup from "../subscription-expired-popup";
import SubscriptionLimitPopup from "../subscription-limit-popup";
import BookLibrary from "./book-library";
// import BookSearch from "./book-search";
// import BookSearchList from "./book-search-list";
import {
  DEVICE_MOBILE,
  MEDIUM,
  MOBILE,
  TAB,
} from "../../constants/breakpoints";
import { PublicContext } from "../../context/PublicContext";
import { LoaderContext } from "../../context/loader-context";
import useDebounce from "../../use-debounce";
import CreateDropchatBook from "./create-book";
import { TeamContext } from "../../context/team-context";
import { PlanContext } from "../../context/plan-context";
import { StatusLoaderSection, WaitTitle } from "../app-loader";
import LoaderGif from "../../images/loader.gif";
import NavbarForBookArea from "./Navbar/navbar-for-book-area";
import NavbarForTeamUser from "./Navbar/navbar-for-team-user";
import TeamSettingsSidebarPopup from "../team-settings-sidebar/team-settings-sidebar-popup";
import { TeamDropdownContext } from "../../context/team-dropdown-context";
import TeamSettingsNew from "../team-settings-sidebar/team-settings-new";
import NavbarForTeamUserMobile from "./Navbar/navbar-for-team-user-mobile";
import { CustomBlurDivContext } from "../../context/custom-blur-div-context";

declare var _cio: any;

const RightDivMain = styled(Col)`
  flex-direction: row !important;
  width: 100% !important;
`;

const RightDiv = styled(Col)`
  flex-direction: column !important;
  background: none !important;
  // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px !important;
  padding: 12px !important;
  position: relative !important;
  flex: 1 !important;
  width: 100% !important;
  // height: 2000px;
`;


const ListRow = styled(Row)`
  width: 100% !important;
  margin: 0 !important;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: auto !important;
  }
`;

export const WaitTitleNew = styled.p`
  text-align: center;
  font-size: 20px;
  color: #4f83b5;
  font-weight: bold;
`;


const ContainerDiv = styled(Container)`
margin-top: 2% !important;
margin-bottom: 3% !important;
padding-right: 0% !important;
height: 100% !important;
@media (min-width: ${MEDIUM.minWidth}px) {
margin-top: 1% !important;
margin-bottom: 1% !important;
}
`

const BookArea = ({ loadingState }: any) => {
  const { deviceWidth } = useContext(PublicContext);
  const {
    noFoBots,
    setNoOfBots,
    books,
    setBooks,
    totalPage,
    setTotalPage,
    setNoOfTeamBooks,
    noOfTeamBooks,
  } = useContext(BookContext);
  const [userSubs, setUserSubs] = useState<any>();
  const { activePlan, setPlansList, setActivePlan, setLoggedInUserPlan, setDowngradeRequestData } =
    useContext(PlanContext);
  const [isUserCanChange, setIsUserCanChange] = useState<boolean>(true);
  const { selectedTeam, userRoleInTeam } = useContext(TeamContext);
  const { setShowLoader } = useContext(LoaderContext);
  const {
    userProfileSettings,
    user,
    totalBooksCount,
    setTotalBooksCount,
    updateUserSubscription,
    setUserProfileSettings,
  } = useContext(UserContext);
  const { pageLimit, setPageLimit, pageSkip, setPageSkip } =
    useContext(PaginationContext);
  const [currentPage, setCurrentPage] = useState<any>(0);
  const [bookCurrentPage, setBookCurrentPage] = useState<number>(0);
  const [resetPagination, setResetPagination] = useState<boolean>(false);
  const [userRole, setUserRole] = useState<string>('')
  const [showTeamSettingsPopup, setShowTeamSettingsPopup] = useState<boolean>(false);
  const [isButtonCreateNewTeam, setIsButtonCreateNewTeam] = useState<boolean>(false);
  const { selectedDropdownValue, setSelectedDropdownValue } = useContext(TeamDropdownContext);

  const navigate = useNavigate();

  useDebounce(
    () => {
      if (!user) {
        navigate("/verifying-credentials");
      } else {
        setShowLoader(true);
        if (selectedTeam) {
          if (selectedDropdownValue !== 'All Chatbots') {
            myAxios
              .post(process.env.REACT_APP_SERVER_URL + "/books/paginated-books", {
                team: selectedTeam._id,
                pagination: { limit: pageLimit, skip: pageSkip },
              })
              .then((response) => {
                setBooks(response.data.data.books);
                setTotalPage(response.data.data.count);
                setNoOfBots(response.data.data.count);
                setNoOfTeamBooks(
                  response.data.data.totalTeamBooks.length > 0
                    ? response.data.data.totalTeamBooks.length
                    : 0
                );
              })
              .catch((error) => {
                setShowLoader(false);
              });
            // setShowLoader(false);
            myAxios
              .get(process.env.REACT_APP_SERVER_URL + "/books/count")
              .then((response) => {
                setTotalBooksCount(response.data.data.totalBooksCount);
              })
              .catch((error) => { });
            myAxios
              .get(`${process.env.REACT_APP_SERVER_URL}/plans`)
              .then((res) => {
                if (res.data.success) {
                  // user state need to update
                  updateUserSubscription(res.data.data.currentSubscription);
                  setUserSubs(res.data.data);
                  setShowLoader(false);
                  setActivePlan(res.data.data.currentSubscription);
                  setLoggedInUserPlan(
                    res.data.data.loggedInUserPlans.currentSubscription
                  );
                }
              });
            myAxios
              .get(process.env.REACT_APP_SERVER_URL + "/user/user-profile")
              .then((response) => {
                if (response.data && response.data.data) {
                  setUserProfileSettings(response.data.data);
                }
              })
              .catch((error) => {
                // handle error
                // console.log("user settings error, ", error.response.data);
              });

          } else {
            // console.log("new api should run");
            myAxios
              .post(process.env.REACT_APP_SERVER_URL + "/books/get-all-books-for-user", {
                // team: selectedTeam._id,
                pagination: { limit: pageLimit, skip: pageSkip },
              })
              .then((response) => {
                setBooks(response.data.data.books);
                setTotalPage(response.data.data.count);
                setNoOfBots(response.data.data.count);
                setNoOfTeamBooks(
                  response.data.data.totalTeamBooks.length > 0
                    ? response.data.data.totalTeamBooks.length
                    : 0
                );
              })
              .catch((error) => {
                setShowLoader(false);
              });
            // setShowLoader(false);
            myAxios
              .get(process.env.REACT_APP_SERVER_URL + "/books/count")
              .then((response) => {
                setTotalBooksCount(response.data.data.totalBooksCount);
              })
              .catch((error) => { });
            myAxios
              .get(`${process.env.REACT_APP_SERVER_URL}/plans`)
              .then((res) => {
                if (res.data.success) {
                  // user state need to update
                  updateUserSubscription(res.data.data.currentSubscription);
                  setUserSubs(res.data.data);
                  setShowLoader(false);
                  setActivePlan(res.data.data.currentSubscription);
                  setLoggedInUserPlan(
                    res.data.data.loggedInUserPlans.currentSubscription
                  );
                }
              });
            myAxios
              .get(process.env.REACT_APP_SERVER_URL + "/user/user-profile")
              .then((response) => {
                if (response.data && response.data.data) {
                  setUserProfileSettings(response.data.data);
                }
              })
              .catch((error) => {
                // handle error
                // console.log("user settings error, ", error.response.data);
              });

          }
        }
        else {
          setShowLoader(false)
        }

      }
      // eslint-disable-next-line
    },
    [selectedTeam, userProfileSettings.totalCalculatedBooks, selectedDropdownValue],
    500
  );


  useEffect(() => {
    // console.log("selectedTeam", selectedTeam);
    if (selectedTeam) {
      let currentTeamMember: any =
        selectedTeam &&
        selectedTeam.members &&
        selectedTeam?.members.find((m: any) => m.email === user?.email);
      currentTeamMember && setUserRole(currentTeamMember.role)
      // console.log("userRole is, ", userRole);
      if (
        (currentTeamMember && currentTeamMember.role === "owner") ||
        (currentTeamMember && currentTeamMember.role === "admin") ||
        (currentTeamMember && currentTeamMember.role === "member")
      ) {
        setIsUserCanChange(true);
      } else {
        setIsUserCanChange(false);
      }
    } else {
      setIsUserCanChange(true);
    }
    // eslint-disable-next-line
  }, [selectedTeam]);

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.usetiful.com/dist/usetiful.js";
    script.setAttribute("id", "usetifulScript");
    script.dataset.token = process.env.REACT_APP_USETIFUL_TOKEN;

    document.head.appendChild(script);

    return () => {
      // Cleanup the script if the component unmounts
      document.getElementById("usetifulScript")?.remove();
    };
  }, []); // Empty dependency array ensures this runs only once on mount

  useEffect(() => {
    setResetPagination(true)
  }, [selectedDropdownValue])

  return (
    <>
      <MainHeader isUserPage={true} activeFrom={"fromMain"} showTeamSettingsPopup={showTeamSettingsPopup} />
      <NavbarForBookArea setShowTeamSettingsPopup={setShowTeamSettingsPopup} setIsButtonCreateNewTeam={setIsButtonCreateNewTeam}
        setSelectedDropdownOption={setSelectedDropdownValue} selectedDropdownOption={selectedDropdownValue} />

      {selectedDropdownValue && selectedDropdownValue !== 'All Chatbots' &&
        <>
          {/* Render different Navbar components based on device width */}
          {deviceWidth !== DEVICE_MOBILE ? (
            <NavbarForTeamUser
              setShowTeamSettingsPopup={setShowTeamSettingsPopup}
              setIsButtonCreateNewTeam={setIsButtonCreateNewTeam}
            />
          ) : (
            <NavbarForTeamUserMobile
              setShowTeamSettingsPopup={setShowTeamSettingsPopup}
              setIsButtonCreateNewTeam={setIsButtonCreateNewTeam}
            />
          )}
        </>
      }

      <ContainerDiv
        fluid
      >

        <Row
          style={{
            width: "100%",
            // margin: "0",
            display: "flex",
          }}
        >

          <RightDivMain
            style={{
              paddingRight: "0",
              margin: '0',
            }}
          >
            <RightDiv>
              {books && books.length > 0 ? (
                <ListRow>
                  <BookLibrary
                    allBooks={books}
                    bookCurrentPage={bookCurrentPage}
                    setBookCurrentPage={setBookCurrentPage}
                    totalPage={noFoBots}
                    setTotalPage={setTotalPage}
                    pageLimit={pageLimit}
                    setPageLimit={setPageLimit}
                    pageSkip={pageSkip}
                    setPageSkip={setPageSkip}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    resetPagination={resetPagination}
                    setResetPagination={setResetPagination}
                    isUserCanChange={isUserCanChange}
                  />
                </ListRow>
              ) : (
                <></>
              )}
            </RightDiv>
          </RightDivMain>
        </Row>
      </ContainerDiv>
      {showTeamSettingsPopup && <>
        <TeamSettingsNew setShowTeamSettingsPopup={setShowTeamSettingsPopup} isButtonCreateNewTeam={isButtonCreateNewTeam} />
      </>
      }
    </>
  );
};

export default BookArea;
