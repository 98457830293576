
import { useContext } from "react";
import WarningIcon from "../../../images/warning-icon.svg"
import { TeamContext } from "../../../../context/team-context";
import styled from "styled-components";
import { Col, Navbar, Row } from "react-bootstrap";
import { MOBILE } from "../../../../constants/mobile";
import { TAB } from "../../../../constants/tab";
import { MEDIUM } from "../../../../constants/desktop";
import BackImage from '../../../../images/back-button-black.svg'
import { PublicContext } from "../../../../context/PublicContext";
import { DEVICE_MEDIUM, DEVICE_MOBILE, DEVICE_TAB } from "../../../../constants/breakpoints";
import { FaAlignJustify } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const NavbarMain = styled(Navbar)`
padding-left: 2% !important;
padding-right: 2% !important;
width: 100% !important;
z-index: 8 !important;
font-size: 11px !important;
background: #D9D3E5 !important;
flex-direction: column !important;  
align-items: start !important;  
`;

const Label = styled.label`
margin-left: 5%;
`
const TeamNameLabel = styled.label`
margin-left: 4.65%;
@media (max-width: ${MOBILE.maxWidth}px) {
    margin-left: 6.5%;
}
`


const UpperRow = styled.div`
width: 100%;
justify-content: space-between;
`


const BackButton = styled.button`
border: none;
padding: 0;
background: none;
`

const BackButtonImage = styled.img`
width: 15px;
`

const NavbarForChatbotBuilderMobile = ({ createMethod }: any) => {
    const { userRoleInTeam, selectedTeam } = useContext(TeamContext);
    const navigate = useNavigate();

    return (
        <>
            <NavbarMain>
                <UpperRow>
                    <div style={{ width: '50%' }}>
                        <BackButton type="button" onClick={() => { navigate('/create-chatbot') }}> <BackButtonImage src={BackImage} alt="" /></BackButton>

                        {createMethod && createMethod === 'chatbot-builder' ?
                            <Label htmlFor="">Chatbot Builder</Label> :
                            <Label htmlFor="">Manual</Label>
                        }
                    </div>
                    <Label htmlFor="">User Role:
                        {userRoleInTeam && userRoleInTeam === 'owner' && <b> Owner</b>}
                        {userRoleInTeam && userRoleInTeam === 'admin' && <b> Team Admin</b>}
                        {userRoleInTeam && userRoleInTeam === 'member' && <b> Member</b>}
                        {userRoleInTeam && userRoleInTeam === 'viewer' && <b> Viewer</b>}
                    </Label>
                </UpperRow>



                <TeamNameLabel htmlFor="">{selectedTeam?.name}</TeamNameLabel>



            </NavbarMain>


        </>
    );
}

export default NavbarForChatbotBuilderMobile