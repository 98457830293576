import { useContext, useEffect, useState } from "react";
import { DEVICE_MOBILE } from "../../constants/breakpoints";
import { PublicContext } from "../../context/PublicContext";
import { UserContext } from "../../context/UserContext";
import { CodeBoxContext } from "../../context/code-box-context";
import { PlanContext } from "../../context/plan-context";
import MainHeader from "../header";
import MobilePlans from "./mobile-plans";
import Plans from "./plans";
import myAxios from "../../myaxios";

const Subscriptions = () => {
  const { user, updateUserSubscription } = useContext(UserContext);
  const { showConfirmationBox } = useContext(CodeBoxContext);
  const { deviceWidth } = useContext(PublicContext);
  const { setPlansList, setActivePlan, setDowngradeRequestData, setLoggedInUserPlan } =
    useContext(PlanContext);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setLoading(true);
      myAxios.get(`${process.env.REACT_APP_SERVER_URL}/plans`).then((res) => {
        if (res.data.success) {
          setPlansList(res.data.data.plans);
          setActivePlan(res.data.data.currentSubscription);
          setDowngradeRequestData(res.data.data.downgradeRequestData);
          setLoading(false);

          // user state need to update
          updateUserSubscription(res.data.data.currentSubscription);
          setLoggedInUserPlan(
            res.data.data.loggedInUserPlans.currentSubscription
          );
        }
        else {
          setLoading(false);
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (showConfirmationBox) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showConfirmationBox]);

  return (
    <>
      <MainHeader isUserPage={true} activeFrom={"fromPlans"} />
      {deviceWidth !== DEVICE_MOBILE ? (
        <Plans loading={loading} setLoading={setLoading} />
      ) : (
        <MobilePlans loading={loading} setLoading={setLoading} />
      )}
    </>
  );
};

export default Subscriptions;
