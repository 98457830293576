import React, { useEffect, useState } from "react";
import { FieldSection } from "../styled-public";
import styled from "styled-components";
import EditButton from "../../../images/EditButton.svg";
import DeleteButton from "../../../images/delete-icon-chat-seesion.svg";
import EditQuestionPopup from "./edit-question-popup";
import { MOBILE } from "../../../constants/mobile";
import { MEDIUM } from "../../../constants/desktop";

const FieldLabel = styled.label`
  margin-top: 2%;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.8px;
  color: rgba(51, 48, 60, 0.8);
  text-overflow: ellipsis;
  padding: 10px 5px;
`;

const FieldPara = styled.p`
  margin-top: 2%;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.8px;
  color: rgba(51, 48, 60, 0.8);
  background: #fff;
  padding: 25px 5px;
`;

const FormInputTextareaQuestion = styled.textarea`
  border: 1px solid rgba(201, 199, 206, 1);
  border-radius: 4px;
  height: 2rem;
  overflow: hidden;
`;

const FormInputTextarea = styled.textarea`
  padding: 8px;
  border: 1px solid rgba(201, 199, 206, 1);
  border-radius: 4px;
  height: 7rem;
  overflow: hidden;
`;

const HearderPara = styled.label`
display: block;
font-size: 20px;
@media (max-width: ${MOBILE.maxWidth}px){
font-size: 16px;
}
`

const ContentPara = styled.label`
font-size: 18px;
@media (max-width: ${MOBILE.maxWidth}px){
font-size: 14px;
}
@media (min-width: ${MEDIUM.minWidth}px) 
{
font-size: 16px;
}
`

const FieldSectionYourFaq = styled(FieldSection)`
@media (max-width: ${MOBILE.maxWidth}px)
{
margin-top: 2% !important;
}
`

function YourFAQ({ setFieldValue, values, setValues }: any) {
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [valueArray, setValueArray] = useState<any>([]);
  const [editableQuestions, setEditableQuestions] = useState<any>([]);
  const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
  const [questionToEdit, setQuestionToEdit] = useState("");
  const [indexToEdit, setIndexToEdit] = useState<number | null>();

  // Synchronize form values with local state on initial load
  useEffect(() => {
    setValueArray(values.companyFAQ || []);
  }, [values.companyFAQ]);

  useEffect(() => {
    if (!showEditPopup && questionToEdit !== "") {
      showPopup(indexToEdit);
    }
  }, [showEditPopup]);

  const showPopup = (ind: any) => {
    if (ind >= 0) {
      updateQuestion(ind, questionToEdit);
    } else {
      addNewRow();
    }
    setQuestionToEdit("");
    setIndexToEdit(null);
  };

  const addNewRow = () => {
    if (questionToEdit === "") {
      setValueArray([...valueArray, { question: "", answer: "" }]);
      setFieldValue("companyFAQ", [
        ...valueArray,
        { question: "", answer: "" },
      ]);
    } else {
      setValueArray([...valueArray, { question: questionToEdit, answer: "" }]);
      setFieldValue("companyFAQ", [
        ...valueArray,
        { question: questionToEdit, answer: "" },
      ]);
    }
    // isQuestionEditable(valueArray.length);
  };

  const updateQuestion = (index: number, newQuestion: string) => {
    const updatedArray = valueArray.map((item: any, i: number) =>
      i === index ? { ...item, question: newQuestion } : item
    );
    setValueArray(updatedArray);
    setFieldValue("companyFAQ", updatedArray);
  };

  const updateAnswer = (index: number, newAnswer: string) => {
    const updatedArray = valueArray.map((item: any, i: number) =>
      i === index ? { ...item, answer: newAnswer } : item
    );
    setValueArray(updatedArray);
    setFieldValue("companyFAQ", updatedArray);
  };

  const deleteRow = (index: number) => {
    let updatedEditableQuestions = editableQuestions.map((i: number) => {
      if (i === undefined) {
        return;
      }
      if (i === index) {
        return;
      } else if (i > index) {
        return i - 1;
      } else return i;
    });
    setEditableQuestions(updatedEditableQuestions);
    const newArray = valueArray.filter((_: any, i: number) => i !== index);
    setValueArray(newArray);
    setFieldValue("companyFAQ", newArray);
  };

  return (
    <>
      <HearderPara>
        <b>What FAQs would you like the chatbot to answer?</b>
      </HearderPara>
      <ContentPara>
        The more questions you add, the better the chatbot can respond to users.
      </ContentPara>

      {valueArray.length > 0 &&
        valueArray.map((item: any, index: number) => (
          <FieldSectionYourFaq key={index}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "7fr 1fr",
                marginTop: "1%",
              }}
            >
              {editableQuestions &&
                editableQuestions.length > 0 &&
                editableQuestions.includes(index) ? (
                <FormInputTextareaQuestion
                  name={`companyFAQ.${index}.question`}
                  value={item.question}
                  onChange={(e) => updateQuestion(index, e.target.value)}
                />
              ) : (
                <FieldLabel>{item.question}</FieldLabel>
              )}

              {index > 0 && <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {!editableQuestions.includes(index) && (
                  <button
                    type="button"
                    style={{
                      marginLeft: "3%",
                      marginRight: "5%",
                      border: "none",
                      background: "none",
                    }}
                    onClick={() => deleteRow(index)}
                  >
                    <img
                      style={{ width: "20px" }}
                      src={DeleteButton}
                      alt="Delete"
                    />
                  </button>
                )}

                <button
                  type="button"
                  style={{
                    marginLeft: "3%",
                    border: "none",
                    background: "none",
                  }}
                  onClick={() => {
                    setEditIndex(editIndex === index ? null : index);
                    setIndexToEdit(index);
                    setQuestionToEdit(item.question);
                    setShowEditPopup(true);
                  }}
                >
                  {/* {editableQuestions &&
                editableQuestions.length > 0 &&
                editableQuestions.includes(index) ? (
                  <img
                    style={{ width: "20px" }}
                    src={SaveButton}
                    alt="Edit"
                  />
                ) : ( */}
                  <img style={{ width: "20px" }} src={EditButton} alt="Edit" />
                  {/* )} */}
                  {/* <img style={{ width: "20px" }} src={EditButton} alt="Edit" /> */}
                </button>
              </div>}
            </div>
            <FormInputTextarea
              name={`companyFAQ.${index}.answer`}
              value={item.answer}
              onChange={(e) => updateAnswer(index, e.target.value)}
            />
          </FieldSectionYourFaq>
        ))}
      <p style={{ marginTop: "2%", color: "#1585b5"}}>
        <b
          style={{ cursor: "pointer" }}
          onClick={() => {
            // addNewRow();
            setIndexToEdit(-1);
            setShowEditPopup(true);
          }}
        >
          Add New Question
        </b>
      </p>

      {showEditPopup && (
        <EditQuestionPopup
          setShowEditPopup={setShowEditPopup}
          questionToEdit={questionToEdit}
          setQuestionToEdit={setQuestionToEdit}
        />
      )}
    </>
  );
}

export default YourFAQ;
