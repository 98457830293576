import { Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, FormLabel, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { UserContext } from "../../context/UserContext";
import { LoaderContext } from "../../context/loader-context";
import { PlanContext } from "../../context/plan-context";
import {
  EventCategory,
  SubCategory,
  UserEvents,
} from "../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../event-utils/dropchat-event-track";
import { trackEvent } from "../../event-utils/track-event";
import Tick from "../../images/pricing-tick.svg";
import { magic } from "../../magic";
import myAxios, { setAuthHeader } from "../../myaxios";
import { UserSubscription } from "../../types";
import { USER_PROFILE } from "../../utils/class-names";
import { numberWithComma, parseErrorResponse } from "../../utils/utils";
import DeleteAccountConfirmationBox from "../utility-component/delete-account-confirmation-box";

const AddAppFeatureContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-direction: column;
`;

const HeadingText = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: #2f4c95;
`;
const PlanDesc = styled.h6`
  color: #1f062a;
  margin-top: 5px;
  font-weight: 500;
  font-size: 18px;
  color: rgba(31, 6, 42, 0.5);
  line-height: 21px;
`;
const Label = styled(FormLabel)`
  font-weight: bold;
`;

const ValueField = styled(Field)`
  width: 100%;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  display: inline;
  padding: 5px;
  margin: 0px 0px 10px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;

const UserProfileForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const UpadateBtn = styled.button`
  border: none;
  width: 200px;
  height: 40px;
  background: #2f4c95;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin: 8px 0px;
  &:hover {
    background: #0e2151;
    border: none;
  }
`;
const DivForSubmitBtn = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const DeleteAccountSection = styled.div`
  flex-direction: column;
  margin-top: 2rem;
  padding-bottom: 2rem;
`;

const DeleteHeadingText = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: #2f4c95;
`;

const DeleteParagraphText = styled.p`
  font-size: 16px;
  color: #33303c;
  margin-top: 1rem;
`;

const DeleteButton = styled.button`
  background: #ea5455;
  color: #fff;
  padding: 8px 40px 8px 40px;
  border: none;
  border-radius: 4px;
  width: max-content;
`;

const UpgradeSpan = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
  padding: 5px 25px;
  background: #f2ae01;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
`;

const PlanSpan = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #585858;
  margin-left: 5px;
  margin-top: 1px;
  display: flex;
  align-items: center;
`;

const UserProfile = () => {
  let navigate = useNavigate();
  const { setUser, user } = useContext(UserContext);
  const { setPlanHighliter, activePlan, setActivePlan } =
    useContext(PlanContext);
  const { setShowLoader } = useContext(LoaderContext);
  const { userProfileSettings, setUserProfileSettings } =
    useContext(UserContext);
  const [showUtilityConfirmationBox, setShowUtilityConfirmationBox] =
    useState<boolean>(false);
  // const [activePlan, setActivePlan] = useState<UserSubscription>();
  const [confirmationBoxMessage, setConfirmationBoxMessage] =
    useState<string>("");
  const [confirmationAction, setConfirmationAction] = useState<string>("");

  const saveUserProfileSettings = (values: any) => {
    myAxios
      .post(`/user/update-user-profile`, {
        ...values.userProfileSettings,
      })
      .then((response) => {
        if (response.data.success) {
          trackEvent({
            email: getUsersEmail(user),
            eventCategory: EventCategory.USER,
            event: UserEvents.USER_SAVE_UPDATE_PROFILE,
            subCategory: SubCategory.EVENT,
            rawData: response.data.data,
          });

          NotificationManager.success(
            "Settings save successfully...",
            "",
            1500
          );
          if (response.data && response.data.data) {
            setUserProfileSettings(response.data.data);
          }
        } else {
          NotificationManager.error(response.data.message, "", 2000);
        }
      })
      .catch((error) => {
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  useEffect(() => {
    if (user) {
      setShowLoader(true)
      myAxios.get(`${process.env.REACT_APP_SERVER_URL}/plans`).then((res) => {
        if (res.data.success) {
          setActivePlan(res.data.data.currentSubscription);
          setShowLoader(false)
        }
        else setShowLoader(false)
      });
    }
  }, [user]);

  const getCurrentChatbotUses = () => {
    if (!activePlan) return "";

    const txt = `${activePlan?.planStatics.totalBooksCount} -
                ${activePlan?.planStatics.totalBooksCount &&
        activePlan?.planStatics.totalBooksCount > 1
        ? "Chatbots used"
        : "Chatbot used"
      }
                out of
                ${activePlan?.meta.noOfBooks === -1
        ? "Unlimited"
        : activePlan?.meta.noOfBooks
      } 
                ${activePlan?.meta &&
        activePlan?.meta.noOfBooks &&
        (activePlan?.meta.noOfBooks > 1 ||
          activePlan?.meta.noOfBooks === -1)
        ? "chatbots "
        : "chatbot "
      }
                plan limit`;
    return txt;
  };

  const getTotalCharactersUses = () => {
    if (!activePlan) return "";

    const txt = `${activePlan?.planStatics.usedMessage
      ? numberWithComma(Number(activePlan?.planStatics.usedMessage))
      : 0
      } - Total messages used out of ${activePlan?.noOfBook === -1
        ? "Unlimited"
        : numberWithComma(Number(activePlan?.meta.messageCount))
      }  messages plan limit`;
    return txt;
  };

  // const getQuestionCharactersUses = () => {
  //   if (!activePlan) return "";

  //   const txt = `${
  //     activePlan?.planStatics.inputCharacterCount
  //       ? numberWithComma(Number(activePlan?.planStatics.inputCharacterCount))
  //       : 0
  //   } - Question characters used`;
  //   return txt;
  // };

  // const getAnswerCharactersUses = () => {
  //   if (!activePlan) return "";

  //   const txt = `${
  //     activePlan?.planStatics.outputCharacterCount
  //       ? numberWithComma(Number(activePlan?.planStatics.outputCharacterCount))
  //       : 0
  //   } - Answer characters used`;
  //   return txt;
  // };

  const getSharedURLQuestionCharactersUses = () => {
    if (!activePlan) return "";

    const txt = `${activePlan?.planStatics.shareUsedMessage
      ? numberWithComma(Number(activePlan?.planStatics.shareUsedMessage))
      : 0
      } - Shared chatbot URL messages used`;
    return txt;
  };
  const getSharedURLAnswerCharactersUses = () => {
    if (!activePlan) return "";

    const txt = `${activePlan?.planStatics.shareUrlOutputCharacterCount
      ? numberWithComma(
        Number(activePlan?.planStatics.shareUrlOutputCharacterCount)
      )
      : 0
      } - Shared chatbot URL answer characters used`;
    return txt;
  };
  const getBubbleChatQuestionCharactersUses = () => {
    if (!activePlan) return "";

    const txt = `${activePlan?.planStatics.bubbleUsedMessage
      ? numberWithComma(Number(activePlan?.planStatics.bubbleUsedMessage))
      : 0
      } - Bubble(Embedded) chat messages used`;
    return txt;
  };
  const getBubbleChatAnswerCharactersUses = () => {
    if (!activePlan) return "";

    const txt = `${activePlan?.planStatics.bubbleOutputCharacterCount
      ? numberWithComma(
        Number(activePlan?.planStatics.bubbleOutputCharacterCount)
      )
      : 0
      } - Bubble(Embedded) chat answer characters used`;
    return txt;
  };
  const getIndexCharacterUsedCount = () => {
    if (!activePlan) return "";

    const txt = `${activePlan?.indexCharacterUsedTotal
      ? numberWithComma(Number(activePlan?.indexCharacterUsedTotal))
      : 0
      } - Total index characters used out of ${activePlan?.noOfBook === -1
        ? "Unlimited"
        : numberWithComma(Number(activePlan?.meta.indexCharacterSupport))
      } index characters plan limit`;
    return txt;
  };

  const deleteMyAccount = () => {
    setShowLoader(true);
    myAxios
      .post(`/user/user-profile-delete`, {
        email: user?.email,
      })
      .then(async (response) => {
        if (response.data.success) {
          NotificationManager.success("User successfully deleted.", "", 1500);
          if (response.data && response.data.data) {
            localStorage.clear();
            setAuthHeader("");
            if (await magic.user.isLoggedIn()) await magic.user.logout();
            setUser(undefined);
            setShowLoader(false);
            navigate("/landing");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        } else {
          setShowLoader(false);
          NotificationManager.error(response.data.message, "", 2000);
        }
        // setLoading(false);
      })
      .catch((error) => {
        setShowLoader(false);
        NotificationManager.error(parseErrorResponse(error));
        // setLoading(false);
      });
  };

  const deleteConfirmationBox = () => {
    setConfirmationBoxMessage(
      "Deleting user account will remove all of user information from app.dropchat.co. This can not be undone. If you need to use the service again, you will need to create a new account."
    );
    setConfirmationAction("delete");
    setShowUtilityConfirmationBox(true);
  };

  const confirmClick = (action: string) => {
    if (action === "delete") {
      deleteMyAccount();
    }
  };

  return (
    <>
      <Row
        style={{
          marginTop: "-1rem",
        }}
      >
        <Col
          sm={5}
          style={{
            flexDirection: "column",
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "5px",
            height: "calc(100vh - 10vh)",
          }}
        >
          <AddAppFeatureContainer>
            <HeadingText>User Profile</HeadingText>
          </AddAppFeatureContainer>
          <Formik
            enableReinitialize={true}
            validateOnChange={true}
            initialValues={{
              userProfileSettings: userProfileSettings && userProfileSettings,
            }}
            onSubmit={(values, { setSubmitting }) => {
              saveUserProfileSettings(values);
            }}
          >
            {({ setFieldValue }) => (
              <UserProfileForm className={USER_PROFILE}>
                <>
                  <ValueField type="hidden" name={`userProfileSettings.id`} />
                </>
                <>
                  <Label>Email</Label>
                  <ValueField
                    type="text"
                    disabled
                    name={`userProfileSettings.email`}
                  />
                </>
                <>
                  <Label>Firstname</Label>
                  <ValueField
                    type="text"
                    name={`userProfileSettings.firstname`}
                  />
                </>
                <Label>Lastname</Label>
                <ValueField type="text" name={`userProfileSettings.lastname`} />
                <DivForSubmitBtn>
                  <UpadateBtn type="submit">Update</UpadateBtn>
                </DivForSubmitBtn>
              </UserProfileForm>
            )}
          </Formik>

          <DeleteAccountSection>
            <DeleteHeadingText>Deleting Account</DeleteHeadingText>
            <DeleteParagraphText>
              Deleting user account will remove all of user information from
              app.dropchat.co. This can not be undone. If you need to use the
              service again, you will need to create a new account.
            </DeleteParagraphText>
            {user?.currentSubscription ? (
              user?.currentSubscription.meta?.deleteAccount ? (
                <DeleteButton onClick={() => deleteConfirmationBox()}>
                  Delete My Account
                </DeleteButton>
              ) : (
                <Col>
                  <DeleteButton
                    type="button"
                    style={{ opacity: 0.5, cursor: "not-allowed" }}
                  >
                    Delete My Account
                  </DeleteButton>
                  <div style={{ paddingLeft: "2rem" }}>
                    <UpgradeSpan
                      onClick={(e) => {
                        setPlanHighliter({
                          isBookHighliter: false,
                          bookLimit: 0,
                          isDeleteBookHighliter: false,
                          isFlashcardHighliter: false,
                          isCharacterLimit: false,
                          pdfLimit: 0,
                          isDeleteAccount: true,
                        });
                        navigate("/subscription");
                      }}
                    >
                      Upgrade Plan
                    </UpgradeSpan>
                    {/* <PlanSpan>Your plan</PlanSpan> */}
                  </div>
                </Col>
              )
            ) : (
              <div style={{ paddingLeft: "2rem" }}>
                <UpgradeSpan
                  onClick={(e) => {
                    setPlanHighliter({
                      isBookHighliter: false,
                      bookLimit: 0,
                      isDeleteBookHighliter: false,
                      isFlashcardHighliter: false,
                      isCharacterLimit: false,
                      pdfLimit: 0,
                      isDeleteAccount: true,
                    });
                    navigate("/subscription");
                  }}
                >
                  Purchase Plan
                </UpgradeSpan>
              </div>
            )}
          </DeleteAccountSection>
        </Col>

        <Col sm={6} style={{ flexDirection: "column", marginLeft: "40px" }}>
          {activePlan && (
            <AddAppFeatureContainer>
              <div>
                <img
                  alt="img"
                  src={Tick}
                  width="20"
                  style={{ marginRight: "10px" }}
                />
                <PlanDesc style={{ marginTop: "10px" }}>
                  <b></b>
                  {getCurrentChatbotUses()}
                </PlanDesc>
              </div>

              <div>
                <img
                  alt="img1"
                  src={Tick}
                  width="20"
                  style={{ marginRight: "10px" }}
                />
                <PlanDesc>{getTotalCharactersUses()}</PlanDesc>
              </div>
              {/* <div>
                <img
                  alt="img2"
                  src={Tick}
                  width="20"
                  style={{ marginRight: "10px" }}
                />
                <PlanDesc>{getQuestionCharactersUses()}</PlanDesc>
              </div>
              <div>
                <img
                  alt="img3"
                  src={Tick}
                  width="20"
                  style={{ marginRight: "10px" }}
                />
                <PlanDesc>{getAnswerCharactersUses()}</PlanDesc>
              </div> */}
              <div>
                <img
                  alt="img4"
                  src={Tick}
                  width="20"
                  style={{ marginRight: "10px" }}
                />
                <PlanDesc>{getSharedURLQuestionCharactersUses()}</PlanDesc>
              </div>

              {/* <div>
                <img
                  alt="img5"
                  src={Tick}
                  width="20"
                  style={{ marginRight: "10px" }}
                />
                <PlanDesc>{getSharedURLAnswerCharactersUses()}</PlanDesc>
              </div> */}

              <div>
                <img
                  alt="img7"
                  src={Tick}
                  width="20"
                  style={{ marginRight: "10px" }}
                />
                <PlanDesc>{getBubbleChatQuestionCharactersUses()}</PlanDesc>
              </div>

              {/* <div>
                <img
                  alt="img6"
                  src={Tick}
                  width="20"
                  style={{ marginRight: "10px" }}
                />
                <PlanDesc>{getBubbleChatAnswerCharactersUses()}</PlanDesc>
              </div> */}

              <div>
                <img
                  alt="img7"
                  src={Tick}
                  width="20"
                  style={{ marginRight: "10px" }}
                />
                <PlanDesc>{getIndexCharacterUsedCount()}</PlanDesc>
              </div>
            </AddAppFeatureContainer>
          )}
        </Col>
      </Row>

      {showUtilityConfirmationBox && (
        <DeleteAccountConfirmationBox
          setShow={setShowUtilityConfirmationBox}
          name="Confirmation"
          message={confirmationBoxMessage}
          okButtonText="Delete"
          action={confirmationAction}
          okButtonClick={confirmClick}
        />
      )}
    </>
  );
};

export default UserProfile;
