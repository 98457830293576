import { useContext, useState } from "react";
import { PlanContext } from "../../../context/plan-context";
import { BookContext } from "../../../context/BookContext";
import { PublicContext } from "../../../context/PublicContext";
import styled from "styled-components";
import { Col, Container } from "react-bootstrap";
import { MOBILE } from "../../../constants/mobile";
import { TAB } from "../../../constants/tab";
import { MEDIUM } from "../../../constants/desktop";
import SubscriptionLimitPopup from "../../subscription-limit-popup";
import SubscriptionExpiredPopup from "../../subscription-expired-popup";
import CreateDropchatBook from "../create-book";
import { WaitTitleNew } from "../book-area";
import LoaderGif from "../../../images/loader.gif";
import { UserContext } from "../../../context/UserContext";
import MainHeader from "../../header";
import NavbarForChatbotBuilder from "./navbar-for-chatbot-builder/navbar-for-chatbot-builder";
import NavbarForChatbotBuilderMobile from "./navbar-for-chatbot-builder/navbar-for-chatbot-builder-mobile";
import { DEVICE_MOBILE } from "../../../constants/breakpoints";
import { TeamContext } from "../../../context/team-context";


const LeftDiv = styled(Col)`
flex-direction: column !important;
background: white !important;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08) !important;
border-radius: 8px !important;
padding: 3% !important;
position: relative !important;
padding-top: 4% !important;
align-items: center !important;
margin: 2% !important;
width: 95% !important;
@media (min-width: ${MEDIUM.minWidth}px) {
margin: 1% !important;
padding-top: 2% !important;
width: 40% !important;
}
`;

const LeftDivMain = styled(Col)`
  flex-direction: column !important;
  align-items: center !important;
  position: relative !important;
  flex: 1 !important;
  @media (max-width: ${MOBILE.maxWidth}px) {
    height: max-content !important;
  }
  @media (min-width: ${TAB.minWidth}px) {
    height: max-content !important;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    height: auto !important;
  }
`;

const BookHeadingText = styled.h4`
  font-size: 16px;
  color: #797979;
  font-weight: bold;
`;

const SubscriptionExpireOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
`;

const AddSelfBookPara = styled.p`
  color: #282c34;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 7%;
  @media (max-width: ${MOBILE.maxWidth}px) {
  font-size: 12px;
  }
  @media (min-width: ${TAB.minWidth}px) {
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
  margin-bottom: 5%;
  }
`;

const CreateChatbotManually = () => {
    const { deviceWidth } = useContext(PublicContext);
    const { user } = useContext(UserContext)
    const {
        setTotalPage,
        setNoOfTeamBooks,
        noOfTeamBooks,
    } = useContext(BookContext);
    const { activePlan } =
        useContext(PlanContext);
    const { userRoleInTeam } = useContext(TeamContext)
    const [allowedInputsForUser, setAllowedInputsForUser] = useState(false);
    const [setAllIndexIds] = useState<any>([]);
    const [bookCurrentPage, setBookCurrentPage] = useState<number>(0);
    const [resetPagination, setResetPagination] = useState<boolean>(false);
    const [bookError, setBookError] = useState(false);

    return (
        <>
            {user && <MainHeader isUserPage={true} activeFrom={"fromDemo"} />}
            {deviceWidth !== DEVICE_MOBILE ? (
                <NavbarForChatbotBuilder />
            ) : (
                <NavbarForChatbotBuilderMobile />
            )}
            {(
                <LeftDivMain
                // lg={5}
                // style={{
                //     padding: "0",
                // }}
                >
                    <LeftDiv>
                        {activePlan ? (
                            <>
                                {activePlan && activePlan ? (
                                    activePlan.meta && activePlan?.meta?.noOfBooks ? (
                                        activePlan?.meta?.noOfBooks <
                                            0 ? null : noOfTeamBooks &&
                                                noOfTeamBooks >= activePlan?.meta?.noOfBooks ? (
                                            <SubscriptionExpireOverlay>
                                                <SubscriptionLimitPopup
                                                    restrictionMessage={`Your ${activePlan.meta.noOfBooks
                                                        } ${activePlan.meta.noOfBooks > 1
                                                            ? "chatbots"
                                                            : "chatbot"
                                                        } plan limit has been exceeded!`}
                                                    messageregardingrest={`For more chatbots please change your plan.`}
                                                    highliter={`isBookHighliter`}
                                                ></SubscriptionLimitPopup>
                                            </SubscriptionExpireOverlay>
                                        ) : null
                                    ) : null
                                ) : (
                                    <SubscriptionExpireOverlay>
                                        <>
                                            {userRoleInTeam === 'owner' ?
                                                <SubscriptionExpiredPopup
                                                    restrictionMessage={`It looks like you don't currently have a personal subscription, but you still have access to shared chatbots through your team's subscription. To access the team chatbots shared with you, click your email address in the top-right corner!`}
                                                    // messageregardingrest={`To create more chatbots please purchase a plan.`}
                                                    highliter={`isBookHighliter`}
                                                ></SubscriptionExpiredPopup>
                                                :
                                                <SubscriptionExpiredPopup
                                                    restrictionMessage={`It looks like your team owner don't currently have a personal subscription, but you still have access to other shared chatbots through your team's subscription. To access the other team chatbots shared with you, click your email address in the top-right corner!`}
                                                    // messageregardingrest={`To create more chatbots please purchase a plan.`}
                                                    highliter={`isBookHighliter`}
                                                ></SubscriptionExpiredPopup>
                                            }
                                        </>
                                    </SubscriptionExpireOverlay>
                                )}

                                <AddSelfBookPara>
                                    Have a PDF file, website URL you want to chat with?
                                </AddSelfBookPara>
                                <div style={{ padding: "0", width: '100%' }}>
                                    <CreateDropchatBook
                                        setAllowedInputsForUser={setAllowedInputsForUser}
                                        setAllIndexIds={setAllIndexIds}
                                        bookCurrentPage={bookCurrentPage}
                                        setTotalPage={setTotalPage}
                                        setNoOfTeamBooks={setNoOfTeamBooks}
                                        setResetPagination={setResetPagination}
                                    />
                                </div>

                                {bookError && (
                                    <Container>
                                        <BookHeadingText>
                                            Book not found. Please try again!
                                        </BookHeadingText>
                                    </Container>
                                )}
                            </>
                        ) : (
                            <>
                                (
                                <SubscriptionExpireOverlay>
                                    {userRoleInTeam === 'owner' ?
                                        <SubscriptionExpiredPopup
                                            restrictionMessage={`It looks like you don't currently have a personal subscription, but you still have access to shared chatbots through your team's subscription. To access the team chatbots shared with you, click your email address in the top-right corner!`}
                                            // messageregardingrest={`To create more chatbots please purchase a plan.`}
                                            highliter={`isBookHighliter`}
                                        ></SubscriptionExpiredPopup>
                                        :
                                        <SubscriptionExpiredPopup
                                            restrictionMessage={`It looks like your team owner don't currently have a personal subscription, but you still have access to other shared chatbots through your team's subscription. To access the other team chatbots shared with you, click your email address in the top-right corner!`}
                                            // messageregardingrest={`To create more chatbots please purchase a plan.`}
                                            highliter={`isBookHighliter`}
                                        ></SubscriptionExpiredPopup>
                                    }
                                </SubscriptionExpireOverlay>
                                )
                            </>
                        )}
                    </LeftDiv>
                </LeftDivMain>
            )}
        </>
    )
}

export default CreateChatbotManually