import styled from "styled-components";
import { MOBILE } from "../../../../constants/mobile";
import { MEDIUM } from "../../../../constants/desktop";
import { useNavigate } from "react-router-dom";


// Define the interface for button props
interface ButtonProps {
  isDisabled: boolean;
  isTeamButtonHidden: boolean;
}

/* width: ${(props) => (props.isTeamButtonHidden ? '96vw' : 'auto')}; */
/* cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')}; */
/* opacity: ${(props) => (props.isDisabled ? '20%' : '100%')}; */
// Styled-component for the chatbot button
const CopyButton = styled.button`
position: relative;
  font-size: 16px;
  background: #1585b5;
  padding: 0.4rem 1rem;
  border-radius: 4px;
  color: #fff;
  border: 1px solid transparent;
  display: flex;
  width: auto;
  justify-content: center;
  transition: opacity 0.3s ease;
  
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem 1.5rem;
    font-size: 12px;
    margin-top: 0.2rem;
  }
  
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 16px;
    padding: 0.5rem 3rem;
    justify-content: center;
  }
`;

const CopyChatbotButton = ({ updateTeams }: any) => {

  return (
    <CopyButton
      type="button"
      onClick={updateTeams}
    >
      Copy Chatbot
    </CopyButton>
  );
};

export default CopyChatbotButton;
