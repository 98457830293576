import { useContext } from "react";
import styled from "styled-components";
import { BookContext } from "../../../../../context/BookContext";
import { Row } from "react-bootstrap";

const MainDiv = styled.div`
width: 300px;
color: black;
background: #d9d3e5 !important;
padding: 3% 5% 5% 8%;
border-radius: 10px;
/* gap: 100px; */
text-align: start;
`

const TeamPopupForChatPage = () => {
    const { selectedBook } = useContext(BookContext);
    const currentBook: any = selectedBook;
    const bookTeams: any = currentBook && currentBook.teams && currentBook.teams

    return (
        <>
            <MainDiv>{bookTeams && bookTeams.map((team: any) => (<Row>{team.name}</Row>))}</MainDiv>
        </>
    )
}

export default TeamPopupForChatPage;