import { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../../context/loader-context";
import { BookContext } from "../../../context/BookContext";
import { UserContext } from "../../../context/UserContext";
import { ChooseURLContext } from "../../../context/choose-url-context";
import { useNavigate } from "react-router-dom";
import { PublicContext } from "../../../context/PublicContext";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import MainHeader from "../../header";
import { Col, ProgressBar, Row } from "react-bootstrap";
import { Formik, Form } from "formik";
import axios from "axios";
import { parseErrorResponse } from "../../../utils/utils";
import BasicInfo from "../../publicComponent/trial-chatbot/basic-info-component";
import Overview from "../../publicComponent/trial-chatbot/overview-component";
import AboutUs from "../../publicComponent/trial-chatbot/about-us-component";
import YourFAQ from "../../publicComponent/trial-chatbot/your-faq-component";
import myAxios from "../../../myaxios";
import URLPDFComponent from "../../publicComponent/trial-chatbot/url-pdf-component";
import * as BookChatStyles from "../../publicComponent/styled-public-trial";
import ProgressIndicator from "../../../images/loading_bar.gif";
import UrlSelectionBox from "../../utility-component/url-selection-box";
import DummyPopupChatbox from "../../publicComponent/trial-chatbot/dummy-popup-chatbox";
import socket from "../../../dropchat-socket";
import { PaginationContext } from "../../../context/pagination-context";
import { TeamContext } from "../../../context/team-context";
import NavbarForChatbotBuilder from "./navbar-for-chatbot-builder/navbar-for-chatbot-builder";
import { MEDIUM } from "../../../constants/desktop";
import { MOBILE } from "../../../constants/mobile";
import { DEVICE_LARGE, DEVICE_MEDIUM, DEVICE_MOBILE } from "../../../constants/breakpoints";
import NavbarForChatbotBuilderMobile from "./navbar-for-chatbot-builder/navbar-for-chatbot-builder-mobile";
import { Document } from "react-pdf";

declare var _cio: any;

type ContainerType = {
  width?: string; ///Passing Optional Props
  step?: string;
};

interface IsDisabled{
  isDisabled: boolean;
}

const NextButton = styled.button`
height: 40px;
border-radius: 4px;
border: none;
width: 36%;
background: #1585b5;
// padding: 0.5rem;
color: #fff;
margin-right: 0.5rem;

&:hover {
background: #0b5f82;
}

&:focus {
background: #0b5f82;
};

// font-size: 20px;

@media (min-width: ${MEDIUM.minWidth}px)
{
width: 20%;
}

@media (max-width: ${MOBILE.maxWidth}px)
{
width: 40%;
font-size: 14px;
}
`;

const SubmitButton = styled.button`
height: 40px;
border-radius: 4px;
border: none;
width: 36%;
background: #1585b5;
opacity: ${(props: IsDisabled) => (props.isDisabled ? "" : "60%")};

// padding: 0.5rem;
color: #fff;
margin-right: 0.5rem;
&:hover {
background: #0b5f82;
}
&:focus {
background: #0b5f82;
}

@media (min-width: ${MEDIUM.minWidth}px)
{
width: 20%;
}

@media (max-width: ${MOBILE.maxWidth}px)
{
width: 40%;
font-size: 14px;
}
`;

const PreviousButton = styled.button`
height: 40px;
border-color: gray;
border-radius: 4px;
width: 36%;
background: #fff !important;
// padding: 0.5rem;
color: gray;
margin-right: 0.5rem;
&:hover {
background: #0b5f82;
}

@media (min-width: ${MEDIUM.minWidth}px)
{
width: 20%;
}

@media (max-width: ${MOBILE.maxWidth}px)
{
font-size: 14px;
width: 40%;
}
`;

const Container = styled.div`
width: 100%;
  flex-direction: row;
  margin-top: 2%;
`;

const LeftColumnDiv = styled(Col)`
width: 100% !important;
  flex-direction: column !important;
  padding: 20px !important;
  @media (min-width: ${MEDIUM.minWidth}px) {
  WIDTH: 70% !important;
  }
`;

const RightColumnDiv = styled(Col)`
  // position: relative;
  visibility: hidden !important;
  @media (min-width: ${MEDIUM.minWidth}px) {
  visibility: visible !important;
  }
`;

const ToggleTabsDiv = styled.div`
  display: block;
  width: 95%;
  margin-bottom: 10%;
  margin-left: 2%;
  margin-right: 2%;
  @media (min-width: ${MEDIUM.minWidth}px)
  {
  width: 60%;
  margin-bottom: 5%;
  }

  @media (max-width: ${MOBILE.maxWidth}px)
  {
  margin-bottom: 15%;
  }
`;

const FormContainerDiv = styled.div`
  margin-top: 2%;
  display: block;
  margin-bottom: 1%;
`;

const ButtonContainerDiv = styled.div`
  justify-content: space-between;
  margin-top: 10%;

@media (max-width: ${MOBILE.maxWidth}px)
{
margin-top: 15%;
}
`;

const MainContainer = styled.div`
//   width: 100%;
`;

const StepContainer = styled.div<ContainerType>`
  display: flex !important;
  justify-content: space-between !important;
  position: relative !important;
  :before {
    content: "";
    position: absolute !important;
    background: #797979 !important;
    height: 2px !important;
    width: 100% !important;
    // width: 95%;
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 0 !important;
    // left: 4%;
  }
  :after {
    content: "";
    position: absolute !important;
    background: #1585b5 !important;
    height: 2px !important;
    width: ${(props: ContainerType) => props.width} !important;
    top: 50% !important;
    transition: 0.4s ease !important;
    transform: translateY(-50%) !important;
    left: 0 !important;
    // left: 4%;
  }
`;

const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const StepStyle = styled.div<ContainerType>`
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  background-color: ${(props: ContainerType) =>
    props.step === "completed" ? "#1585b5" : "#fff"} !important;
  border: 2px solid
    ${(props: ContainerType) =>
    props.step === "completed" ? "#1585b5" : "#797979"} !important;
  transition: 0.4s ease !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
`;

const StepCount = styled.span`
  font-size: 10px;
  color: #f3e7f3;
  @media (max-width: 600px) {
    font-size: 10px;
  }
`;

const StepsLabelContainer = styled.div`
  position: absolute;
  width: max-content;
  top: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StepLabel = styled.span<ContainerType>`
  font-size: 15px !important;
  font-weight: 600 !important;
  color: ${(props: ContainerType) =>
    props.step === "completed" ? "#1585b5" : "#797979"} !important;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px !important;
    
  }
`;

const CheckMark = styled.div`
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
  -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
`;

const StatusLoaderPopup = styled(Col)`
background: rgba(30 30 30 / 30%) !important;
position: absolute !important;
padding: 20% !important;
height: 100% !important;
display: flex !important;
justify-content: center !important;
align-items: center !important;
flex-direction: column !important;
position: absolute !important;
z-index: 99999 !important;
@media (max-width: ${MOBILE.maxWidth}px) {
padding: 10% !important;
}
`

const ProgressBarItem = styled(ProgressBar)`
height: 14px !important;
width: 70% !important;
@media (max-width: ${MOBILE.maxWidth}px) {
width: 75% !important;
height: 10px !important;
}
@media (min-width: ${MEDIUM.minWidth}px) {
width: 35% !important;
}
`

const ProgressBarImg = styled.img`
width: 160%;
@media (max-width: ${MOBILE.maxWidth}px) {
width: 120%;
}
@media (min-width: ${MEDIUM.minWidth}px) {
width: 200%;
}
`

const steps = [
  {
    label: "Basic Info",
    step: 1,
  },
  {
    label: "Overview",
    step: 2,
  },
  {
    label: "About Us",
    step: 3,
  },
  {
    label: "Your FAQ",
    step: 4,
  },
  {
    label: "Website / PDF",
    step: 5,
  },
];

const CreateWithChatbotBuilder = () => {
  const [componentTitle, setComponentTitle] = useState<string>("basicInfo");
  const [bookId, setBookId] = useState<string>("");

  const [fileObject, setFileObject] = useState<any>();
  const { setShowLoader } = useContext(LoaderContext);
  const { indexingProgress, setIndexingProgress, setBookTypeName } =
    useContext(PublicContext);
  const navigate = useNavigate();
  const {
    setShowConfirmationBox,
    setUrlList,
    setCreateBookInput,
    setResetForm,
  } = useContext(ChooseURLContext);
  const {
    user,
    setTotalBooksCount,
    totalBooksCount,
    userProfileSettings,
    setUserProfileSettings,
  } = useContext(UserContext);
  const { selectedBook, updateBookIndex, books, setSelectedBook } =
    useContext(BookContext);
  const [formValues, setFormValues] = useState<any>();
  const [popupUrlContent, setPopupUrlContent] = useState({
    type: "",
    url: "",
  });
  const [isEmailValidated, setIsEmailValidated] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState(1);
  const nextStep = () => {
    if (!isEmailValidated && !user) {
      // NotificationManager.error("Required valid email")
      return;
    }
    setActiveStep(activeStep + 1);
  };

  const prevStep = () => {
    setActiveStep(activeStep - 1);
  };
  const [isTextChatbot, setIsTextChatbot] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [userSubs, setUserSubs] = useState<any>();
  const [createMethod, setCreateMethod] = useState<string>('');
  const { deviceWidth } = useContext(PublicContext);
  const [showRightDiv, setShowRightDiv] = useState<boolean>(true);
  const [isExpiredChatbot, setIsExpiredChatbot] = useState<boolean>(false);
  const [lastPdfPageLimitStatus, setLastPdfPageLimitStatus] =
    useState<Boolean>(false);
  const [characterCount, setCharacterCount] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const [pdfCountLimit, setPdfCountLimit] = useState<Boolean>(false);
  const [disablePdfIndexLimit, setDisablePdfIndexLimitState] =
    useState<boolean>(false);



  const totalSteps = steps.length;

  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

  useEffect(() => {
    if (window.location.href.includes('/chatbot-builder/')) {
      setCreateMethod('chatbot-builder')
    }
    else if (window.location.href.includes('/manual/')) {
      setCreateMethod('manual')
    }

    if (deviceWidth === DEVICE_LARGE || deviceWidth === DEVICE_MEDIUM) {
      setShowRightDiv(true)
    }
    else setShowRightDiv(false)
  }, [])

  useEffect(() => {
    // console.log("inside subscription")
    if (user) {
      // setLoading(true);
      myAxios.get(`${process.env.REACT_APP_SERVER_URL}/plans`).then((res) => {
        if (res.data.success) {
          setUserSubs(res.data.data);
        }
      });
      myAxios
        .get(process.env.REACT_APP_SERVER_URL + "/user/user-profile")
        .then((response) => {
          if (response.data && response.data.data) {
            setUserProfileSettings(response.data.data);
          }
        })
        .catch((error) => {
          // handle error
          // console.log("user settings error, ", error.response.data);
        });
    }
    // eslint-disable-next-line
  }, []);

  function handleNext(errors: any, setErrors: any) {
    if (!isEmailValidated && !user) {
      NotificationManager.error("Required valid email");
      setErrors({ ...errors, email: "Required email" });
      return;
    }
    let title = componentTitle;
    if (title === "basicInfo") {
      setComponentTitle("overview");
    } else if (title === "overview") {
      setComponentTitle("aboutUs");
    } else if (title === "aboutUs") {
      setComponentTitle("yourFAQ");
    } else if (title === "yourFAQ") {
      setComponentTitle("urlpdf");
    }
  }

  function handlePrevious() {
    console.log("clicked previous");
    let title = componentTitle;
    if (title === "overview") {
      setComponentTitle("basicInfo");
    } else if (title === "aboutUs") {
      setComponentTitle("overview");
    } else if (title === "yourFAQ") {
      setComponentTitle("aboutUs");
    } else if (title === "urlpdf") {
      setComponentTitle("yourFAQ");
    }
  }

  const handleRedirection = (step: number) => {
    if (!isEmailValidated && !user) {
      NotificationManager.error("Required valid email");

      return;
    }
    if (step === 1) {
      setComponentTitle("basicInfo");
      setActiveStep(1);
    }
    if (step === 2) {
      setComponentTitle("overview");
      setActiveStep(2);
    }
    if (step === 3) {
      setComponentTitle("aboutUs");
      setActiveStep(3);
    }
    if (step === 4) {
      setComponentTitle("yourFAQ");
      setActiveStep(4);
    }
    if (step === 5) {
      setComponentTitle("urlpdf");
      setActiveStep(5);
    }
  };
  const checkPdfPageLimit = (count: number) => {
    if (
      user &&
      user.currentSubscription &&
      user.currentSubscription.meta &&
      user.currentSubscription.meta.pdfPageSupport === -1
    ) {
      setLastPdfPageLimitStatus(false);
      setLastPdfPageLimitStatus(false);
      setPdfCountLimit(false);
    } else {
      setPdfCountLimit(
        (user &&
          user.currentSubscription &&
          user.currentSubscription.meta &&
          user.currentSubscription.meta.pdfPageSupport
          ? user.currentSubscription.meta.pdfPageSupport
          : 500) < count
          ? true
          : false
      );
      setLastPdfPageLimitStatus(
        (user &&
          user.currentSubscription &&
          user.currentSubscription.meta &&
          user.currentSubscription.meta.pdfPageSupport
          ? user.currentSubscription.meta.pdfPageSupport
          : 500) < count
          ? true
          : false
      );
    }
  };
  const onLoadSuccess = async (pdf: any) => {
    const numPages = pdf.numPages;

    setNumPages(numPages);
    checkPdfPageLimit(numPages);

    let extractedText = "";
    for (let i = 1; i <= numPages; i++) {
      const page = await pdf.getPage(i);
      const textContent = await page.getTextContent();
      const pageText = textContent.items.map((item: any) => item.str).join(" ");
      extractedText += pageText;
    }
    setCharacterCount(extractedText.length);
  };
  useEffect(() => {
    const indexingCharacterLimitCount = Math.max(
      (user?.currentSubscription?.meta?.indexCharacterSupport
        ? user?.currentSubscription?.meta?.indexCharacterSupport
        : 0) -
      (user?.currentSubscription?.indexCharacterUsedTotal
        ? user?.currentSubscription?.indexCharacterUsedTotal
        : 0),
      0
    );
    if (characterCount > indexingCharacterLimitCount) {
      setDisablePdfIndexLimitState(true);
    } else {
      setDisablePdfIndexLimitState(false);
    }
    // eslint-disable-next-line
  }, [setCharacterCount, characterCount]);

  const createDataSource = async (book_Id: any, allvalues: any) => {
    setIndexingProgress(true);
    console.log("book id is in createDataSource, ", book_Id, allvalues);
    // console.log("isTextChatbot: ", isTextChatbot);
    console.log("allvalues: ", allvalues);
    if (allvalues.commpanyDetails && (allvalues.file || allvalues.url)) {
      console.log("creating data source for details ");
      let values: any = {
        title: allvalues.companyName,
        text: allvalues.commpanyDetails,
        type: "text",
        textType: "textWrite",
        user: {
          email: allvalues.email,
        },
      };
      // console.log("book id in createDataSource: ", book_Id);
      // console.log("values in createDataSource: ", values);
      addDataSource(values, book_Id);
    }
    if (allvalues.companyOverview !== "") {
      console.log("creating data source for overview ");

      let values: any = {
        title: "Company Overview",
        text: allvalues.companyOverview,
        type: "text",
        textType: "textWrite",
        user: {
          email: allvalues.email,
        },
      };
      // console.log("book id in createDataSource: ", book_Id);
      // console.log("values in createDataSource: ", values);

      addDataSource(values, book_Id);
    }
    if (allvalues.companyAbout) {
      allvalues.companyAbout.forEach((item: any, i: number) => {
        if (item.answer !== "") {
          console.log("creating data source for about us ");
          let values: any = {
            title: item.question,
            text: item.question + "\n" + item.answer,
            type: "text",
            textType: "textWrite",
            user: {
              email: allvalues.email,
            },
          };
          // console.log("book id in createDataSource: ", book_Id);
          // console.log("values in createDataSource: ", values);
          addDataSource(values, book_Id);
        }
      });
    }
    if (allvalues.companyFAQ && allvalues.companyFAQ.answer !== "") {
      allvalues.companyFAQ.forEach((item: any) => {
        if (item.answer !== "") {
          console.log("creating data source for faq ");
          let values: any = {
            title: item.question,
            text: item.question + "\n" + item.answer,
            type: "text",
            textType: "textWrite",
            user: {
              email: allvalues.email,
            },
          };
          // console.log("book id in createDataSource: ", book_Id);
          // console.log("values in createDataSource: ", values);
          addDataSource(values, book_Id);
        }
      });
    }

    //  title: "",
    // text: "",
    // type: "text",
    // textType: "textWrite",
  };

  const addDataSource = async (values: any, book_Id: any) => {
    console.log("bookId in ads: ", book_Id, "and values: ", values);
    axios
      .post(process.env.REACT_APP_SERVER_URL + `/books/add-source/${book_Id}`, [
        values,
      ])
      .then((response) => {
        console.log("response in addDtataSource is , ", response.data);
        if (response.data && response.data.success) {
          setShowLoader(false);
          updateBookIndex(response.data.data);
          // setShowAddNewSourcePage(false);
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "Source saved successfully."
          );
          setIndexingProgress(true);
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      });
  };

  useEffect(() => {
    let totalPlanBooks: any = user?.currentSubscription?.meta.noOfBooks;
    let usedBook: any = user?.currentSubscription?.planStatics.totalBooksCount;
    if (user?.currentSubscription && !(parseInt(usedBook, 10) >= parseInt(totalPlanBooks, 10))) {
      setIsExpiredChatbot(false)
    }
    else setIsExpiredChatbot(true)

  }, [user?.currentSubscription])

  const createTextBook = async (values: any, resetForm: any) => {
    console.log("inside createTextbook");

    let dataToSave = {
      email: values.email,
      fromDemo: !user && true,
      chatType: "dropchat",
      title: values.companyName ? values.companyName : "Demo Chatbot",
      type: "text",
      text: values.commpanyDetails,
      textType: "textWrite",
      planExpiredChatbot: isExpiredChatbot,
    };
    setBookTypeName(dataToSave.type);
    console.log("data to save: ", dataToSave);

    let createBookInput = dataToSave;

    myAxios
      .post(
        process.env.REACT_APP_SERVER_URL + "/books/create-book",
        createBookInput
      )
      .then((response) => {
        if (response.data && response.data.success) {
          console.log(
            "response.data.success in createTextbook********",
            response.data.data.id
          );
          // setFileObject(undefined);
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "Book successfully created!"
          );
          myAxios
            .get(process.env.REACT_APP_SERVER_URL + "/books/count")
            .then((response) => {
              setTotalBooksCount(response.data.data.totalBooksCount);
            })
            .catch((error) => { });
          setIndexingProgress(true);

          setBookId(response.data.data.id);
          setSelectedBook(response.data.data);
          createDataSource(response.data.data.id, values);
          console.log("response.data.data: ", response.data.data);
          setShowLoader(false);
          // navigate(`/user-demo-chat/${response.data.data._id}`);
          // if (response.data.data.indexStatus === 2) {
          //   setIndexingProgress(false);
          // }
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      })

      .catch((error) => {
        // handle error
        // resetForm();
        setShowLoader(false);
        setFileObject(undefined);
        NotificationManager.error(parseErrorResponse(error));
        setIndexingProgress(false);
      });
  };

  const createBook = async (values: any, resetForm: any) => {
    console.log("inside createbook");
    console.log("fileObject: ", fileObject, "values.file: ", values.file);
    let fileExtension =
      fileObject && fileObject.name ? fileObject.name.split(".")[1] : "";
    console.log("fileExtension: ", fileExtension);
    let dataToSave = {
      fromDemo: !user && true,
      title: values.companyName ? values.companyName : "Demo Chatbot",
      email: values.email,
      type:
        values.selectedRadio === "file"
          ? "aws"
          : values.selectedRadio === "url"
            ? "url"
            : values.selectedRadio === "youtube"
              ? "youtube"
              : values.selectedRadio === "character"
                ? "character"
                : "book",
      fileType:
        fileObject && fileObject.type
          ? fileObject.type
          : values.selectedRadio === "url"
            ? "url"
            : values.selectedRadio === "youtube"
              ? "youtube"
              : values.selectedRadio === "character"
                ? "text"
                : fileExtension,
      url: values.url,
      fileName: fileObject ? fileObject.name : "",
      webUrl: values.webUrl,
      charGreeting: values.charGreeting,
      charOtherInfo: values.charOtherInfo,
      planExpiredChatbot: isExpiredChatbot,
    };
    setBookTypeName(dataToSave.type);
    console.log("data to save: ", dataToSave);

    let createBookInput;

    if (fileObject) {
      console.log("got file object");
      const data = new FormData();

      data.append("files.files", fileObject);
      data.append("data", JSON.stringify(dataToSave));
      createBookInput = data;
    } else {
      createBookInput = dataToSave;
      console.log("did not got file object");
    }

    myAxios
      .post(
        process.env.REACT_APP_SERVER_URL + "/books/create-book",
        createBookInput
      )
      .then((response) => {
        if (response.data && response.data.success) {
          console.log("response.data.success********", response.data.data);
          setFileObject(undefined);
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "Book successfully created!"
          );
          setIndexingProgress(true);
          setBookId(response.data.data.id);
          setSelectedBook(response.data.data);
          createDataSource(response.data.data.id, values);
          console.log(
            "response.data.data.indexStatus: ",
            response.data.data.indexStatus
          );
          myAxios
            .get(process.env.REACT_APP_SERVER_URL + "/books/count")
            .then((response) => {
              setTotalBooksCount(response.data.data.totalBooksCount);
            })
            .catch((error) => { });
          setShowLoader(false);
          // navigate(`/user-demo-chat/${response.data.data._id}`);
          // if (response.data.data.indexStatus === 2) {
          //   setIndexingProgress(false);
          // }
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      })

      .catch((error) => {
        // handle error
        // resetForm();
        setShowLoader(false);
        setFileObject(undefined);
        NotificationManager.error(parseErrorResponse(error));
        setIndexingProgress(false);
      });
  };

  const createUrlBook = async (createBookInput: any, resetForm: any) => {
    // setShowLoader(true);
    console.log("inside createurlbook", createBookInput);
    myAxios
      .post(
        process.env.REACT_APP_SERVER_URL + "/books/create-book",
        createBookInput
      )
      .then((response) => {
        console.log("response is , ", response.data);
        if (response.data && response.data.success) {
          console.log("response is i if , ", response.data);
          setFileObject(undefined);
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "Chatbot successfully created!"
          );
          setIndexingProgress(true);
          setBookId(response.data.data.id);
          setSelectedBook(response.data.data);
          createDataSource(response.data.data.id, formValues);
          myAxios
            .get(process.env.REACT_APP_SERVER_URL + "/books/count")
            .then((response) => {
              setTotalBooksCount(response.data.data.totalBooksCount);
            })
            .catch((error) => { });
          // navigate(`/user-demo-chat/${response.data.data._id}`);
          setShowLoader(false);
          // if (response.data.data.indexStatus === 2) {
          //   setIndexingProgress(false);
          // }
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      })
      .catch((error) => {
        // handle error
        resetForm();
        setShowLoader(false);
        setFileObject(undefined);
        NotificationManager.error(parseErrorResponse(error));
        console.log("error: ", error);
      });
    // if (!isTestUser(createBookInput.email))
    //   _cio.track(USER_ADD_A_BOOK, {
    //     name: "User add a book using create book form",
    //   });
  };

  useEffect(() => {
    // console.log("bookId: ", bookId);
    if (bookId) {
      console.log("found book id: ", bookId);
      socket.emit("bookId", {
        bookId: bookId,
      });
      socket.off("bookId-event");
      socket.on("bookId-event", (data) => {
        console.log("data in book: ", data);
        console.log("socket on");
        if (data) {
          if (data.book) {
            console.log(
              "data.book.roundProgressPercentage: ",
              data.book.roundProgressPercentage
            );
            setProgressPercentage(data.book.roundProgressPercentage);
            if (data.book.roundProgressPercentage == 100) {
              socket.off("bookId-event");
              // socket.disconnect();
              setIndexingProgress(false);
              navigate(`/create-chatbot/chatbot-builder/${data.book.id}`);
            }
          }
        }
      });
    } else {
      setIndexingProgress(false);
    }
    // eslint-disable-next-line
  }, [bookId]);

  // console.log("DEVICE WIDTH: ", deviceWidth)
  // console.log("DEVICE_MEDIUM: ", DEVICE_MEDIUM)
  // console.log("is true/false: ", deviceWidth === DEVICE_MEDIUM || deviceWidth === DEVICE_LARGE && <h1>CORRECT</h1>)


  return (
    <>
      <div style={{ display: "none" }}>
        <Document
          file={fileObject && fileObject}
          onLoadSuccess={onLoadSuccess}
        ></Document>
      </div>
      <MainContainer style={{ flexDirection: "column", position: "relative" }}>
        {user && <MainHeader isUserPage={true} activeFrom={"fromDemo"} />}
        {deviceWidth !== DEVICE_MOBILE ? (
          <NavbarForChatbotBuilder createMethod={createMethod} />
        ) : (
          <NavbarForChatbotBuilderMobile createMethod={createMethod} />
        )}
        {indexingProgress && (
          <>
            <StatusLoaderPopup>
              <div
                style={{
                  flexDirection: "column",
                  background: "white",
                  alignItems: "center",
                }}
              >
                <p
                  style={{ color: "black", fontSize: "20px", marginTop: "2%" }}
                >
                  {progressPercentage + "%"}
                </p>
                {progressPercentage < 1 ? (
                  <div
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ProgressBarImg
                      alt="imgProgress"
                      src={ProgressIndicator}
                    ></ProgressBarImg>
                  </div>
                ) : (
                  <ProgressBarItem
                    animated
                    variant={
                      progressPercentage < 34
                        ? "warning"
                        : progressPercentage < 68
                          ? "info"
                          : "success"
                    }
                    now={progressPercentage}
                  />
                )}
                <div
                  style={{
                    // background: 'white',
                    // background:  "#2f4c95",
                    fontSize: "16px",
                    color: "black",
                    fontWeight: "bold",
                    textAlign: "center",
                    padding: "5%",
                  }}
                >
                  Hello! We wanted to let you know that it will take a few
                  minutes to process this item. Don't worry, though - once the
                  file processing is finished, you'll be able to ask any
                  questions you have. You can leave this screen and return a few
                  minutes later to check status.`
                </div>
              </div>
            </StatusLoaderPopup>
          </>
        )}

        <Container>
          {/* <UserDemoChat /> */}
          <LeftColumnDiv sm="8">
            <ToggleTabsDiv id="toggleTabs">
              {/* <MainContainer> */}
              <StepContainer width={width}>
                {steps.map(({ step, label }) => (
                  <StepWrapper key={step}>
                    <button
                      type="button"
                      style={{ border: "none", background: "none" }}
                      onClick={() => {
                        handleRedirection(step);
                      }}
                    >
                      <StepStyle
                        step={activeStep >= step ? "completed" : "incomplete"}
                      >
                        {activeStep >= step ? (
                          <CheckMark>L</CheckMark>
                        ) : (
                          <StepCount></StepCount>
                        )}
                      </StepStyle>
                    </button>
                    <StepsLabelContainer>
                      <StepLabel
                        key={step}
                        step={activeStep >= step ? "completed" : "incomplete"}
                      >
                        {label}
                      </StepLabel>
                    </StepsLabelContainer>
                  </StepWrapper>
                ))}
              </StepContainer>
              {/* </MainContainer> */}
            </ToggleTabsDiv>

            <FormContainerDiv>
              <Formik
                initialValues={{
                  email: "",
                  companyName: "",
                  commpanyDetails: "",
                  companyOverview: "",
                  companyAbout: [
                    {
                      question: "What is your address?",
                      answer: "",
                    },
                    {
                      question: "What are your office hours?",
                      answer: "",
                    },
                    {
                      question: "What is the best email to use for questions?",
                      answer: "",
                    },
                    {
                      question:
                        "What is the best phone number to use for questions?",
                      answer: "",
                    },
                  ],
                  companyFAQ: [
                    {
                      question:
                        "How can I get pricing for your products or services?",
                      answer: "",
                    },
                  ],
                  selectedRadio: "file",
                  urlTitle: "",
                  url: "",
                  fileTitle: "",
                  file: "",
                }}
                onSubmit={(values, { resetForm }) => {
                  console.log("Formik values : ", values);
                  console.log("File Object - ", fileObject);

                  values.file = fileObject;
                  setShowLoader(true);
                  if (values.url !== "" && values.url !== undefined) {
                    console.log("url selected");
                    axios
                      .post(process.env.REACT_APP_SERVER_URL + "/books/urls", {
                        url: values.url,
                      })
                      .then((response) => {
                        if (response.data && response.data.success) {
                          console.log("response fetched successfully");

                          setShowLoader(false);
                          setUrlList(response.data.data);
                          let dataToSave = {
                            title: values.companyName
                              ? values.companyName
                              : "Demo Chatbot",
                            email: values.email,
                            type: "url",
                            fileType: "url",
                            url: values.url,
                            fileName: "",
                            fromDemo: !user && true,
                            planExpiredChatbot: isExpiredChatbot,
                          };
                          setPopupUrlContent({
                            type: "web",
                            url: values.url,
                          });
                          setCreateBookInput(dataToSave);
                          setResetForm(resetForm);
                          setShowConfirmationBox(true);
                        } else {
                          console.log("no response fetched");

                          setShowLoader(false);
                          NotificationManager.error(
                            response.data.message
                              ? response.data.message
                              : "Unable to process your request, please try later"
                          );
                          console.log("error: ", response.data.message);
                        }
                      })
                      .catch((error) => {
                        // handle error
                        // resetForm();
                        setShowLoader(false);
                        setFileObject(undefined);
                        NotificationManager.error(parseErrorResponse(error));
                        console.log("error: ", error);
                      });
                  } else if (values.file !== "" && values.file !== undefined) {
                    console.log("file selected");
                    // setShowLoader(false);
                    setFileObject(values.file);
                    console.log(
                      "fileObject: ",
                      fileObject,
                      "values.file: ",
                      values.file
                    );
                    createBook(values, resetForm);
                  } else {
                    createTextBook(values, resetForm);
                  }
                  console.log("value are for formvalue, ", values);
                  setFormValues(values);
                }}
              // enableReinitialize
              >
                {({
                  values,
                  setFieldValue,
                  setValues,
                  errors,
                  setErrors,
                  touched,
                }) => (
                  <Form>
                    <Row style={{
                      // overflow: 'scroll', height: '500px'
                    }}>
                      {componentTitle === "basicInfo" && (
                        <BasicInfo
                          setFieldValue={setFieldValue}
                          values={values}
                          errors={errors}
                          touched={touched}
                          setIsEmailValidated={setIsEmailValidated}
                        />
                      )}
                      {componentTitle === "overview" && (
                        <Overview
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                      )}
                      {componentTitle === "aboutUs" && (
                        <AboutUs
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                      )}
                      {componentTitle === "yourFAQ" && (
                        <YourFAQ
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                      )}
                      {componentTitle === "urlpdf" && (
                        <URLPDFComponent
                          setFieldValue={setFieldValue}
                          values={values}
                          setFileObject={setFileObject}
                          isEmailValidated={isEmailValidated}
                          disablePdfIndexLimit={disablePdfIndexLimit}
                          characterCount={characterCount}
                          fileObject={fileObject}
                          setCharacterCount={setCharacterCount}
                        />
                      )}
                    </Row>

                    <ButtonContainerDiv>
                      {componentTitle === "basicInfo" ? (
                        <PreviousButton
                          type="button"
                          onClick={() => {
                            handlePrevious();
                            prevStep();
                          }}
                          disabled
                        >
                          Previous
                        </PreviousButton>
                      ) : (
                        <NextButton
                          type="button"
                          onClick={() => {
                            handlePrevious();
                            prevStep();
                          }}
                        >
                          Previous
                        </NextButton>
                      )}


                      {componentTitle === "urlpdf" ? (
                        (values.selectedRadio !== 'url' ?
                          <SubmitButton isDisabled={!disablePdfIndexLimit} type="submit" disabled={!disablePdfIndexLimit ? false : true}>
                            Create Chatbot
                          </SubmitButton>
                          :
                          <SubmitButton isDisabled={!disablePdfIndexLimit} type="submit">
                            Fetch Links
                          </SubmitButton>
                        )
                      ) : (
                        <NextButton
                          type="button"
                          onClick={() => {
                            handleNext(errors, setErrors);
                            nextStep();
                          }}
                        >
                          Next
                        </NextButton>
                      )}
                    </ButtonContainerDiv>
                  </Form>
                )}
              </Formik>

              <UrlSelectionBox
                popupUrlContent={popupUrlContent}
                createUrlBook={createUrlBook}
                isLoggedIn={true}
              />
            </FormContainerDiv>
          </LeftColumnDiv>

          {showRightDiv &&
            <RightColumnDiv>
              <DummyPopupChatbox />
            </RightColumnDiv>
          }
        </Container>
        {/* <UserDemoChat /> */}
      </MainContainer>
    </>
  );
};

export default CreateWithChatbotBuilder;
