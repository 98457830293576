import styled from "styled-components";
import { MOBILE } from "../../../constants/mobile";
import { TAB } from "../../../constants/tab";
import { MEDIUM } from "../../../constants/desktop";
import { useNavigate } from "react-router-dom";

const Button = styled.button`
background: #1585b5;
padding: 0.4rem 3rem;
border-radius: 4px;
color: #fff;
border: 1px solid transparent;
width: auto;
display: flex;
margin-top: auto;
justify-content: center;
@media (max-width: ${MOBILE.maxWidth}px) {
padding: 0.5rem;
font-size: 14px;
width: 80vw;
margin-top: 0.2rem;
}
@media (min-width: ${TAB.minWidth}px) {
padding: 0.8rem;
font-size: 20px;
width: 70vw;
margin-top: 0.5rem;
}
@media (min-width: ${MEDIUM.minWidth}px) {
padding: 0.8rem;
font-size: 20px;
width: 100%;
}
`;
const CreateChatbotManuallyButton = () => {
  const navigate = useNavigate();

    return (
        <>
        <Button onClick={() => {navigate('/create-chatbot/manual/')}}>
            Create Chatbot Manually
        </Button>
        </>
    )
}

export default CreateChatbotManuallyButton