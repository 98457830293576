import React, { useContext, useEffect, useState } from "react";
import { FieldSection } from "../styled-public";
import styled from "styled-components";
import { Field, useFormikContext } from "formik";
import { MOBILE } from "../../../constants/mobile";
import { MEDIUM } from "../../../constants/desktop";
import { UserContext } from "../../../context/UserContext";
import { FormLabel } from "react-bootstrap";

const FieldLabel = styled.label`
  margin-top: 2%;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.8px;
  white-space: nowrap;
  overflow: hidden;
  color: rgba(51, 48, 60, 0.8);
  text-overflow: ellipsis;
`;

const RadioInput = styled.input`
  padding: 8px;
  border: 1px solid rgba(201, 199, 206, 1);
  border-radius: 4px;
`;

const FormInput = styled(Field)`
  padding: 8px !important;
  border: 1px solid rgba(201, 199, 206, 1) !important;
  border-radius: 4px !important;
  margin-top: 1% !important;
`;

const RadioButtonLabel = styled.label`
  display: flex;
  cursor: pointer;
  margin-right: 2%;
  margin-bottom: 1%;
`;

const FormInputRadio = styled(Field)`
  cursor: pointer !important;
`;

const FormInputFile = styled.input`
  margin-top: 2%;
`;

const PartsDiv = styled.div`
  display: block;
  flex-direction: column;
`;

const HearderPara = styled.label`
display: block;
font-size: 20px;
@media (max-width: ${MOBILE.maxWidth}px){
font-size: 16px;
}
`

const ContentPara = styled.label`
font-size: 18px;
@media (max-width: ${MOBILE.maxWidth}px){
font-size: 14px;
}
@media (min-width: ${MEDIUM.minWidth}px) 
{
font-size: 16px;
}
`

const FieldSectionURLPDF = styled(FieldSection)`
margin-top: 4% !important;
@media (max-width: ${MOBILE.maxWidth}px){
margin-top: 5% !important;
}
@media (min-width: ${MEDIUM.minWidth}px){
margin-top: 2% !important;
}
`

const FieldSectionDiv = styled.div`
  @media (max-width: ${MOBILE.maxWidth}PX)
  {
    flex-direction: column !important;
  margin-top: 5% !important;

  }
`;

const FieldSectionURLTitle = styled(FieldSection)`
width: 50% !important;

  @media (max-width: ${MOBILE.maxWidth}PX)
  {
  margin-left: 0% !important;
  width: 100% !important;

  }
`;

const FieldSectionURL = styled(FieldSection)`
width: 50% !important;
 margin-left: 4% !important;
  @media (max-width: ${MOBILE.maxWidth}PX)
  {
  margin-left: 0% !important;
  margin-top: 1% !important;
  width: 100% !important;


  }
`;

const FieldSectionFileTitle = styled(FieldSection)`
width: 50% !important;
@media (max-width: ${MOBILE.maxWidth}PX)
  {
  margin-left: 0% !important;
  width: 100% !important;
  }
`;

const FieldSectionFile = styled(FieldSection)`
width: 50% !important;
margin-left: 4% !important;
  @media (max-width: ${MOBILE.maxWidth}PX)
  {
  margin-left: 0% !important;
  margin-top: 1% !important;
  width: 100% !important;
  }
`;

const SelectedCount = styled.span`
font-size: 13px;
font-weight: 400;
@media (max-width: ${MOBILE.maxWidth}px) {
font-size: 10px;
}
@media (min-width: ${MEDIUM.minWidth}px) {
font-size: 15px;
}
`;

const InputLabel = styled.label`
background: #d4d4d4;
padding: 1% 2%;
`


const PDFNameFormLabel = styled(FormLabel)`
font-size: 15px !important;
font-weight: 600 !important;
color: #33303c !important;
`;

function URLPDFComponent({ setFieldValue, setFileObject, disablePdfIndexLimit, characterCount, fileObject, setCharacterCount }: any) {
  const { values } = useFormikContext<any>();
  const { user } = useContext(UserContext)
  const [fileName, setFileName] = useState<string | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFileName(e.target.files[0].name);
      setFileObject(e.target.files[0]);
    }
  };
  useEffect(() => {
    if (!fileObject) {
      setCharacterCount(0)
    }
  }, [fileObject])

  console.log("values inside url component", fileObject);
  return (
    <>
      <HearderPara>
        <b>Add Website and PDF Files</b>
      </HearderPara>
      <ContentPara>
        If you want to add any URL and PDF document, go ahead and use below
        fields.
      </ContentPara>

      <FieldSectionURLPDF>
        <PartsDiv>
          <RadioButtonLabel>
            <RadioInput
              type="radio"
              name="selectedRadio"
              checked={values.selectedRadio === "file"}
              onChange={() => {
                setFieldValue(`selectedRadio`, "file");
              }}
            />
            <span style={{ marginLeft: "1%" }}>Upload PDF</span>
          </RadioButtonLabel>

          <FieldSectionDiv>
            <FieldSectionFileTitle>
              <FieldLabel>Company File Title</FieldLabel>
              <FormInput
                name="fileTitle"
                type="text"
                accept=".pdf"
                disabled={values.selectedRadio !== "file"}
                onChange={(e: any) => {
                  setFieldValue(`fileTitle`, e.target.value);
                }}
              />
            </FieldSectionFileTitle>

            <FieldSectionFile>
              <FieldLabel>Upload PDF</FieldLabel>
              <div className="flex flex-col items-center">
                <InputLabel
                  htmlFor="file-upload"
                  className="w-full max-w-sm bg-blue-100 text-gray-700 py-3 px-6 rounded border border-blue-300 cursor-pointer text-center hover:bg-blue-200 transition"
                >
                  {fileName ? fileName : fileObject ? fileObject.name : "Select File"}
                </InputLabel>
                <FormInputFile
                  id="file-upload"
                  type="file"
                  accept=".pdf"
                  hidden
                  className="hidden"
                  onChange={handleFileChange}
                />
              </div>
              {/* <PDFNameFormLabel>
                {fileObject ? fileObject.name : "No file selected"}
              </PDFNameFormLabel> */}
              {fileObject && (
                <FieldLabel
                  style={{
                    padding: "0",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  <SelectedCount
                    style={{
                      color: disablePdfIndexLimit ? "red" : "#33303c",
                    }}
                  >
                    {characterCount} {" / "}
                    {Math.max(
                      (user?.currentSubscription?.meta
                        ?.indexCharacterSupport
                        ? user?.currentSubscription?.meta
                          ?.indexCharacterSupport
                        : 0) -
                      (user?.currentSubscription
                        ?.indexCharacterUsedTotal
                        ? user?.currentSubscription
                          ?.indexCharacterUsedTotal
                        : 0),
                      0
                    )}{" "}
                    {disablePdfIndexLimit
                      ? "Characters limit exceeds"
                      : "Characters"}
                  </SelectedCount>
                </FieldLabel>
              )}
            </FieldSectionFile>
          </FieldSectionDiv>
        </PartsDiv>

        <br />
        <hr />

        <PartsDiv>
          <RadioButtonLabel>
            <RadioInput
              type="radio"
              name="selectedRadio"
              checked={values.selectedRadio === "url"}
              onChange={() => {
                setFieldValue(`selectedRadio`, "url");
              }}
            />
            <span style={{ marginLeft: "1%" }}>Website URLs</span>
          </RadioButtonLabel>

          <FieldSectionDiv>
            <FieldSectionURLTitle>
              <FieldLabel>URL Title</FieldLabel>
              <FormInput
                name="urlTitle"
                type="text"
                disabled={values.selectedRadio !== "url"}
                onChange={(e: any) => {
                  setFieldValue(`urlTitle`, e.target.value);
                }}
              />
            </FieldSectionURLTitle>

            <FieldSectionURL>
              <FieldLabel>Website URL</FieldLabel>
              <FormInput
                name="url"
                type="text"
                disabled={values.selectedRadio !== "url"}
                onChange={(e: any) => {
                  setFieldValue(`url`, e.target.value);
                }}
              />
            </FieldSectionURL>
          </FieldSectionDiv>
        </PartsDiv>
      </FieldSectionURLPDF>
    </>
  );
}

export default URLPDFComponent;
