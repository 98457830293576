import styled from "styled-components";
import { MOBILE } from "../../../constants/mobile";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { TeamContext } from "../../../context/team-context";
import { MEDIUM } from "../../../constants/desktop";
import { UserContext } from "../../../context/UserContext";
import { PlanContext } from "../../../context/plan-context";

// Define the interface for button props
interface ButtonProps {
  isDisabled: boolean;
  isTeamButtonHidden: boolean;
}

// Styled-component for the chatbot button
const CreateNewChatbotButton = styled.button<ButtonProps>`
  opacity: ${(props) => (props.isDisabled ? '20%' : '100%')} !important;
  font-size: 14px !important;
  background: #1585b5 !important;
  padding: 0.4rem 1rem !important;
  border-radius: 4px !important;
  color: #fff !important;
  border: 1px solid transparent !important;
  display: flex !important;
  width: auto !important;
  justify-content: center !important;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')} !important;
  transition: opacity 0.3s ease !important;  
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${(props) => (props.isTeamButtonHidden ? '96vw' : 'auto')} !important;
    padding: 0.5rem 1.5rem !important;
    font-size: 12px !important;
    margin-top: 0.2remv;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 16px !important;
    padding: 0.5rem 3rem !important;
    justify-content: center !important;
  }
`;

const CreateChatbotButton = ({ selectedDropdownOption }: any) => {
  const { userRoleInTeam } = useContext(TeamContext);
  const { activePlan } = useContext(PlanContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    if ((userRoleInTeam === 'viewer') ||
      (activePlan &&
        activePlan?.meta && activePlan?.planStatics &&
        activePlan?.meta?.noOfBooks && activePlan?.planStatics?.totalBooksCount &&
        activePlan?.meta?.noOfBooks <= activePlan?.planStatics?.totalBooksCount) || !activePlan) {
      setIsDisabled(true)
    }
    else {
      setIsDisabled(false)
    }
    // Disable the button if a specific dropdown option is selected and the user is a 'viewer'

  }, [selectedDropdownOption, userRoleInTeam, activePlan]);

  return (
    <CreateNewChatbotButton
      type="button"
      isTeamButtonHidden={selectedDropdownOption !== 'All Chatbots'}
      isDisabled={isDisabled}
      disabled={isDisabled}
      onClick={() => navigate('/create-chatbot')}
    >
      Create New Chatbot
    </CreateNewChatbotButton>
  );
};

export default CreateChatbotButton;
