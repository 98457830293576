import { Navbar } from "react-bootstrap";
import styled from "styled-components";
import TeamSettingsButton from "./team-settings-button";
import { useContext, useEffect } from "react";
import { TeamContext } from "../../../context/team-context";
import { MOBILE } from "../../../constants/mobile";
import { MEDIUM } from "../../../constants/desktop";
import WarningIcon from "../../../images/warning-icon.svg";
import { UserContext } from "../../../context/UserContext";
import { PlanContext } from "../../../context/plan-context";

// Styled component for the main Navbar
const NavbarMain = styled(Navbar)`
  padding-left: 1% !important;
  padding-right: 1% !important;
  justify-content: space-between !important;
  width: 100% !important;
  z-index: 8 !important;
  background: #d9d3e5 !important;
  display: flex !important;
  flex-direction: row !important;

  @media (max-width: ${MOBILE.maxWidth}px) {
    flex-direction: column !important; 
  }
`;

// Styled component for labels
const Label = styled.label`
  font-size: 14px;

  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
  }

  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 16px;
  }
`;

// Styled component for warning labels
const WarningLabel = styled.label`
  font-size: 14px;
  width: 50%;
  color: #ec2d2d;
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: ${MOBILE.maxWidth}px) {
    width: auto;
    font-size: 12px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 16px;
    width: auto;
  }
`;

const NavbarForTeamUser = ({ setShowTeamSettingsPopup, setIsButtonCreateNewTeam }: any) => {
  const { userRoleInTeam, findUserRole } = useContext(TeamContext);
  const { activePlan } = useContext(PlanContext);
  const { user } = useContext(UserContext);


  useEffect(() => {
    if (user) {
      findUserRole(user);
    }
  })
  return (
    <NavbarMain>
      {/* Display user role if available */}
      <Label htmlFor="">User Role:
        {userRoleInTeam && userRoleInTeam === 'owner' && <b> Owner</b>}
        {userRoleInTeam && userRoleInTeam === 'admin' && <b> Team Admin</b>}
        {userRoleInTeam && userRoleInTeam === 'member' && <b> Member</b>}
        {userRoleInTeam && userRoleInTeam === 'viewer' && <b> Viewer</b>}
      </Label>

      {/* Warning message about chatbot creation restriction */}
      {!activePlan ?
        <WarningLabel>
          <img src={WarningIcon} alt="Warning" />
          <b>Note: Unable to create a chatbot as the team owner doesn't have a valid plan.</b>
        </WarningLabel>
        :
        (
          activePlan &&
            activePlan?.meta && activePlan?.planStatics &&
            activePlan?.meta?.noOfBooks && activePlan?.planStatics?.totalBooksCount &&
            activePlan?.meta?.noOfBooks <= activePlan?.planStatics?.totalBooksCount ?
            <WarningLabel>
              <img src={WarningIcon} alt="Warning" />
              <b>Note: Unable to create a chatbot as the team owner's plan has expired.</b>
            </WarningLabel>
            :
            <></>
        )
      }

      {/* Team settings button */}
      <TeamSettingsButton
        setShowTeamSettingsPopup={setShowTeamSettingsPopup}
        setIsButtonCreateNewTeam={setIsButtonCreateNewTeam}
      />
    </NavbarMain>
  );
};

export default NavbarForTeamUser;
