import React, { useContext, useEffect } from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import { CustomBlurDivContext } from "../context/custom-blur-div-context";

export const StatusLoaderSection = styled(Col)`
  position: fixed;
  display: block;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10 !important;
  background: #2726267f !important;
  overflow: hidden;
`;

const NewCustomLoader = () => {
  const { isBackgroundBlur } = useContext(CustomBlurDivContext);

  useEffect(() => {
    if (isBackgroundBlur) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isBackgroundBlur]);

  return isBackgroundBlur ? <StatusLoaderSection /> : null;
};

export default NewCustomLoader;
