import { Field, Form, Formik, FormikHelpers, FormikValues } from "formik";
import React from "react";
import { FieldSection } from "../styled-public";
import styled from "styled-components";
import { MOBILE } from "../../../constants/mobile";
import { MEDIUM } from "../../../constants/desktop";

const FieldLabel = styled.label`
  margin-top: 2%;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.8px;
  // white-space: nowrap;
  // overflow: hidden;
  color: rgba(51, 48, 60, 0.8);
  text-overflow: ellipsis;
`;

const FormInputTextarea = styled.textarea`
  padding: 8px;
  border: 1px solid rgba(201, 199, 206, 1);
  border-radius: 4px;
  height: 7rem;
  overflow: hidden;
`;

const HearderPara = styled.label`
display: block;
font-size: 20px;
@media (max-width: ${MOBILE.maxWidth}px){
font-size: 16px;
}
`

const ContentPara = styled.label`
font-size: 18px;
@media (max-width: ${MOBILE.maxWidth}px){
font-size: 14px;
}
@media (min-width: ${MEDIUM.minWidth}px) 
{
font-size: 16px;
}
`

const FieldSectionCompanyOverview = styled(FieldSection)`
@media (max-width: ${MOBILE.maxWidth}px){
margin-top: 5% !important;
}
`

function Overview({ setFieldValue, values }: any) {
  return (
    <>
      <HearderPara>
        <b>No worries—you can always edit it later.</b>
      </HearderPara>
      <ContentPara>Just enter some info, and our AI will handle the rest.</ContentPara>

      <FieldSectionCompanyOverview style={{}}>
        <FieldLabel>Company Overview</FieldLabel>
        <FormInputTextarea
          defaultValue={values.companyOverview}
          name="companyOverview"
          onChange={(e: any) => {
            setFieldValue(`companyOverview`, e.target.value);
          }}
        />
      </FieldSectionCompanyOverview>
    </>
  );
}

export default Overview;
