import styled from "styled-components";
import CreateChatbotButton from "./create-chatbot-button";
import TeamSelectionDropdown from "./team-selection-dropdown";
import { Col, Nav, Navbar, Row } from "react-bootstrap";
import CreateNewTeamButton from "./create-new-team-button";
import { MOBILE } from "../../../constants/mobile";
import { useState } from "react";

// Styled component for Navbar with responsive design
const NavbarMain = styled(Navbar)`
  padding-left: 1% !important;
  padding-right: 1% !important;
  justify-content: space-between !important;
  align-items: center !important;
  flex-direction: row !important;
  width: 100% !important;
  z-index: 8 !important;
  background: white !important;;
  border-bottom: 1px solid #21232c48 !important;;

  @media (max-width: ${MOBILE.maxWidth}px) {
    flex-direction: column !important;;
  }
`;

// Styled component for Button Row with responsive margin adjustments
const ButtonRow = styled.div`
  margin-left: 3%;
  margin-right: 0;

  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-left: 0;
  }
`;

const NavbarForBookArea = ({ setShowTeamSettingsPopup, setIsButtonCreateNewTeam, selectedDropdownOption, setSelectedDropdownOption }: any) => {
  // console.log("Navbar component rendered");

  return (
    <NavbarMain>
      {/* Dropdown for selecting a team */}
      <TeamSelectionDropdown setSelectedDropdownOption={setSelectedDropdownOption} selectedDropdownOption={selectedDropdownOption} />

      <ButtonRow>
        {/* Button to create a new team */}
        <CreateNewTeamButton
          selectedDropdownOption={selectedDropdownOption}
          setShowTeamSettingsPopup={setShowTeamSettingsPopup}
          setIsButtonCreateNewTeam={setIsButtonCreateNewTeam}
        />

        {/* Button to create a chatbot */}
        <CreateChatbotButton selectedDropdownOption={selectedDropdownOption} />
      </ButtonRow>
    </NavbarMain>
  );
};

export default NavbarForBookArea;
