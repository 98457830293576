import styled from "styled-components"
import { MEDIUM } from "../../../../constants/desktop"
import CopyChatbotNavbar from "./copy-chatbot-navbar"
import { useContext, useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import CopyChatbotButton from "./copy-chatbot-button"
import { UserContext } from "../../../../context/UserContext"
import { BookContext } from "../../../../context/BookContext"
import { MOBILE } from "../../../../constants/mobile"

interface WarningProp {
    warn: boolean;
}

const MainPopupDiv = styled.div`
flex-direction: column;
position: absolute;
top: 50px;
right: 0;
bottom: 0;
z-index: 12; 
background-color: white;
width: 40%;
left: 60%;
`;

const ContentDiv = styled.label`
  padding: 7%;
`;

const HeadingLabel = styled.label`
  font-size: 20px;
  font-weight: bold;
`;

const ParaLabel = styled.label`
  font-size: 16px;
`;

const ParaLabelName = styled.label`
  font-size: 16px;
`;

const ParaLabelRole = styled.label`
  font-size: 16px;
`;

const WarningLabel = styled.label`
  font-size: 16px;
  color: red;
  visibility: ${(props: WarningProp) => (props.warn ? "" : "hidden")};

`;

const TeamListRow = styled(Row)`
  justify-content: space-between !important;
`;

const ButtonDiv = styled.div`
  position: absolute;
  right: 5%;
  bottom: 5%;
`;

const CopyChatbotPopup = ({ setShowCopyChatbotPopup, updateTeams, setNewTeamArray, newTeamArray }: any) => {
    const { user } = useContext(UserContext);
    const [showWrning, setShowWarning] = useState<boolean>(false)

    // State to track checked checkboxes
    const [checkedTeams, setCheckedTeams] = useState<any>(
        user?.teams?.filter((t: any) =>
            newTeamArray.some((bt: any) => t.id === bt.id)
        ) || []
    );

    // Function to handle checkbox toggle
    const handleCheckboxChange = (team: any) => {
        setCheckedTeams((prevCheckedTeams: any) => {
            setShowWarning(false);
            const isAlreadyChecked = prevCheckedTeams.some((t: any) => t.id === team.id);
            if (isAlreadyChecked) {
                if (checkedTeams.length > 1) {

                    return prevCheckedTeams.filter((t: any) => t.id !== team.id);
                }
                else {
                    setShowWarning(true);
                    return checkedTeams
                }
            } else {
                return [...prevCheckedTeams, team];
            }
        });
    };

    useEffect(() => {
        setCheckedTeams(newTeamArray)
    }, [newTeamArray])

    // Update parent state when teams change
    setNewTeamArray && setNewTeamArray(checkedTeams);

    return (
        <>
            <MainPopupDiv>
                <CopyChatbotNavbar setShowCopyChatbotPopup={setShowCopyChatbotPopup} />
                <ContentDiv>
                    <HeadingLabel>Select Team</HeadingLabel>
                    <ParaLabel>
                        You can copy this chatbot to multiple teams, but ensure that you are the team owner of the destination team.
                    </ParaLabel>
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10%' }}>
                        <WarningLabel warn={showWrning}>Atleast one team should be selected.</WarningLabel>
                        {user?.teams?.map((t: any) => (
                            user?.id === t.createdBy && (
                                <TeamListRow>
                                    <Col style={{ justifyContent: 'space-between' }}>
                                        <Col>
                                            <ParaLabel style={{ marginRight: '2%' }}>
                                                <input
                                                    key={t.id}
                                                    type="checkbox"
                                                    checked={checkedTeams.some((team: any) => team.id === t.id)}
                                                    onChange={() => handleCheckboxChange(t)}
                                                />
                                            </ParaLabel>
                                            <ParaLabelName>{t.name}</ParaLabelName>
                                        </Col>
                                        <ParaLabelRole >
                                            Team Owner
                                        </ParaLabelRole>
                                    </Col>
                                    <hr />
                                </TeamListRow>
                            )
                        ))}
                    </div>
                    <ButtonDiv>
                        <CopyChatbotButton updateTeams={updateTeams} />
                    </ButtonDiv>
                </ContentDiv>
            </MainPopupDiv>
        </>
    );
}

export default CopyChatbotPopup;
