import axios from "axios";
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Button, Col, ProgressBar } from "react-bootstrap";
import styled from "styled-components";
import ProgressIndicator from "../../../images/loading_bar.gif";
import AboutUs from "./about-us-component";
import BasicInfo from "./basic-info-component";
import Overview from "./overview-component";
import URLPDFComponent from "./url-pdf-component";
import YourFAQ from "./your-faq-component";
import { NotificationManager } from "react-notifications";
import { isTestUser, parseErrorResponse } from "../../../utils/utils";
import UrlSelectionBox from "../../utility-component/url-selection-box";
import { LoaderContext } from "../../../context/loader-context";
import { PublicContext } from "../../../context/PublicContext";
import { useNavigate } from "react-router-dom";
import { ChooseURLContext } from "../../../context/choose-url-context";
import socket from "../../../dropchat-socket";
import DummyPopupChatbox from "./dummy-popup-chatbox";
import * as BookChatStyles from "./../styled-public-trial";
import { BookContext } from "../../../context/BookContext";
import { UserContext } from "../../../context/UserContext";
import MainHeader from "../../header";

declare var _cio: any;

type ContainerType = {
  width?: string; ///Passing Optional Props
  step?: string;
};

const NextButton = styled.button`
  height: 40px;
  border-radius: 4px;
  border: none;
  width: 16%;
  background: #1585b5;
  padding: 0.5rem;
  color: #fff;
  margin-right: 0.5rem;
  &:hover {
    background: #0b5f82;
  }
  &:focus {
    background: #0b5f82;
  }
`;

const SubmitButton = styled.button`
  height: 40px;
  border-radius: 4px;
  border: none;
  width: 16%;
  background: #1585b5;
  padding: 0.5rem;
  color: #fff;
  margin-right: 0.5rem;
  &:hover {
    background: #0b5f82;
  }
  &:focus {
    background: #0b5f82;
  }
`;

const PreviousButton = styled.button`
  height: 40px;
  border-color: gray;
  border-radius: 4px;
  width: 16%;
  background: #fff !important;
  padding: 0.5rem;
  color: gray;
  margin-right: 0.5rem;
  &:hover {
    background: #0b5f82;
  }
`;

const AppDescriptionDiv = styled.div`
  display: block;
  padding-top: 2%;
  padding-bottom: 2%;
  background-color: #e2edef;
`;

const Container = styled.div`
  flex-direction: row;
`;

const LeftColumnDiv = styled(Col)`
  flex-direction: column !important;
  padding: 20px !important;
`;

const RightColumnDiv = styled(Col)`
  // position: relative;
`;

const ToggleTabsDiv = styled.div`
  display: block;
  width: 60%;
  margin-bottom: 5%;
  margin-left: 2%;
`;

const FormContainerDiv = styled.div`
  margin-top: 2%;
  display: block;
  margin-bottom: 1%;
`;

const ButtonContainerDiv = styled.div`
  justify-content: space-between;
  margin-top: 3%;
`;

const MainContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 16px;
`;

const StepContainer = styled.div<ContainerType>`
  display: flex !important;
  justify-content: space-between !important;
  position: relative !important;
  :before {
    content: "" !important;
    position: absolute !important;
    background: #797979 !important;
    height: 2px !important;
    width: 100% !important;
    // width: 95%;
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 0 !important;
    // left: 4%;
  }
  :after {
    content: "" !important;
    position: absolute !important;
    background: #1585b5 !important;
    height: 2px !important;
    width: ${(props: ContainerType) => props.width} !important;
    top: 50% !important;
    transition: 0.4s ease !important;
    transform: translateY(-50%) !important;
    left: 0 !important;
    // left: 4%;
  }
`;

const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const StepStyle = styled.div<ContainerType>`
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  background-color: ${(props: ContainerType) =>
    props.step === "completed" ? "#1585b5" : "#fff"} !important;
  border: 2px solid
    ${(props: ContainerType) =>
      props.step === "completed" ? "#1585b5" : "#797979"} !important;
  transition: 0.4s ease !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
`;

const StepCount = styled.span`
  font-size: 10px;
  color: #f3e7f3;
  @media (max-width: 600px) {
    font-size: 10px;
  }
`;

const StepsLabelContainer = styled.div`
  position: absolute;
  width: max-content;
  top: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StepLabel = styled.span<ContainerType>`
  font-size: 13px !important;
  font-weight: 600 !important;
  color: ${(props: ContainerType) =>
    props.step === "completed" ? "#1585b5" : "#797979"} !important;
  @media (max-width: 600px) {
    font-size: 16px !important;
  }
`;

const CheckMark = styled.div`
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
  -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
`;

const steps = [
  {
    label: "Basic Info",
    step: 1,
  },
  {
    label: "Overview",
    step: 2,
  },
  {
    label: "About Us",
    step: 3,
  },
  {
    label: "Your FAQ",
    step: 4,
  },
  {
    label: "Website / PDF",
    step: 5,
  },
];

const TrialChatbot = () => {
  const [componentTitle, setComponentTitle] = useState<string>("basicInfo");
  const [bookId, setBookId] = useState<string>("");
  const [fileObject, setFileObject] = useState<any>();
  const { setShowLoader } = useContext(LoaderContext);
  const [popupUrlContent, setPopupUrlContent] = useState({
    type: "",
    url: "",
  });
  const { indexingProgress, setIndexingProgress, setBookTypeName } =
    useContext(PublicContext);
  const navigate = useNavigate();
  const {
    setShowConfirmationBox,
    setUrlList,
    setCreateBookInput,
    setResetForm,
  } = useContext(ChooseURLContext);
  const { user, userProfileSettings, setUserProfileSettings } =
    useContext(UserContext);
  const { selectedBook, updateBookIndex } = useContext(BookContext);
  const [formValues, setFormValues] = useState<any>();
  const [isEmailValidated, setIsEmailValidated] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState(1);
  const nextStep = () => {
    if (!isEmailValidated && !user) {
      // NotificationManager.error("Required valid email")
      return;
    }
    setActiveStep(activeStep + 1);
  };

  const prevStep = () => {
    setActiveStep(activeStep - 1);
  };
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [_email, _setEmail] = useState<any>();

  const totalSteps = steps.length;

  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;
  // const width = `${(95 / (totalSteps - 1)) * (activeStep - 1)}%`;

  console.log("User: ", user);
  // console.log("selectedBook: ", selectedBook);

  // console.log("userProfileSettings: ", userProfileSettings)

  function handleNext(errors: any, setErrors: any) {
    if (!isEmailValidated && !user) {
      NotificationManager.error("Required valid email");
      setErrors({ ...errors, email: "Required email" });
      return;
    }
    let title = componentTitle;
    if (title === "basicInfo") {
      setComponentTitle("overview");
    } else if (title === "overview") {
      setComponentTitle("aboutUs");
    } else if (title === "aboutUs") {
      setComponentTitle("yourFAQ");
    } else if (title === "yourFAQ") {
      setComponentTitle("urlpdf");
    }
  }

  function handlePrevious() {
    console.log("clicked previous");
    let title = componentTitle;
    if (title === "overview") {
      setComponentTitle("basicInfo");
    } else if (title === "aboutUs") {
      setComponentTitle("overview");
    } else if (title === "yourFAQ") {
      setComponentTitle("aboutUs");
    } else if (title === "urlpdf") {
      setComponentTitle("yourFAQ");
    }
  }

  const handleRedirection = (step: number) => {
    if (!isEmailValidated && !user) {
      NotificationManager.error("Required valid email");

      return;
    }
    if (step === 1) {
      setComponentTitle("basicInfo");
      setActiveStep(1);
    }
    if (step === 2) {
      setComponentTitle("overview");
      setActiveStep(2);
    }
    if (step === 3) {
      setComponentTitle("aboutUs");
      setActiveStep(3);
    }
    if (step === 4) {
      setComponentTitle("yourFAQ");
      setActiveStep(4);
    }
    if (step === 5) {
      setComponentTitle("urlpdf");
      setActiveStep(5);
    }
  };

  const createDataSource = async (book_Id: any, allvalues: any) => {
    setIndexingProgress(true);
    console.log("book id is in createDataSource, ", book_Id, allvalues);
    // console.log("isTextChatbot: ", isTextChatbot);
    console.log("allvalues: ", allvalues);
    if (allvalues.commpanyDetails && (allvalues.file || allvalues.url)) {
      console.log("creating data source for details ");
      let values: any = {
        title: allvalues.companyName,
        text: allvalues.commpanyDetails,
        type: "text",
        textType: "textWrite",
        user: {
          email: allvalues.email,
        },
      };
      // console.log("book id in createDataSource: ", book_Id);
      // console.log("values in createDataSource: ", values);
      addDataSource(values, book_Id);
    }
    if (allvalues.companyOverview !== "") {
      console.log("creating data source for overview ");

      let values: any = {
        title: "Company Overview",
        text: allvalues.companyOverview,
        type: "text",
        textType: "textWrite",
        user: {
          email: allvalues.email,
        },
      };
      // console.log("book id in createDataSource: ", book_Id);
      // console.log("values in createDataSource: ", values);

      addDataSource(values, book_Id);
    }
    if (allvalues.companyAbout) {
      allvalues.companyAbout.forEach((item: any, i: number) => {
        if (item.answer !== "") {
          console.log("creating data source for about us ");
          let values: any = {
            title: item.question,
            text: item.question + "\n" + item.answer,
            type: "text",
            textType: "textWrite",
            user: {
              email: allvalues.email,
            },
          };
          // console.log("book id in createDataSource: ", book_Id);
          // console.log("values in createDataSource: ", values);
          addDataSource(values, book_Id);
        }
      });
    }
    if (allvalues.companyFAQ && allvalues.companyFAQ.answer !== "") {
      allvalues.companyFAQ.forEach((item: any) => {
        if (item.answer !== "") {
          console.log("creating data source for faq ");
          let values: any = {
            title: item.question,
            text: item.question + "\n" + item.answer,
            type: "text",
            textType: "textWrite",
            user: {
              email: allvalues.email,
            },
          };
          // console.log("book id in createDataSource: ", book_Id);
          // console.log("values in createDataSource: ", values);
          addDataSource(values, book_Id);
        }
      });
    }

    //  title: "",
    // text: "",
    // type: "text",
    // textType: "textWrite",
  };

  const addDataSource = async (values: any, book_Id: any) => {
    console.log("bookId in ads: ", book_Id, "and values: ", values);
    axios
      .post(process.env.REACT_APP_SERVER_URL + `/books/add-source/${book_Id}`, [
        values,
      ])
      .then((response) => {
        console.log("response in addDtataSource is , ", response.data);
        if (response.data && response.data.success) {
          setShowLoader(false);
          updateBookIndex(response.data.data);
          // setShowAddNewSourcePage(false);
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "Source saved successfully."
          );
          setIndexingProgress(true);
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      });
  };

  const createTextBook = async (values: any, resetForm: any) => {
    console.log("inside createTextbook");

    let dataToSave = {
      email: values.email,
      fromDemo: !user && true,
      chatType: "dropchat",
      title: values.companyName ? values.companyName : "Demo Chatbot",
      type: "text",
      text: values.commpanyDetails,
      textType: "textWrite",
    };
    setBookTypeName(dataToSave.type);
    console.log("data to save: ", dataToSave);

    let createBookInput = dataToSave;

    axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/books/create-book",
        createBookInput
      )
      .then((response) => {
        if (response.data && response.data.success) {
          console.log(
            "response.data.success in createTextbook********",
            response.data.data.id
          );
          // setFileObject(undefined);
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "Book successfully created!"
          );
          setIndexingProgress(true);
          setBookId(response.data.data.id);
          createDataSource(response.data.data.id, values);
          console.log(
            "response.data.data.indexStatus: ",
            response.data.data.indexStatus
          );
          setShowLoader(false);
          // navigate(`/user-demo-chat/${response.data.data._id}`);
          // if (response.data.data.indexStatus === 2) {
          //   setIndexingProgress(false);
          // }
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      })

      .catch((error) => {
        // handle error
        // resetForm();
        setShowLoader(false);
        setFileObject(undefined);
        NotificationManager.error(parseErrorResponse(error));
        setIndexingProgress(false);
      });
  };

  const createBook = async (values: any, resetForm: any) => {
    console.log("inside createbook");
    console.log("fileObject: ", fileObject, "values.file: ", values.file);
    let fileExtension =
      fileObject && fileObject.name ? fileObject.name.split(".")[1] : "";
    console.log("fileExtension: ", fileExtension);
    let dataToSave = {
      fromDemo: !user && true,
      title: values.companyName ? values.companyName : "Demo Chatbot",
      email: values.email,
      type:
        values.selectedRadio === "file"
          ? "aws"
          : values.selectedRadio === "url"
          ? "url"
          : values.selectedRadio === "youtube"
          ? "youtube"
          : values.selectedRadio === "character"
          ? "character"
          : "book",
      fileType:
        fileObject && fileObject.type
          ? fileObject.type
          : values.selectedRadio === "url"
          ? "url"
          : values.selectedRadio === "youtube"
          ? "youtube"
          : values.selectedRadio === "character"
          ? "text"
          : fileExtension,
      url: values.url,
      fileName: fileObject ? fileObject.name : "",
      webUrl: values.webUrl,
      charGreeting: values.charGreeting,
      charOtherInfo: values.charOtherInfo,
    };
    setBookTypeName(dataToSave.type);
    console.log("data to save: ", dataToSave);

    let createBookInput;

    if (fileObject) {
      console.log("got file object");
      const data = new FormData();

      data.append("files.files", fileObject);
      data.append("data", JSON.stringify(dataToSave));
      createBookInput = data;
    } else {
      createBookInput = dataToSave;
      console.log("did not got file object");
    }

    axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/books/create-book",
        createBookInput
      )
      .then((response) => {
        if (response.data && response.data.success) {
          console.log("response.data.success********", response.data.data.id);
          setFileObject(undefined);
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "Book successfully created!"
          );
          setIndexingProgress(true);
          setBookId(response.data.data.id);
          createDataSource(response.data.data.id, values);
          console.log(
            "response.data.data.indexStatus: ",
            response.data.data.indexStatus
          );
          setShowLoader(false);
          // navigate(`/user-demo-chat/${response.data.data._id}`);
          // if (response.data.data.indexStatus === 2) {
          //   setIndexingProgress(false);
          // }
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      })

      .catch((error) => {
        // handle error
        // resetForm();
        setShowLoader(false);
        setFileObject(undefined);
        NotificationManager.error(parseErrorResponse(error));
        setIndexingProgress(false);
      });
  };

  const createUrlBook = async (createBookInput: any, resetForm: any) => {
    // setShowLoader(true);
    console.log("inside createurlbook", createBookInput);
    axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/books/create-book",
        createBookInput
      )
      .then((response) => {
        console.log("response is , ", response.data);
        if (response.data && response.data.success) {
          console.log("response is i if , ", response.data);
          setFileObject(undefined);
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "Chatbot successfully created!"
          );
          setIndexingProgress(true);
          setBookId(response.data.data.id);
          createDataSource(response.data.data.id, formValues);

          // navigate(`/user-demo-chat/${response.data.data._id}`);
          setShowLoader(false);
          // if (response.data.data.indexStatus === 2) {
          //   setIndexingProgress(false);
          // }
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      })
      .catch((error) => {
        // handle error
        resetForm();
        setShowLoader(false);
        setFileObject(undefined);
        NotificationManager.error(parseErrorResponse(error));
        console.log("error: ", error);
      });
    // if (!isTestUser(createBookInput.email))
    //   _cio.track(USER_ADD_A_BOOK, {
    //     name: "User add a book using create book form",
    //   });
  };

  useEffect(() => {
    console.log("bookId: ", bookId);
    if (bookId) {
      console.log("found book id: ", bookId);
      socket.emit("bookId", {
        bookId: bookId,
      });
      socket.off("bookId-event");
      socket.on("bookId-event", (data) => {
        console.log("data in book: ", data);
        console.log("socket on");
        if (data) {
          if (data.book) {
            console.log(
              "data.book.roundProgressPercentage: ",
              data.book.roundProgressPercentage
            );
            setProgressPercentage(data.book.roundProgressPercentage);
            if (data.book.roundProgressPercentage == 100) {
              socket.off("bookId-event");
              // socket.disconnect();
              setIndexingProgress(false);
              navigate(`/public/user-demo-chat/${data.book.id}`);
            }
          }
        }
      });
    } else {
      setIndexingProgress(false);
    }
    // eslint-disable-next-line
  }, [bookId]);

  const getPlan = async (values: any, resetForm: any) => {
    setShowLoader(true);

    console.log("inside getPlan method: ----------------");

    let localCanCreate = false;
    if (values.email) {
      try {
        await axios
          .post(process.env.REACT_APP_SERVER_URL + "/plans-for-landing", {
            email: values.email,
          })
          .then(async (response) => {
            const data = response.data;
            console.log("got res");
            if (data && data.success) {
              console.log("response from plans-for-landing: ", data);
              console.log(
                "response from plans-for-landing: response.data.message:",
                data.message
              );
              if (data.message.canCreate) {
                console.log("cancreate found---: ", data.message.canCreate);
                localCanCreate = true;
              } else if (data.message === "Plans ") {
                let totalCalculatedBooks = 0;
                await axios
                  .post(process.env.REACT_APP_SERVER_URL + "/user-by-email", {
                    email: values.email,
                  })
                  .then((response) => {
                    if (response.data && response.data.data) {
                      console.log("response from user by email: ", response);
                      totalCalculatedBooks =
                        response.data.data.totalCalculatedBooks;
                    }
                  })
                  .catch((error) => {
                    // handle error
                    // console.log("user settings error, ", error.response.data);
                  });
                console.log("inside plans found---: ");
                console.log("plans---");
                console.log(
                  "data.data.currentSubscription.meta.noOfBooks: ",
                  data.data.currentSubscription.meta.noOfBooks
                );
                console.log(
                  "data.data.currentSubscription.planStatics.totalBooksCount: ",
                  data.data.currentSubscription.planStatics.totalBooksCount
                );
                console.log(
                  "response local totalCalculatedBooks: ",
                  totalCalculatedBooks
                );
                console.log(
                  "data.data.currentSubscription.meta",
                  data.data.currentSubscription.meta
                );

                console.log(
                  " data.data.currentSubscription &&    data.data.currentSubscription.meta &&                  totalCalculatedBooks ",
                  data.data.currentSubscription &&
                    data.data.currentSubscription.meta
                );
                if (
                  data.data.currentSubscription &&
                  data.data.currentSubscription.meta
                ) {
                  console.log("found all 3");
                  if (
                    data.data.currentSubscription.meta.noOfBooks >
                    totalCalculatedBooks
                  ) {
                    console.log("plan valid---");
                    localCanCreate = true;
                  } else {
                    console.log("plan not valid---");
                    localCanCreate = false;
                  }
                } else {
                  console.log("plan not valid, plan expired---");
                  localCanCreate = false;
                }
              } else {
              }
            } else {
            }
          });
      } catch (error) {
        console.error("Error fetching plan: ", error);
        localCanCreate = true;
      }
      setShowLoader(false);
    } else {
      setShowLoader(false);
    }

    if (localCanCreate) {
      console.log("Formik values : ", values);
      console.log("File Object - ", fileObject);

      values.file = fileObject;
      setShowLoader(true);
      if (values.url !== "" && values.url !== undefined && values.selectedRadio === 'url') {
        console.log("url selected");
        axios
          .post(process.env.REACT_APP_SERVER_URL + "/books/urls", {
            url: values.url,
          })
          .then((response) => {
            if (response.data && response.data.success) {
              console.log("response fetched successfully");

              setShowLoader(false);
              setUrlList(response.data.data);
              let dataToSave = {
                title: values.companyName ? values.companyName : "Demo Chatbot",
                email: values.email,
                type: "url",
                fileType: "url",
                url: values.url,
                fileName: "",
                fromDemo: !user && true,
                // webUrl: values.webUrl,s
              };
              setPopupUrlContent({
                type: "web",
                url: values.url,
              });
              setCreateBookInput(dataToSave);
              setResetForm(resetForm);
              setShowConfirmationBox(true);
            } else {
              console.log("no response fetched");

              setShowLoader(false);
              NotificationManager.error(
                response.data.message
                  ? response.data.message
                  : "Unable to process your request, please try later"
              );
              console.log("error: ", response.data.message);
            }
          })
          .catch((error) => {
            // handle error
            // resetForm();
            setShowLoader(false);
            setFileObject(undefined);
            NotificationManager.error(parseErrorResponse(error));
            console.log("error: ", error);
          });
      } else if (values.file !== "" && values.file !== undefined && values.selectedRadio === 'file') {
        console.log("file selected");
        // setShowLoader(false);
        setFileObject(values.file);
        console.log("fileObject: ", fileObject, "values.file: ", values.file);
        createBook(values, resetForm);
      } else {
        createTextBook(values, resetForm);
      }

      console.log("value are for formvalue, ", values);
      setFormValues(values);
    } else {
      NotificationManager.error("Please purchase valid plan to create chatbot");
      setShowLoader(false);
    }
  };

  console.log(
    "userProfileSettings.totalCalculatedBooks: ",
    userProfileSettings.totalCalculatedBooks
  );

  return (
    <>
      <div style={{ flexDirection: "column", position: "relative" }}>
        {user && <MainHeader isUserPage={true} activeFrom={"fromDemo"} />}
        {indexingProgress && (
          <>
            <BookChatStyles.StatusLoaderPopup
              style={{
                background: "rgba(30 30 30 / 30%)",
                position: "absolute",
                padding: "20%",
                height: "100%",
              }}
            >
              <div
                style={{
                  flexDirection: "column",
                  background: "white",
                  alignItems: "center",
                }}
              >
                <p
                  style={{ color: "black", fontSize: "20px", marginTop: "2%" }}
                >
                  {progressPercentage + "%"}
                </p>
                {progressPercentage < 1 ? (
                  <div
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt="imgProgress"
                      src={ProgressIndicator}
                      style={{ width: "140%" }}
                    ></img>
                  </div>
                ) : (
                  <ProgressBar
                    animated
                    variant={
                      progressPercentage < 34
                        ? "warning"
                        : progressPercentage < 68
                        ? "info"
                        : "success"
                    }
                    now={progressPercentage}
                    style={{ height: "14px", width: "30%" }}
                  />
                )}
                <div
                  style={{
                    // background: 'white',
                    // background:  "#2f4c95",
                    fontSize: "16px",
                    color: "black",
                    fontWeight: "bold",
                    textAlign: "center",
                    padding: "5%",
                  }}
                >
                  Hello! We wanted to let you know that it will take a few
                  minutes to process this item. Don't worry, though - once the
                  file processing is finished, you'll be able to ask any
                  questions you have. You can leave this screen and return a few
                  minutes later to check status.`
                </div>
              </div>
            </BookChatStyles.StatusLoaderPopup>
          </>
        )}
        <AppDescriptionDiv>
          <center>
            <h4>
              <b>Welcome to Dropchat Chatbot Builder</b>
            </h4>
            <br />
            <h6>
              Build your first chatbot in minutes! Just tell us about your
              company or website, and we’ll use that info as the chatbot’s
              knowledge base.
            </h6>
            <h6>
              Create a smart, human-like chat experience for your
              audience—simple, fast, and easy.
            </h6>
          </center>
        </AppDescriptionDiv>

        <Container>
          {/* <UserDemoChat /> */}
          <LeftColumnDiv sm="8">
            <ToggleTabsDiv id="toggleTabs">
              {/* <MainContainer> */}
              <StepContainer width={width}>
                {steps.map(({ step, label }) => (
                  <StepWrapper key={step}>
                    <button
                      type="button"
                      style={{ border: "none", background: "none" }}
                      onClick={() => {
                        handleRedirection(step);
                      }}
                    >
                      <StepStyle
                        step={activeStep >= step ? "completed" : "incomplete"}
                      >
                        {activeStep >= step ? (
                          <CheckMark>L</CheckMark>
                        ) : (
                          <StepCount></StepCount>
                        )}
                      </StepStyle>
                    </button>
                    <StepsLabelContainer>
                      <StepLabel
                        key={step}
                        step={activeStep >= step ? "completed" : "incomplete"}
                      >
                        {label}
                      </StepLabel>
                    </StepsLabelContainer>
                  </StepWrapper>
                ))}
              </StepContainer>
              {/* </MainContainer> */}
            </ToggleTabsDiv>

            <FormContainerDiv>
              <Formik
                initialValues={{
                  email: "",
                  companyName: "",
                  commpanyDetails: "",
                  companyOverview: "",
                  companyAbout: [
                    {
                      question: "What is your address?",
                      answer: "",
                    },
                    {
                      question: "What are your office hours?",
                      answer: "",
                    },
                    {
                      question: "What is the best email to use for questions?",
                      answer: "",
                    },
                    {
                      question:
                        "What is the best phone number to use for questions?",
                      answer: "",
                    },
                  ],
                  companyFAQ: [
                    {
                      question:
                        "How can I get pricing for your products or services?",
                      answer: "",
                    },
                  ],
                  selectedRadio: "file",
                  urlTitle: "",
                  url: "",
                  fileTitle: "",
                  file: "",
                }}
                onSubmit={(values, { resetForm }) => {
                  _setEmail(values.email);
                  getPlan(values, resetForm);
                }}
                // enableReinitialize
              >
                {({
                  values,
                  setFieldValue,
                  setValues,
                  errors,
                  setErrors,
                  touched,
                }) => (
                  <Form>
                    <div style={{ display: "block" }}>
                      {componentTitle === "basicInfo" && (
                        <BasicInfo
                          setFieldValue={setFieldValue}
                          values={values}
                          errors={errors}
                          touched={touched}
                          setIsEmailValidated={setIsEmailValidated}
                        />
                      )}
                      {componentTitle === "overview" && (
                        <Overview
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                      )}
                      {componentTitle === "aboutUs" && (
                        <AboutUs
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                      )}
                      {componentTitle === "yourFAQ" && (
                        <YourFAQ
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                      )}
                      {componentTitle === "urlpdf" && (
                        <URLPDFComponent
                          setFieldValue={setFieldValue}
                          values={values}
                          setFileObject={setFileObject}
                          isEmailValidated={isEmailValidated}
                        />
                      )}
                    </div>

                    <ButtonContainerDiv>
                      {/* <ButtonsContainer>
                      <ButtonStyle type="button" onClick={prevStep} disabled={activeStep === 1}>
                        Previous
                      </ButtonStyle>
                      <ButtonStyle type="button" onClick={nextStep} disabled={activeStep === totalSteps}>
                        Next
                      </ButtonStyle>
                    </ButtonsContainer> */}
                      {componentTitle === "basicInfo" ? (
                        <PreviousButton
                          type="button"
                          onClick={() => {
                            handlePrevious();
                            prevStep();
                          }}
                          disabled
                        >
                          Previous
                        </PreviousButton>
                      ) : (
                        <NextButton
                          type="button"
                          onClick={() => {
                            handlePrevious();
                            prevStep();
                          }}
                        >
                          Previous
                        </NextButton>
                      )}

                      {componentTitle === "urlpdf" ? (
                        <SubmitButton type="submit">
                          Create Chatbot
                        </SubmitButton>
                      ) : (
                        // !isEmailValidated ||
                        //   (!isEmailValidated && componentTitle === "basicInfo") ? (
                        //   <PreviousButton
                        //     type="button"
                        //     onClick={() => {
                        //       handleNext();
                        //       nextStep();
                        //     }}
                        //     disabled
                        //   >
                        //     Next
                        //   </PreviousButton>
                        // ) :
                        <NextButton
                          type="button"
                          onClick={() => {
                            handleNext(errors, setErrors);
                            nextStep();
                          }}
                        >
                          Next
                        </NextButton>
                      )}
                    </ButtonContainerDiv>
                  </Form>
                )}
              </Formik>

              <UrlSelectionBox
                popupUrlContent={popupUrlContent}
                createUrlBook={createUrlBook}
                isLoggedIn={false}
              />
            </FormContainerDiv>
          </LeftColumnDiv>

          <RightColumnDiv sm="4">
            <DummyPopupChatbox />
          </RightColumnDiv>
        </Container>
        {/* <UserDemoChat /> */}
      </div>
    </>
  );
};

export default TrialChatbot;
