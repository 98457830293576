import { createContext, ReactElement, useState } from "react";
import { TeamDropdownProps, TeamDropdownValues } from "../types";

export const TeamDropdownContext = createContext({} as TeamDropdownValues);


const TeamDropdownContextProvider = (props: TeamDropdownProps): ReactElement<TeamDropdownValues> => {
    const [selectedDropdownValue, setSelectedDropdownValue] = useState<string>('All Chatbots');

    return (
        <TeamDropdownContext.Provider
            value={{
                selectedDropdownValue,
                setSelectedDropdownValue
            }}
        >
            {props.children}
        </TeamDropdownContext.Provider>
    );
};

export default TeamDropdownContextProvider;
