import { Navbar } from "react-bootstrap";
import styled from "styled-components";
import BackImage from '../../../../images/back-button-black.svg'
import { MOBILE } from "../../../../constants/mobile";
import { TAB } from "../../../../constants/tab";
import { MEDIUM } from "../../../../constants/desktop";
import { useContext } from "react";
import { CustomBlurDivContext } from "../../../../context/custom-blur-div-context";

const NavbarMain = styled(Navbar)`
padding-left: 2% !important;
padding-right: 2% !important;
justify-content: start !important; 
width: 100% !important;
z-index: 8 !important;
background: #D9D3E5 !important;
@media (max-width: ${MOBILE.maxWidth}px) {
flex-direction: row !important;  
align-items: start !important;
font-size: 11px !important;
}
@media (min-width: ${TAB.minWidth}px) {
align-items: start !important;

}
@media (min-width: ${MEDIUM.minWidth}px) {
justify-content: start !important; 

}
  
`;

const Label = styled.label`
margin-left: 3rem;
font-size: 16px;
@media (max-width: ${MOBILE.maxWidth}px) {
margin-left: 3%;
font-size: 12px;

}
`


const BackButton = styled.button`
border: none;
padding: 0;
background: none;
`

const BackButtonImage = styled.img`
@media (max-width: ${MOBILE.maxWidth}px) {
width: 15px;
}

`

const CopyChatbotNavbar = ({ setShowCopyChatbotPopup }: any) => {
    const { setIsBackgroundBlur } = useContext(CustomBlurDivContext)

    const closePopup = () => {
        setShowCopyChatbotPopup(false)
        setIsBackgroundBlur(false)
    }

    return (
        <>
            <NavbarMain>

                <BackButton type="button" onClick={() => { closePopup() }}> <BackButtonImage src={BackImage} alt="" /></BackButton>
                <Label>Copy Chatbot</Label>

            </NavbarMain>
        </>
    )
}

export default CopyChatbotNavbar