import { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  DEVICE_MEDIUM,
  DEVICE_MOBILE,
  DEVICE_TAB,
  MEDIUM,
  MOBILE,
  TAB,
} from "../../constants/breakpoints";
import { BookContext } from "../../context/BookContext";
import { PublicContext } from "../../context/PublicContext";
import AddDataSourceButton from "../../images/add-data-source-button.svg";
import BackIcon from "../../images/chat-back.svg";
import HTMLSign from "../../images/htmlSign.svg";
import URLSign from "../../images/ogg1.svg";
import PDFSign from "../../images/pdf1.svg";
import YouTubeSign from "../../images/ps_youtube.svg";
import {
  default as TXTSign,
  default as TextSign,
} from "../../images/txtSign.svg";
import myAxios from "../../myaxios";
import MainHeader from "../header";
import ManageChatbotSingleSave from "../manage-setting/manage-chatbot";
import MobileManageChatbot from "../manage-setting/mobile-manage-chatbot";
import AddNewSource from "./add-new-source/add-new-source";
import AnalyticsArea from "./analytics";
import ChatArea from "./chat-area";
import DesktopTabComponent from "./desktop-tab-component";
import MobileTabComponent from "./mobile-tab-component";
import * as BookChatStyles from "./styled";
import ChatbotSessions from "../chatbot-session/chatbot-sessions";
import DataSources from "./tab-components/data-source-tab/data-sources";
import LoginSettings from "./tab-components/login-settings";
import MobileChatbotSessions from "./tab-components/mobile-chatbot-sessions";
import MobileSampleQuestions from "./tab-components/mobile-sample-questions";
import MobileThemeSettings from "./tab-components/mobile-theme-settings";
import SampleQuestions from "./tab-components/sample-questions";
import ThemeSettings from "./tab-components/theme-settings";
import { TeamContext } from "../../context/team-context";
import { UserContext } from "../../context/UserContext";

const ImageTag = styled.img`
  width: 30px;
  margin: 0 1rem;
  padding: 1px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 40px;
  }
`;
const ImageTagSource = styled.img`
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 150px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: 150px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: 70%;
  }
`;

const BookChat = () => {
  const { deviceWidth } = useContext(PublicContext);
  const { setSelectedBook, selectedBook } = useContext(BookContext);
  const [selectTabName, setSelectTabName] = useState("Chat");
  const [loading, setLoading] = useState<boolean>(false);
  const { bookId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedTeam, findUserRole, userRoleInTeam } = useContext(TeamContext);
  const { user } = useContext(UserContext);
  const [bookSettings, setBookSettings] = useState<any>();
  const [showCopyChatbotPopup, setShowCopyChatbotPopup] = useState<boolean>(false);


  useEffect(() => {
    if (bookId) {
      setLoading(true);
      myAxios
        .get(process.env.REACT_APP_SERVER_URL + "/books/book/" + bookId)
        .then((response) => {
          if (response && response.data) {
            setSelectedBook(response.data.data);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
    myAxios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/book-settings/get-book-settings/${bookId}`
      )
      .then((res) => {
        // console.log("res.data.data ---", res.data.data);
        if (res.data.data) {
          // updateUserSubscription(res.data.data.currentSubscription);
          setBookSettings(res.data.data);
        }
      });
    // eslint-disable-next-line
  }, [bookId]);

  useEffect(() => {
    // console.log("selectedTeam", selectedTeam);
    if (user) {
      findUserRole(user)
    }
    // eslint-disable-next-line
  }, [selectedTeam]);

  useEffect(() => {
    var url = location.pathname.split("/").pop();
    if (url === "data-sources") {
      setSelectTabName("Data Sources");
    }
    if (url === "manage-chatbot") {
      setSelectTabName("Manage Chatbot");
    }
    if (url === "analytics") {
      setSelectTabName("Analytics");
    }
    if (url === "login-settings") {
      setSelectTabName("Login Settings");
    }
    if (url === "theme-settings") {
      setSelectTabName("Theme Settings");
    }
    if (url === "chatbot-sessions") {
      setSelectTabName("Chatbot Sessions");
    }
    if (url === "sample-questions") {
      setSelectTabName("Sample Questions");
    }
    if (url === "add-pdf-sources") {
      setSelectTabName("Data Sources");
      setShowAddNewSourcePage(true);
    }
    if (url === "add-text-sources") {
      setSelectTabName("Data Sources");
      setShowAddNewSourcePage(true);
    }
    if (url === "add-url-sources") {
      setSelectTabName("Data Sources");
      setShowAddNewSourcePage(true);
    }
    if (url === "add-shopify-sources") {
      setSelectTabName("Data Sources");
      setShowAddNewSourcePage(true);
    }
  }, [bookId, location]);

  // eslint-disable-next-line

  const [showAddNewSourcePage, setShowAddNewSourcePage] =
    useState<boolean>(false);

  const fileTypeMappings: Record<string, string> = {
    "text/plain": TXTSign,
    "text/html": HTMLSign,
    youtube: YouTubeSign,
    url: URLSign,
    "application/text": TextSign,
  };

  const getImageSource = () => {
    if (!selectedBook) {
      return PDFSign;
    }

    if (selectedBook.type === "google" || selectedBook.chatType === "chatgpt") {
      return selectedBook.imageUrl ?? PDFSign;
    }

    const fileType = selectedBook.fileType;
    if (fileType && fileTypeMappings.hasOwnProperty(fileType)) {
      return fileTypeMappings[fileType];
    }

    return PDFSign;
  };
  return (
    <>
      {!showAddNewSourcePage ? (
        <>
          <MainHeader isUserPage={true} activeFrom={"fromMain"} showCopyChatbotPopup={showCopyChatbotPopup} />
          <BookChatStyles.ChatPageHeaderNewStyle>
            <Col
              xs={12}
              sm={12}
              style={{
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0.2rem 0.5rem",
              }}
            >
              <BookChatStyles.BookContent
                md={10}
                sm={10}
                xs={10}
                lg={10}
                style={{ alignItems: "center" }}
              >
                <BookChatStyles.BackButton
                  src={BackIcon}
                  onClick={() => {
                    navigate("/my-chatbots/create-pdf-chatbot");
                  }}
                />
                <ImageTag src={getImageSource()} alt="book_image" />
                <Col style={{ flexDirection: "column" }}>
                  <Col md={12} xs={12} lg={12} sm={12}>
                    <span style={{ color: "#33303c", fontSize: "16px" }}>
                      {selectedBook && selectedBook.title}
                    </span>
                  </Col>
                  {selectedBook &&
                    selectedBook.authors &&
                    selectedBook.authors[0].length > 0 && (
                      <Col md={12} xs={12} lg={12} sm={12}>
                        <span
                          style={{
                            color: "rgba(51, 48, 60, 0.6)",
                            fontSize: "14px",
                          }}
                        >
                          {selectedBook.authors
                            .map((author: any) => author)
                            .join(", ")}
                        </span>
                      </Col>
                    )}
                </Col>
              </BookChatStyles.BookContent>
              {selectedBook?.type === "google" ||
                selectedBook?.chatType === "chatgpt" ? null : (
                <Col
                  md={2}
                  xs={2}
                  sm={2}
                  style={{ justifyContent: "flex-end" }}
                >
                  {
                    (userRoleInTeam !== 'viewer') &&
                    <ImageTagSource
                      src={AddDataSourceButton}
                      alt="Add Data Source"
                      // width={"70%"}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(
                          "/books/" +
                          selectedBook?._id +
                          "/chatbot-settings/add-pdf-sources"
                        );
                        setShowAddNewSourcePage(true);
                      }}
                    />
                  }
                </Col>
              )}
            </Col>
            {/* <Col sm={10} style={{ alignItems: "center" }}></Col> */}
          </BookChatStyles.ChatPageHeaderNewStyle>

          {deviceWidth === DEVICE_MEDIUM ? (
            <DesktopTabComponent
              setSelectTabName={setSelectTabName}
              selectTabName={selectTabName}
            />
          ) : (
            <MobileTabComponent
              setSelectTabName={setSelectTabName}
              selectTabName={selectTabName}
            />
          )}
          <BookChatStyles.MainContainer fluid>
            {selectTabName === "Chat" && <ChatArea />}
            {selectTabName === "Data Sources" && <DataSources />}
            {selectTabName === "Analytics" && (
              <AnalyticsArea selectedBook={selectedBook} />
            )}
            {selectTabName === "Manage Chatbot" && (
              <>
                {deviceWidth === DEVICE_TAB || deviceWidth === DEVICE_MOBILE ? (
                  <>
                    <MobileManageChatbot
                      bookId={bookId}
                      bookSettings={bookSettings}
                      setBookSettings={setBookSettings}
                      selectedBook={selectedBook}
                      showCopyChatbotPopup={showCopyChatbotPopup}
                      setShowCopyChatbotPopup={setShowCopyChatbotPopup}
                    />
                  </>
                ) : (
                  <>
                    <ManageChatbotSingleSave
                      bookId={bookId}
                      bookSettings={bookSettings}
                      setBookSettings={setBookSettings}
                      selectedBook={selectedBook}
                      setSelectTabName={setSelectTabName}
                      showCopyChatbotPopup={showCopyChatbotPopup}
                      setShowCopyChatbotPopup={setShowCopyChatbotPopup}
                    />
                  </>
                )}
              </>
            )}
            {selectTabName === "Login Settings" && <LoginSettings />}

            {selectTabName === "Theme Settings" && (
              <>
                {deviceWidth === DEVICE_TAB || deviceWidth === DEVICE_MOBILE ? (
                  <>
                    <MobileThemeSettings />
                  </>
                ) : (
                  <>
                    <ThemeSettings />
                  </>
                )}
              </>
            )}
            {/* {selectTabName === "Chatbot Sessions" && <ChatbotSessions />} */}

            {selectTabName === "Chatbot Sessions" && (
              <>
                {deviceWidth === DEVICE_TAB || deviceWidth === DEVICE_MOBILE ? (
                  <>
                    <MobileChatbotSessions />
                  </>
                ) : (
                  <>
                    <ChatbotSessions />
                  </>
                )}
              </>
            )}

            {selectTabName === "Sample Questions" && (
              <>
                {deviceWidth === DEVICE_TAB || deviceWidth === DEVICE_MOBILE ? (
                  <>
                    <MobileSampleQuestions />
                  </>
                ) : (
                  <>
                    <SampleQuestions />
                  </>
                )}
              </>
            )}
          </BookChatStyles.MainContainer>
        </>
      ) : (
        <AddNewSource setShowAddNewSourcePage={setShowAddNewSourcePage} />
      )}
    </>
  );
};

export default BookChat;
