import { useContext } from "react";
import styled from "styled-components";
import { Col } from "react-bootstrap";
import { TeamContext } from "../../../context/team-context";
import { MOBILE } from "../../../constants/mobile";
import { TAB } from "../../../constants/tab";
import { MEDIUM } from "../../../constants/desktop";
import myAxios from "../../../myaxios";
import { UserContext } from "../../../context/UserContext";
import { PaginationContext } from "../../../context/pagination-context";

// Styled Components for UI elements
const DropdownSelect = styled.select`
  font-size: 14px;
  height: 40px;
  margin-top: 0.25rem;

  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
    height: 35px;
    margin-top: 0px;
  }

  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 16px;
    height: 45px;
    margin-top: 0px;
  }
`;

const SingleFilter = styled(Col)`
  flex-direction: row !important;
  font-weight: bold !important;

  & select {
    border: 1px solid #eaeaea !important;
    padding: 0.5rem 1rem !important;
    background: #eaeaea !important;

    @media (max-width: ${MOBILE.maxWidth}px) {
      margin-bottom: 1% !important;
    }
    @media (min-width: ${TAB.minWidth}px) {
      margin-bottom: 1% !important;
    }
    @media (min-width: ${MEDIUM.minWidth}px) {
      margin-bottom: 0% !important;
    }
  }
`;

const Label = styled.label`
  margin-top: 0.6rem;
  font-size: 14px;

  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
    margin-top: 0.5rem;
  }

  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 16px;
    margin-top: 0.5rem;
  }
`;

const DropdownOption = styled.option``;

const TeamSelectionDropdown = ({ selectedDropdownOption, setSelectedDropdownOption }: any) => {
  const { userTeams, setSelectedTeam, findUserRole } = useContext(TeamContext);
  const { user } = useContext(UserContext);
  const { setPageSkip } = useContext(PaginationContext);

  // Handles team selection change
  const changeTeam = (newTeam: string) => {
    setPageSkip(0);
    setSelectedDropdownOption(newTeam);

    if (newTeam !== "All Chatbots") {
      const selected = userTeams?.find((team) => team.id === newTeam);
      if (selected) {
        setSelectedTeam(selected);
        updateLastSelectedTeam(newTeam);
      }
    }
    else {
      // setSelectedTeam()
      getDefaultTeamForUser()
    }
  };

  const getDefaultTeamForUser = async () => {
    let defaultTeam: any = userTeams?.filter((t) => {
      return t.type === 'individual' && t.createdBy === user?.id
    })
    setSelectedTeam(defaultTeam[0]);
    updateLastSelectedTeam(defaultTeam[0].id);
    user && findUserRole(user)
  }

  // Updates the last selected team in the backend
  const updateLastSelectedTeam = async (newTeam: string) => {
    try {
      await myAxios.post(`${process.env.REACT_APP_SERVER_URL}/teams/update-last-selected-team`, {
        team: newTeam,
      });
    } catch (error) {
      console.error("Error updating last selected team:", error);
    }
  };

  return (
    <SingleFilter>
      <Label>Filter Chatbot: &nbsp;</Label>
      <DropdownSelect value={selectedDropdownOption} onChange={(e) => changeTeam(e.target.value)}>
        <DropdownOption value="All Chatbots">All Chatbots</DropdownOption>
        {userTeams?.map((team) => (
          <DropdownOption key={team.id} value={team.id}>
            {team.name}
          </DropdownOption>
        ))}
      </DropdownSelect>
    </SingleFilter>
  );
};

export default TeamSelectionDropdown;
